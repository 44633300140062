import { WalletTransactionInterface } from "@/interfaces";

class WalletHelpers {
  getSessionIdFromTransaction(transaction: WalletTransactionInterface) {
    if (!transaction?.memo) return null;
    let memo = transaction.memo.split(" ");
    let id = parseInt(memo[1]);
    return (memo[0] === "Session" || memo[0] === "Sesssion") && id ? id : null;
  }
}

export default new WalletHelpers();
