import { DEVICE_NETWORK_VARIABLE } from "@/constants";
import { DEVICE_NETWORK_COMMAND, DeviceNetworkResponse } from "@/interfaces";
import Api from "@/library/apis/Api";
import { DeviceHelpers, sleep, TimeHelpers } from "@/library/helpers";
import { defineStore } from "pinia";
import { deviceStore, globalStore } from ".";

const defaultState = () =>
  ({
    wifiSetupForm: {
      devices: [] as any[],
      hiddenDeviceIdentifiers: [] as string[],
      selectedDeviceIdentifiers: null,
      inProgressDevices: [] as any[],
      inputs: {
        pingInterval: 15,
        enableReboot: false,
        enableAddCustomWifi: false,
        customWifiConfiguration: {
          ssid: null,
          password: "",
          securityType: 3,
          securityChiper: 2,
          channel: 0,
        },
      },
      results: {}, // { [deviceIdentifier]: { ping, reboot, addCustomWifi, pingAfterReboot } } which is boolean
      inProgress: {
        deviceIdentifier: null,
        process: null, // ping, reboot, addCustomWifi, pingAfterReboot
      },
    },
  }) as any;

export default defineStore("Device-Network", {
  state: () => ({
    ...defaultState(),
    error: null,
    selectedDevice: null,
    isLoading: false,
    showDialogs: {
      testConnection: false,
      reboot: false,
      addWifi: false,
      clearWifi: false,
    },
    deviceLoadings: {
      connectedNetworks: [],
      storedNetworks: [],
    },
    connectedNetworks: {} as Record<string, any>, // { [deviceIdentifier]: values }
    storedNetworks: {} as Record<string, any>, // { [deviceIdentifier]: values }
    offlineDevices: [],
  }),
  getters: {},
  actions: {
    modifyOfflineDevices(device: any, isAdd = true) {
      const isDeviceOffline = this.offlineDevices.includes(device.identifier);
      if (!isAdd) {
        if (isDeviceOffline) this.offlineDevices.splice(this.offlineDevices.indexOf(device.identifier), 1);
        return;
      }
      if (!isDeviceOffline) this.offlineDevices.push(device.identifier);
    },
    testConnection(device: any, refetchOnFinish = true) {
      return new Promise(async (resolve, reject) => {
        if (!device || !device.identifier) {
          reject("Device is not valid");
          return;
        }

        this.showDialogs.testConnection = true;
        this.selectedDevice = device;
        this.isLoading = true;
        const payload = {
          plugIdentifier: device.identifier,
          command: DEVICE_NETWORK_COMMAND.DEVICE_VERIFY_CONNECTION,
        };
        try {
          await Api.plugSendCommand(payload);
          this.modifyOfflineDevices(device, false);
          if (refetchOnFinish) {
            await sleep(1000);
            deviceStore.fetchData({ hideLoading: true, fields: ["last_heard_time"] });
          }
        } catch (error) {
          this.error = true;
          this.modifyOfflineDevices(device);
          reject(error);
        } finally {
          this.isLoading = false;
          resolve(true);
        }
      });
    },
    reboot(device: any, refetchOnFinish = true) {
      return new Promise(async (resolve, reject) => {
        if (!device || !device.identifier) {
          reject("Device is not valid");
          return;
        }

        this.showDialogs.reboot = true;
        this.selectedDevice = device;
        this.isLoading = true;

        const payload = {
          plugIdentifier: device.identifier,
          command: DEVICE_NETWORK_COMMAND.DEVICE_REBOOT,
        };
        try {
          await Api.plugSendCommand(payload);

          this.showDialogs.reboot = true;
          this.isLoading = false;
          this.error = false;
          globalStore.snackbar.show = true;
          globalStore.snackbar.message = `Reboot request sent to ${device.identifier}`;
          this.modifyOfflineDevices(device, false);
          if (refetchOnFinish) {
            await sleep(1000);
            deviceStore.fetchData({ hideLoading: true, fields: ["last_heard_time"] });
          }
        } catch (error) {
          globalStore.snackbar.show = true;
          globalStore.snackbar.message = `Reboot failed`;
          this.error = true;
          this.modifyOfflineDevices(device);
          reject(error);
        } finally {
          this.isLoading = false;
          resolve(true);
        }
      });
    },
    getConnectedNetwork(device: any, refetchOnFinish = true) {
      return new Promise(async (resolve, reject) => {
        try {
          this.deviceLoadings.connectedNetworks.push(device.identifier);
          this.connectedNetworks[device.identifier] = [];
          const payload = {
            plugIdentifier: device.identifier,
            command: DEVICE_NETWORK_COMMAND.WIFI_GET_CONNECTED_NETWORK,
          };
          const response = await Api.plugSendCommand(payload);
          const networkResponse = response as DeviceNetworkResponse;
          this.connectedNetworks[device.identifier] = DeviceHelpers.formatNetworkResponse(networkResponse.connectedNetwork);
          this.modifyOfflineDevices(device, false);
          resolve(true);

          if (refetchOnFinish) {
            await sleep(1000);
            deviceStore.fetchData({ hideLoading: true, fields: ["last_heard_time"] });
          }
        } catch (e) {
          this.error = true;
          this.modifyOfflineDevices(device);
          console.error(e);
        } finally {
          this.deviceLoadings.connectedNetworks = this.deviceLoadings.connectedNetworks.filter((id) => id !== device.identifier);
        }
      });
    },
    getStoredNetworks(device: any, refetchOnFinish = true) {
      return new Promise(async (resolve) => {
        try {
          this.deviceLoadings.storedNetworks.push(device.identifier);
          this.storedNetworks[device.identifier] = [];
          const payload = {
            plugIdentifier: device.identifier,
            command: DEVICE_NETWORK_COMMAND.WIFI_GET_STORED_NETWORKS,
          };
          const response = (await Api.plugSendCommand(payload)) as DeviceNetworkResponse;
          const { data, header } = response;
          if (!Array.isArray(data)) throw new Error("stored_network_datas is not an array");
          this.storedNetworks[device.identifier] = data.map((stored_network_datas: any) => {
            return header.reduce((parsed: any, key: string) => {
              let value = stored_network_datas[header.indexOf(key)];
              if (key === "security") {
                const selected_security = DEVICE_NETWORK_VARIABLE.securities.find((security) => security.data === value);
                value = selected_security ? selected_security.name : null;
              }
              parsed[key] = value;
              return parsed;
            }, {});
          });
          this.modifyOfflineDevices(device, false);
          resolve(true);

          if (refetchOnFinish) {
            await sleep(1000);
            deviceStore.fetchData({ hideLoading: true, fields: ["last_heard_time"] });
          }
        } catch (error) {
          this.error = true;
          this.modifyOfflineDevices(device);
        } finally {
          this.deviceLoadings.storedNetworks = this.deviceLoadings.storedNetworks.filter((id) => id !== device.identifier);
        }
      });
    },
    addNetworkToDevice(payload: any, refetchOnFinish = true) {
      return new Promise(async (resolve, reject) => {
        try {
          await Api.plugSendCommand({
            plugIdentifier: this.selectedDevice.identifier,
            command: DEVICE_NETWORK_COMMAND.WIFI_ADD_NETWORK,
            data: {
              ssid: String(payload.ssid),
              password: String(payload.password),
              securityType: payload.security,
              securityChiper: payload.encryption,
              channel: payload.channel,
            },
          });
          this.getStoredNetworks(this.selectedDevice, refetchOnFinish);

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    clearDeviceStoredNetworks(refetchOnFinish = true) {
      return new Promise(async (resolve, reject) => {
        const payload = {
          plugIdentifier: this.selectedDevice.identifier,
          command: DEVICE_NETWORK_COMMAND.WIFI_CLEAR_STORED_NETWORKS,
        };
        try {
          await Api.plugSendCommand(payload);
          this.getStoredNetworks(this.selectedDevice, refetchOnFinish);
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    reset(key: string, deepKey?: string) {
      if (this.hasOwnProperty(key)) {
        if (deepKey) {
          this[key][deepKey] = defaultState()[key][deepKey];
          return;
        }

        this[key] = defaultState()[key];
      }
    },
    async updateWifiSetupFormDevice(identifier: string) {
      try {
        await sleep(1000);
        const device: any = await Api.adminManufacturedPlugFetch({ qr: identifier });

        const index = this.wifiSetupForm.devices.findIndex((d) => d.identifier === device.external_identifier);
        if (index === -1) {
          this.wifiSetupForm.devices.push(device);
          return;
        }

        this.wifiSetupForm.devices[index] = {
          ...this.wifiSetupForm.devices[index],
          last_heard_time: device.last_heard_time,
          get last_heard_time_text() {
            const lapsedTime = TimeHelpers.unixToTimeLapsed(device.last_heard_time, {
              showSecond: true,
              timezone: globalStore.timezone,
              showTimeOnDate: true,
              isLongTimeUnit: true,
            });
            return lapsedTime ? lapsedTime : "Never Connected";
          },
        };
      } catch (error) {}
    },
  },
});
