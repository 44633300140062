export const DEVICE_NETWORK_COMMAND = {
  WIFI_ADD_NETWORK: 0,
  WIFI_GET_STORED_NETWORKS: 1,
  WIFI_GET_CONNECTED_NETWORK: 2,
  WIFI_CLEAR_STORED_NETWORKS: 3,
  WIFI_REBOOT: 4,
  WIFI_SCAN: 5,
  DEVICE_VERIFY_CONNECTION: 6,
  DEVICE_REBOOT: 7,
  DEVICE_SET_CURRENT: 200,
  DEVICE_GET_OCPP_CONFIG: 210,
};

export interface DeviceNetworkResponse {
  data: any[];
  header: string[];
  connectedNetwork: any;
}
