import { OwnerProxyFeaturePermissions } from "@/constants";
import Api from "@/library/apis/Api";
import { adminStore, authStore } from "@/store-pinia";
import { cloneDeep } from "lodash-es";
import { defineStore } from "pinia";

const localStorageKey = "__plugzio_owner_proxy__";

export default defineStore("Owner-Proxy", {
  state: () => ({
    accessGivenToUsers: [],
    // need to differentiate between accessReceivedFromUsers and canActAsUsers
    // because accessReceivedFromUsers will consist of data LOGGED IN owner and when owner act as other owner
    // and canActAsUsers is the list of users that LOGGED IN owner can act as
    accessReceivedFromUsers: [],
    canActAsUsers: [],
    actAs: null,
    initialized: false,
  }),
  getters: {
    activeActAsUser(state) {
      const activeActAs = state.canActAsUsers.find((user) => user.owner_username === state.actAs);
      if (!activeActAs) return null;
      return {
        ...activeActAs,
        permissions: JSON.parse(activeActAs?.rights || "null"),
      };
    },
    isHaveAccessToFeature() {
      return (feature: string) => {
        const featurePermissions = OwnerProxyFeaturePermissions[feature];
        if (!featurePermissions) return false;

        const activeActAsUser = this.activeActAsUser;
        // if no active act as user, it means the user is the owner itself
        if (!activeActAsUser) return true;

        const userPermissions = this.activeActAsUser?.permissions;

        // null is for view-only access, string is not exist now but just in case it exist, it will returns here
        if (userPermissions === null || typeof userPermissions === "string") return featurePermissions.includes(userPermissions);

        return userPermissions.some((permission: string) => featurePermissions.includes(permission));
      };
    },
  },
  actions: {
    setState(payload: any) {
      for (const key in payload) {
        if (this.hasOwnProperty(key)) {
          this[key] = cloneDeep(payload[key]);
        }
      }
    },
    resetState() {
      this.setActAs(null);
      this.setState({ accessGivenToUsers: [], canActAsUsers: [] });
    },
    async initialize() {
      this.initialized = false;
      await this.fetchCanActAsUsers(true);

      const persistentState = JSON.parse(localStorage.getItem(localStorageKey) || "{}");
      if (persistentState.actAs) {
        let actAs = persistentState.actAs;
        if (!this.canActAsUsers.find((user) => user.owner_username === persistentState.actAs)) actAs = null;
        this.setState({ actAs });
      }
      this.setState({ initialized: true });
    },
    async fetchProxies() {
      if (!adminStore.managerview && !authStore.isOwner) return;
      const accessGivenToUsers = await Api.proxyProxies({});
      this.setState({ accessGivenToUsers });
    },
    async fetchCanActAsUsers(updateCanActAsUsers = false) {
      if (!adminStore.isManagerView && !authStore.isOwner) return;
      const proxyOwners = (await Api.proxyOwners({}, !updateCanActAsUsers)) || [];
      this.setState(updateCanActAsUsers ? { canActAsUsers: proxyOwners } : { accessReceivedFromUsers: proxyOwners });
    },
    setActAs(actAs: string | null) {
      this.setState({ actAs });
      const persistentState = JSON.parse(localStorage.getItem(localStorageKey) || "{}");
      if (actAs === null) {
        delete persistentState.actAs;
        if (!Object.keys(persistentState).length) localStorage.removeItem(localStorageKey);
        return;
      }
      persistentState.actAs = actAs;
      localStorage.setItem(localStorageKey, JSON.stringify({ ...persistentState }));
    },
  },
});
