export const DEVICE_MODES = [
  {
    code: 2,
    name: "Smart",
    description: "Allows you to control access, set billing rates, monitor your device's status and power consumption.",
  },
  {
    code: 1,
    name: "Always On",
    description: "Just like a regular device. Anyone can plug in!",
  },
  {
    code: 0,
    name: "Always Off",
    description: "Disables your device. Plugging in doesn't do anything!",
  },
  // {
  //   code: 3,
  //   name: "Bluetooth",
  //   description: "Control your device through your phone without a network connection.",
  // },
];

export const DEVICE_ACCESS = [
  {
    code: 0,
    name: "Private",
    description: "Only users chosen by you can access this device",
    hint: "No one can access the device unless granted access",
  },
  {
    code: 1,
    name: "Public",
    description: "Allows any Plugzio user to access this device",
    hint: "Everyone can access the device on a default payment plan. Users who are granted specific access will use the assigned payment plan over the default plan.",
  },
];

export const EXPIRY_NOTES = [
  "Devices will automatically be disabled anytime after the subscription has expired.",
  "There is a reactivation fee for reactivating disabled devices.",
  "Reactivating disable devices will take between 3 - 5 business days.",
  "Disabled devices will retain their initial mode right before they were disabled.",
  "You will no longer be able to control disabled devices in the Device Management page.",
  'No one will be able to access/use disabled devices unless the device was previously set to the "Always On" power setting.',
];

export const EXPIRY_NOTES_HTML = `<ul class="mt-2 ml-4">${EXPIRY_NOTES.reduce((notes, text) => notes + `<li class="mb-2">${text}</li>`, "")}</ul>`;

export const DEVICE_NETWORK_VARIABLE = {
  channels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  encryptions: [
    { name: "AES", data: 0 },
    { name: "TKIP", data: 1 },
    { name: "AES/TKIP", data: 2 },
  ],
  securities: [
    { name: "Unsecured", data: 0 },
    { name: "WEP", data: 1 },
    { name: "WPA-Personal", data: 2 },
    { name: "WPA2-Personal", data: 3 },
    { name: "WPA Enterprise", data: 4 },
    { name: "WPA2 Enterprise", data: 5 },
  ],
};
