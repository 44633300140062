import { isArray as _isArray } from "lodash-es";

class LocalStorageHelpers {
  getNestedItem(key, properties = []) {
    let storage = localStorage.getItem(key);
    if (!storage) return null;

    storage = JSON.parse(storage);
    return getItem(storage, properties);

    function getItem(storage, properties) {
      const property = properties[0];
      const next_property = properties[1];
      if (!storage.hasOwnProperty(property)) return null;
      if (!next_property) return storage[property];

      properties.shift();
      return getItem(storage[property], properties);
    }
  }
  setNestedItem(key, properties = [], value = null) {
    let storage = JSON.parse(localStorage.getItem(key) || "{}");

    storage = assignValue(storage, properties, value);
    localStorage.setItem(key, JSON.stringify(storage));

    function assignValue(storage, properties, value) {
      const property = properties[0];
      const next_property = properties[1];
      if (!storage.hasOwnProperty(property)) storage[property] = {};
      if (next_property && _isArray(storage[property])) storage[property] = {};
      if (!next_property) {
        storage[property] = value;
        return storage;
      }
      properties.shift();
      storage[property] = assignValue(storage[property], properties, value);
      return storage;
    }
  }
}

export default new LocalStorageHelpers();
