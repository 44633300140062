import { LOCALSTORAGE_KEYS, USER_ROLES } from "@/constants";
import Api from "@/library/apis/Api";
import RouteHelpers from "@/library/helpers/RouteHelpers";
import { capitalize } from "lodash-es";
import { defineStore } from "pinia";
import { ownerProxyStore } from ".";

export default defineStore("Authentication", {
  state: () => ({
    accessToken: localStorage.getItem("key"),
    email: localStorage.getItem("email"),
  }),
  getters: {
    role: () => (RouteHelpers.isAdminUrl() ? "admin" : RouteHelpers.isOwnerUrl() ? "owner" : "user"),
    isUser() {
      return this.role === USER_ROLES.USER;
    },
    isOwner() {
      return this.role === USER_ROLES.OWNER;
    },
    isAdmin() {
      return this.role === USER_ROLES.ADMIN;
    },
    roleName() {
      if (!this.role) return "";
      return capitalize(this.role).replace("Owner", "Management");
    },
    roleSetting() {
      const settings = {
        admin: {
          routePrefix: "admin-",
          urlPrefix: "admin/",
          color: "#263238",
        },
        owner: {
          routePrefix: RouteHelpers.isLocal ? "owner-" : "",
          urlPrefix: RouteHelpers.isLocal ? "owner/" : "",
          color: "#F08400",
        },
        user: {
          routePrefix: "",
          urlPrefix: "",
          color: "#2b8a2b",
        },
      };
      return settings[this.role];
    },
    isAuthenticated: (state) => !!state.accessToken,
  },
  actions: {
    setState(payload: any) {
      for (const key in payload) {
        if (this.hasOwnProperty(key)) {
          this[key] = payload[key];
        }
      }
    },
    redirectToLogin() {
      /* This function will be subscribed by components */
    },
    async login(username: string, password: string, remember: boolean) {
      localStorage.removeItem(LOCALSTORAGE_KEYS.SAVED_CREDENTIALS);

      return Api.login(
        {
          grant_type: "client_credentials",
          client_id: username,
          client_secret: password,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((key: string) => {
        if (remember) localStorage.setItem("__plugzio_user_credentials__", JSON.stringify({ username: decodeURIComponent(username), password: decodeURIComponent(password) }));
        localStorage.setItem("key", key);
        localStorage.setItem("email", decodeURIComponent(username));
        this.setState({ accessToken: key, email: decodeURIComponent(username) });
        ownerProxyStore.resetState();
        ownerProxyStore.initialized = false;
      });
    },
    logout() {
      return new Promise((resolve) => {
        // this.$store.dispatch("resetCount");
        localStorage.setItem("__plugzio_plug_expiration_notification_dismissed__", "false");
        // this.$store.commit("Global/setState", { preferences: {} })
        // this.$store.dispatch("resetState");
        localStorage.removeItem("key");
        localStorage.removeItem("email");
        localStorage.removeItem("skip");
        localStorage.removeItem("firebaseToken");
        localStorage.removeItem("verify");
        localStorage.removeItem("__plugzio_plug_expiration_notification_dismissed__");
        localStorage.removeItem("__mobikwik_tokens__");
        // this.setToken();
        this.setState({ accessToken: null, email: null });
        return resolve({ name: `${this.roleSetting.routePrefix}login` });
      });
    },
  },
});
