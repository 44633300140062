class SortHelpers {
  compareValues(key: string, order = "asc") {
    return (a: any, b: any) => {
      if (!a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === "string" ? a[key].toUpperCase() : a[key]) || "";
      const varB = (typeof b[key] === "string" ? b[key].toUpperCase() : b[key]) || "";

      return (varA > varB ? 1 : -1) * (order === "desc" ? -1 : 1);
    };
  }
}

export default new SortHelpers();
