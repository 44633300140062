import { LOCALSTORAGE_KEY, USER_ROLES } from "@/constants";
import raw_timezones from "@/data/timezones.json";
import { Preference } from "@/interfaces";
import Api from "@/library/apis/Api";
import { ApiHelpers, ConsoleHelpers, LocalStorageHelpers, LocationHelpers } from "@/library/helpers";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { defineStore } from "pinia";
import { adminStore, authStore, deviceStore, ownerProxyStore, paymentPlanStore, sessionStore, walletStore } from ".";

const defaultValues = () => ({
  globalDialog: {
    show: false,
    title: null,
    content: null,
    showConfirm: true,
    confirmText: "OK",
    onConfirmed: () => {},
    showCancel: true,
    cancelText: "Cancel",
    onCanceled: () => {},
    onClosed: () => {},
  },
  intercom: {
    show: false,
    showEmailForm: false,
    email: localStorage.getItem("email"),
  },
});

export default defineStore("Global", {
  state: () => ({
    timezone: null,
    offline: false,
    showSidebar: false,
    reloadOnStateChange: true,
    country: null,
    globalDialog: defaultValues().globalDialog,
    location: {
      latitude: null,
      longitude: null,
    },
    intercom: defaultValues().intercom,
    apiCalls: [],
    preferences: {} as Preference,
    responsive: null,
    initialized: false,

    showOpenAppSnackbar: false,
    offlineModeSnackbar: {
      show: false,
      message: null,
      timeout: 3000,
      color: "#212121",
    },
    snackbar: {
      show: false,
      message: null,
      timeout: 5000,
    },
    firebaseToken: null,
    firebaseMessage: null,
    loading: false as boolean | string,
    universalLink: null,
  }),
  getters: {
    countryCode: (state) => state.country?.code,
    timezoneText(state) {
      if (!state.timezone) return null;
      const timezones = raw_timezones.map((v) => ({ name: v.text, id: v.value }));
      let timezone = timezones.find((tz) => tz.id == state.timezone);
      if (!timezone) return null;
      return timezone.name;
    },
    isDarkTheme: (state) => ((state.preferences || {}).settings || {}).darkTheme || false,
    isReloadOnStateChange: (state) => state.reloadOnStateChange && sessionStore.processedDevices.length === 0,
  },
  actions: {
    setState(payload: any) {
      const saveToLocalStorageKeys = [];
      for (const key in payload) {
        if (Object.hasOwnProperty.call(this, key)) {
          if (Array.isArray(this[key])) this[key] = payload[key];
          else this[key] = typeof this[key] === "object" && Object.keys(payload[key] || {}).length > 0 ? { ...this[key], ...payload[key] } : payload[key];

          if (saveToLocalStorageKeys.includes(key)) LocalStorageHelpers.setNestedItem(LOCALSTORAGE_KEY, [key], this[key]);
        }
      }
    },
    setOffline(payload: any) {
      const { offline, offlineModeSnackbar } = payload;
      if (offline != null) this.offline = offline;
      if (!offlineModeSnackbar) return;
      for (const key in offlineModeSnackbar) {
        if (this.offlineModeSnackbar.hasOwnProperty(key)) {
          this.offlineModeSnackbar[key] = offlineModeSnackbar[key];
        }
      }
    },
    reset(keys = []) {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (Object.prototype.hasOwnProperty.call(this, key)) {
          let values = defaultValues()[key];
          if (!values) continue;
          this.setState({ [key]: values });
        }
      }
    },
    toggleIntercom() {
      if (!authStore.isAuthenticated && !this.intercom.show) {
        this.intercom.showEmailForm = true;
        return;
      }
      this.intercom.show = !this.intercom.show;
    },
    async initializeData(skipInitializeProxy = false) {
      if (!authStore.isAuthenticated) {
        this.initialized = true;
        return;
      }
      deviceStore.all = [];
      walletStore.all = [];
      paymentPlanStore.all = [];
      sessionStore.all = [];

      if (authStore.isOwner && !skipInitializeProxy) await ownerProxyStore.initialize();
      else ownerProxyStore.initialized = true;

      this.initializePreferences();

      const isAdminManagerview = authStore.isAdmin && adminStore.isManagerView && !!adminStore.managerview;
      const isAdminUserview = authStore.isAdmin && adminStore.isUserView && !!adminStore.userview;
      if (authStore.isUser || authStore.isOwner || isAdminManagerview || isAdminUserview) {
        await walletStore.initialize();
        if (authStore.isOwner || isAdminManagerview) {
          await Promise.all([deviceStore.initialize(), paymentPlanStore.initialize()]);
        }
      }

      this.initialized = true;
    },
    initializeWindow() {
      // Init window variable/functions, most of them will be used in development and testing
      (window as any).changeCountryCode = (countryCode) => {
        const country = { code: countryCode, name: new Intl.DisplayNames(["en"], { type: "region" }).of(countryCode) };
        this.country = country;
        return "Changed country to " + JSON.stringify(country);
      };
      (window as any).changeMobikwikSettings = (payload = {}) => {
        window.mobikwikSettings = { ...window.mobikwikSettings, ...payload };
        return "Changed Mobikwik settings to " + JSON.stringify(window.mobikwikSettings);
      };
    },
    initializeTimezone() {
      const timezones = raw_timezones.map((v) => ({ name: v.text, id: v.value }));
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (!timezone) timezone = "UTC";
      const selectedTimezone = timezones.find((tz) => tz.id == timezone);
      this.timezone = selectedTimezone?.id || timezone;
    },
    async initializeLocation() {
      const onSuccessGetLocation = (coordinates) => {
        let { latitude, longitude } = coordinates;
        ConsoleHelpers.log("Detected location (latitude longitude):", latitude, longitude, ", getting country...");
        const country = LocationHelpers.getCountryByLatitudeAndLongitude(latitude, longitude);
        ConsoleHelpers.log("Detected country", country);
        this.country = country;
        this.location = { latitude, longitude };
      };

      // If web, use navigator.geolocation
      if (!Capacitor.isNativePlatform()) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            onSuccessGetLocation(coords);
          },
          (error) => {
            console.log("Failed to get location: ", error.message);
          }
        );
      } else {
        try {
          const { coords } = await Geolocation.getCurrentPosition();
          onSuccessGetLocation(coords);
        } catch (error) {
          console.log("Failed to get location: ", error.message);
        }
      }
    },
    async initializePreferences() {
      if (authStore.role === USER_ROLES.ADMIN) return;
      const preferences = await Api.getPrefs(ApiHelpers.getApiPrefix(authStore.role));
      if (preferences) this.setState({ preferences });
    },
    async updatePreferences(payload: any) {
      return new Promise(async (resolve, reject) => {
        try {
          const preferences = adminStore.managerview?.preferences || adminStore.userview?.preferences || this.preferences || {};
          const updatedPreferences = {
            ...preferences,
            ...payload,
          };
          await Api.setPrefs({ prefs: updatedPreferences });
          if (authStore.isAdmin) {
            adminStore.getPreferencesForView();
            resolve(true);
            return;
          }
          this.initializePreferences();
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    resetData() {
      walletStore.all = [];
      paymentPlanStore.all = [];
      sessionStore.all = [];
      deviceStore.all = [];

      ownerProxyStore.resetState();
    },
  },
});
