<template>
  <v-layout class="justify-center align-center d-flex">
    <div class="d-flex xs12 offline-container">
      <v-avatar class="offline-avatar" color="grey-lighten-1">
        <v-icon size="x-large" icon="mdi-cloud-off-outline" />
      </v-avatar>
      <h2 class="mt-2">You are currently offline</h2>
      <v-btn class="plugzio-button mt-4" variant="outlined" rounded="pill" @click="reload"> Check Connectivity </v-btn>
    </div>
  </v-layout>
</template>

<script lang="ts" setup>
const reload = () => location.reload();
</script>

<style lang="scss">
.offline-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.v-avatar {
  &.offline-avatar {
    height: 96px !important;
    width: 96px !important;
  }
}
</style>
