export const AUTHENTICATED_ROUTES = [
  "dashboard",
  "activate-plug",
  "sessions",
  "wallet",
  "settings",
  "feedback",
  "outlet-management",
  "device-network-management",
  "ble-configuration",
  "payment-plan-form",
  "payment-plan-form-edit",
  "access-plans",
  "wallets",
  "activate-device",
  "service-subscription",
  "getting-started",
  "advance-settings",
  "owner-dashboard",
  "owner-activate-plug",
  "owner-wallet",
  "owner-service-subscription",
  "owner-settings",
  "owner-feedback",
  "owner-outlet-management",
  "owner-device-network-management",
  "owner-access-plans",
  "owner-wallets",
  "owner-getting-started",
  "owner-advance-settings",
  "owner-account-selection",
  "test",
  "owner-test",
  "owner-ble-configuration",
  "owner-payment-plan-form",
  "owner-payment-plan-form-edit",
  "owner-get-started",
  "owner-configure-subscription",
  "owner-load-balancing",
  "owner-load-balancing-form",
  "owner-load-balancing-form-edit",
  "admin-managerview-load-balancing",
  "admin-managerview-load-balancing-form",
  "admin-managerview-load-balancing-form-edit",
  "admin-dashboard",
  "admin-managerview",
  "admin-managerview-dashboard",
  "admin-managerview-outlet-management",
  "admin-managerview-device-network-management",
  "admin-managerview-paymentplans",
  "admin-managerview-payment-plan-form",
  "admin-managerview-payment-plan-form-edit",
  "admin-managerview-wallets",
  "admin-managerview-subscription",
  "admin-managerview-configuration",
  "admin-wifisetup",
  "admin-deviceview",
  "admin-deviceview-dashboard",
  "admin-deviceview-info-and-logs",
  "admin-deviceview-ocpp-config",
  "admin-deviceview-heartbeat-analytics",
  "admin-userview",
  "admin-userview-dashboard",
  "admin-userview-sessions",
  "admin-userview-wallets",
  "admin-userview-configuration",
  "admin-settings",
  "admin-configure-device-subscriptions",
  "admin-configure-device-subscriptions-time",
  "admin-configure-device-subscriptions-plan",
];

export const PUBLIC_ROUTES = [
  "register",
  "registered",
  "login",
  "forgot-password",
  "new-password",
  "terms-of-service",
  "privacy-policy",
  "setup",
  "verify-mobikwik",
  "owner-register",
  "owner-registered",
  "owner-login",
  "owner-forgot-password",
  "owner-new-password",
  "owner-terms-of-service",
  "owner-privacy-policy",
  "owner-setup",
  "owner-verify-mobikwik",
  "admin-login",
];
