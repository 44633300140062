import { createRouter, createWebHashHistory } from "vue-router";

// TODO: Delete this if unused
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => {
//     if (err.name !== 'NavigationDuplicated') throw err
//   });
// }

import { authStore } from "@/store-pinia";
import routes, { PageTypes } from "./routes";

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (route) => {
  const publicPages = routes.find((r) => r.name === PageTypes.NOT_AUTHENTICATED)?.children?.map((r) => r.name);
  if (!authStore.isAuthenticated && !publicPages?.includes(route.name)) return "/login";
});

export default router;
