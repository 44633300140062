/**
 * This store is mostly for running store actions that will be subscribed by the components.
 * More or less using the same concept with Event Bus or Websocket
 */

import { ConsoleHelpers } from "@/library/helpers";
import { defineStore } from "pinia";

export default defineStore("Event", {
  state: () => ({}),
  getters: {},
  actions: {
    onMobileAppResumed() {
      ConsoleHelpers.log("Mobile App Resumed");
    },
    onMobileAppPaused() {
      ConsoleHelpers.log("Mobile App Paused");
    },
    openDateRangeComponent() {},
    fetchBillingLog(deviceId: number) {},
    onSwiped(direction: string) {},
  },
});
