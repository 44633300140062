/*
  This store is to manage the data for the filters so the filters can be used across pages
  i.e. if we want to filter data using date range, we can use the same date range filter on dashboard and sessions page
*/

import { DateHelpers } from "@/library/helpers";
import moment from "moment-timezone";
import { defineStore } from "pinia";
import { globalStore } from ".";

export default defineStore("Filter", {
  state: () => ({
    search: null,
    enableRegexSearch: false,
    startDatetime: null,
    endDatetime: null,
  }),
  getters: {
    endDatetimeEndOfDay: (state) => (state.endDatetime ? moment.unix(state.endDatetime).endOf("day").unix() : null),
    startDatetimeStartOfDay: (state) => (state.startDatetime ? moment.unix(state.startDatetime).startOf("day").unix() : null),
    // This is used when the time picker allows flexibility for custom times
    dateTimeUnix: (state) => (datetimeType: string, adjustTo?: string) => {
      const datetime = state[datetimeType];
      if (!datetime) return null;

      const momentDate = moment.unix(datetime);

      if (adjustTo === "startOfDay") return momentDate.startOf("day").unix();
      if (adjustTo === "endOfDay") return momentDate.endOf("day").unix();

      return momentDate.unix();
    },
    processedDatetime: (state) => {
      const timezone = window?.timezone || globalStore.timezone;
      return DateHelpers.processDates({ since: state.startDatetime, till: state.endDatetime }, timezone);
    },
  },
  actions: {
    set(payload: any = {}) {
      for (const key in payload) {
        if (this.hasOwnProperty(key)) this[key] = payload[key];
      }
    },
  },
});
