class ApiHelpers {
  getApiPrefix(role: "admin" | "owner" | "user", activeAdminView: string = null) {
    if (role !== "admin") return role;
    return activeAdminView === "managerview" ? "admin/owner" : activeAdminView === "userview" ? "admin/user" : "admin";
  }
  getActAsPayload(actAsUsername = null) {
    return actAsUsername ? { actAsOwnerUsername: actAsUsername } : {};
  }
  appendValueToPayload(payload: any, key: string, value: any) {
    return !!value ? { ...payload, [key]: value } : payload;
  }
  getUsernamePayloadOfViewAs(activeAdminView: string = null, managerview = null, userview = null) {
    if (!activeAdminView) return {};
    if (activeAdminView === "managerview") return managerview ? { ownerUsername: managerview.username || managerview.owner_username } : {};
    if (activeAdminView === "userview") return userview ? { username: userview.username } : {};
  }
}

export default new ApiHelpers();
