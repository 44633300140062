import { USER_ROLES } from "@/constants";
import { AdminViews, DARK_SESSION_STATUS_COLOR, SESSION_STATUS_COLOR, SESSION_STATUS_COLOR_CLASS } from "@/interfaces";
import Api from "@/library/apis/Api";
import { SessionHelpers, SortHelpers, TimeHelpers, findPlan } from "@/library/helpers";
import TimezoneHelper from "@/library/helpers/TimezoneHelper";
import { cloneDeep, uniqBy } from "lodash-es";
import moment from "moment-timezone";
import { defineStore } from "pinia";
import { adminStore, authStore, deviceStore, filterStore, globalStore, walletStore } from ".";

const mapData = (v: any) => {
  const session = cloneDeep(v);
  const currentEpoch = moment().unix();

  const offset = TimezoneHelper.getCurrentTimezoneOffsetDifference(window.timezone || globalStore.timezone);
  session.starttime =
    moment
      .unix(session.starttime)
      .tz(window.timezone || globalStore.timezone)
      .unix() - offset;
  session.updated = session.endtime ? session.endtime : session.last_update_timestamp ? session.last_update_timestamp : session.starttime;
  if (session.updated < session.starttime) session.updated = session.starttime;
  if (session.endtime && session.endtime < session.starttime) session.endtime = session.starttime;

  session.plug_payment_access_plan = typeof session.plug_payment_access_plan == "string" ? JSON.parse(session.plug_payment_access_plan || {}) : session.plug_payment_access_plan;

  return {
    ...session,
    active: !session.endtime,
    get amount() {
      return this.total_cost === 0 ? "0.00" : parseFloat(this.total_cost).toFixed(2);
    },
    get class() {
      return SESSION_STATUS_COLOR_CLASS[this.statusCode];
    },
    get consumption() {
      return this.total_consumption ? (this.total_consumption / 1000).toFixed(3) + " kWh" : 0 + " kWh";
    },
    get duration() {
      return !this.endtime ? this.lapse : TimeHelpers.lapse(moment.unix(this.starttime), moment.unix(this.endtime));
    },
    get duration_raw() {
      return !this.endtime ? this.updated - this.starttime : this.endtime - this.starttime;
    },
    get endtime_formatted() {
      return this.endtime ? moment.unix(this.endtime).tz(globalStore.timezone).format("DD-MMM-YYYY hh:mm:ss A") : null;
    },
    get graph_timeformat() {
      const start_date = moment.unix(this.starttime).tz(globalStore.timezone).format("DD");
      const end_date = moment.unix(this.endtime).tz(globalStore.timezone).format("DD");
      return start_date != end_date ? "MMM D - h:mm:ss A" : "h:mm:ss A";
    },
    get lapse() {
      return !this.endtime ? TimeHelpers.lapse(moment.unix(this.starttime), moment.unix(this.updated)) : "00:00:00";
    },
    get plug() {
      return (deviceStore.mappedData?.length > 0 ? deviceStore.mappedData.find((device: any) => device.id === session.plug_id) : session.plug) || null;
    },
    get plug_identifier() {
      return (!session.plug_identifier && this.plug ? this.plug?.plug_identifier : session.plug_identifier) || null;
    },
    get plug_description() {
      return this.plug ? this.plug.description : null;
    },
    plug_payment_access_plan: session.plug_payment_access_plan,
    get stale_time() {
      return !this.endtime ? currentEpoch - this.updated : 0;
    },
    get pricings() {
      const getAmount = (pricing: any, duration: any, consumption: any, includeStart = false) => {
        let amount = 0;
        if (includeStart && pricing?.startSessionCost) amount += pricing.startSessionCost;
        if (pricing?.hRate) amount += (duration / 3600) * pricing.hRate;
        if (pricing?.whRate) amount += consumption * pricing.whRate;
        return amount;
      };

      const default_pricing = {
        plan: {
          hRate: this.plug_payment_access_plan?.hRate || 0,
          whRate: this.plug_payment_access_plan?.whRate || 0,
          startSessionCost: this.plug_payment_access_plan?.startSessionCost || 0,
          amount: getAmount(cloneDeep(this.plug_payment_access_plan), this.durationRaw, this.total_consumption, true),
        },
        time: {
          start: this.starttime ? moment.unix(this.starttime).tz(globalStore.timezone) : null,
          end: this.endtime ? moment.unix(this.endtime).tz(globalStore.timezone) : null,
        },
      };
      const isConditionalPlan = !!this?.plug_payment_access_plan && !!this?.plug_payment_access_plan.timezone;

      const pricings = isConditionalPlan ? [] : [default_pricing];

      if (isConditionalPlan) {
        for (let i = 1; i < this.logs.length; i++) {
          const d = this.logs[i];
          const timestamp = moment.unix(d.logitem_timestamp);
          const session_start: any = moment.unix(this.logs[i - 1].logitem_timestamp);
          const session_end: any = timestamp;
          const duration = d.logitem_timestamp - this.starttime;
          const consumption: number = d.consumption;
          const pricing = cloneDeep(findPlan({ plan: this.plug_payment_access_plan, momenttime: timestamp, duration, consumption }));
          const amount = getAmount(cloneDeep(pricing), (session_end - session_start) / 1000, this.logs[i].consumption - this.logs[i - 1].consumption, pricings.length === 0);

          const lastPricing: any = pricings[pricings.length - 1] ? pricings[pricings.length - 1].plan : {};
          const isPricingSame = lastPricing.hRate == pricing.hRate && lastPricing.whRate == pricing.whRate;
          if (pricings.length > 0 && isPricingSame) {
            pricings[pricings.length - 1].time.end = session_end.tz(globalStore.timezone);
            pricings[pricings.length - 1].plan.amount += amount;
          } else if (pricings.length === 0 || !isPricingSame) {
            pricing.amount = amount;
            pricings.push({ plan: pricing, time: { start: session_start.tz(globalStore.timezone), end: session_end.tz(globalStore.timezone) } });
          }
        }
      }

      return pricings;
    },
    get starttime_formatted() {
      return moment.unix(this.starttime).tz(globalStore.timezone).format("DD-MMM-YYYY hh:mm:ss A");
    },
    get status() {
      return !this.endtime ? "ON" : "OFF";
    },
    get statusCode() {
      return SessionHelpers.getStatusCode(session);
    },
    // TODO: Remove class property once we're sure that it's not being used as it is changed to color
    get timeline() {
      const sessionStatusColor = globalStore.isDarkTheme ? DARK_SESSION_STATUS_COLOR : SESSION_STATUS_COLOR;
      return [
        {
          tag: "START",
          content: moment.unix(this.starttime).tz(globalStore.timezone).format("DD-MMM-YYYY hh:mm:ss A"),
          // class: this.class,
          color: sessionStatusColor[this.statusCode],
          icon: "mdi-power-plug",
        },
        {
          tag: this.endtime ? "END" : "UPDATED",
          content: this.endtime
            ? moment.unix(this.updated).tz(globalStore.timezone).format("DD-MMM-YYYY hh:mm:ss A")
            : moment.unix(this.last_update_timestamp).tz(globalStore.timezone).format("DD-MMM-YYYY hh:mm:ss A"),
          // class: this.class,
          color: sessionStatusColor[this.statusCode],
          icon: this.endtime ? "mdi-power-plug-off" : "mdi-power-plug",
        },
      ];
    },

    get update_formatted() {
      return TimeHelpers.ago(this.updated, globalStore.timezone);
    },
    get wallet() {
      let wallet_id = authStore.role == USER_ROLES.USER ? this.user_wallet_id : this.owner_wallet_id;
      if (authStore.role == USER_ROLES.ADMIN) {
        wallet_id = adminStore.activeView == AdminViews.USERVIEW ? this.user_wallet_id : this.owner_wallet_id;
      }
      return walletStore.all && !!wallet_id ? walletStore.all.find((wallet) => wallet.id === wallet_id) : null;
    },
  };
};

const calculateLogs = (session: any, logs: any[]) => {
  logs.sort((a: any, b: any) => a.logitem_timestamp - b.logitem_timestamp);

  let span = logs.length;
  let timeSpan = null; // in mins

  if (timeSpan) {
    let oldData = logs;
    let endData = oldData.pop();
    let till = oldData[oldData.length - 1].logitem_timestamp;
    let count = 0;
    let set = [];
    let newData = [];
    do {
      let time = moment
        .unix(session.starttime)
        .tz(globalStore.timezone)
        .add(timeSpan * count, "minutes")
        .startOf("minute")
        .unix();
      set.push(time);
      count = count + 1;
      if (time > till) break;
    } while (count < span);

    set.forEach((value) => {
      let log = oldData.reduce((r, a, i, aa) => (i && Math.abs(aa[r].logitem_timestamp - value) < Math.abs(a.logitem_timestamp - value) ? r : i), -1);
      if (log) newData.push(oldData[log]);
    });

    logs = newData;
    logs.push(endData);
  }

  logs.unshift({
    consumption: 0,
    logitem_timestamp: session.starttime,
  });

  const defaultVoltageValue = logs[1]?.voltage || 0;

  let day = moment.unix(logs[0].logitem_timestamp).tz(globalStore.timezone).format("Md");
  logs.forEach((o) => {
    let time = moment.unix(o.logitem_timestamp).tz(globalStore.timezone);
    let logDay = time.format("Md");
    if (day !== logDay) {
      day = logDay;
    }
    o.timestamp = time.format(session.graph_timeformat);
    o.consumptionkWh = o.consumption / 1000;
    o.current = o.current <= 0 ? 0 : o.current;
    o.voltage = defaultVoltageValue <= 0 ? 0 : defaultVoltageValue;

    return o;
  });
  return logs;
};

export default defineStore("Session", {
  state: () => ({
    all: [],
    ongoingSessions: [],
    // intervaledFetching is to fetch the data during the interval
    intervaledFetching: null,
    loading: false,
    filters: {},
    isDataFetched: false,
    loadingLogIds: [],
    // Processed device is for when activate/kill a device session so we know where to show the loading
    processedDevices: [],
    processingTimeout: 120,
  }),
  getters: {
    activeSessions: (state) => state.all.filter((session) => !session.endtime),
    mappedData: (state) => state.all.map(mapData).sort(SortHelpers.compareValues("plug_identifier")),
    filteredData(state) {
      return SessionHelpers.filterAndSort(cloneDeep(this.mappedData), state.filters);
    },
    statistics() {
      return SessionHelpers.getStats(this.mappedData);
    },
    getSessionById() {
      return (sessionId: number) => {
        return this.mappedData.find((session: any) => session.id === sessionId);
      };
    },
  },
  actions: {
    initialize() {
      this.filters = {};
      // Disable intervaled fetching for now
      if (!this.intervaledFetching && false) {
        this.intervaledFetching = setInterval(() => this.fetchData(), window.SESSION_REFRESH_INTERVAL);
      }
    },
    clearIntervaledFetching() {
      if (!this.intervaledFetching) return;
      clearInterval(this.intervaledFetching);
      this.intervaledFetching = null;
    },
    async fetchData() {
      if (!filterStore.startDatetime || !filterStore.endDatetime) return false;

      try {
        this.loading = true;
        const sessions: any = await Api.sessions({ since: filterStore.startDatetimeStartOfDay, till: filterStore.endDatetimeEndOfDay });
        // this.all = uniqBy([...(sessions || []).map((v) => ({ ...v, logs: [] })), ...this.all], "id");
        this.all = (sessions || []).map((v) => ({ ...v, logs: [] }));
      } catch (error) {
        if (window.location.hash.includes("/sessions")) {
          globalStore.snackbar.message = "Could not complete requests...";
          globalStore.snackbar.show = true;
        }
      } finally {
        this.loading = false;
        this.isDataFetched = true;
      }
    },
    async fetchOngoingSessions() {
      try {
        this.loading = true;
        const sessions: any = await Api.ongoingSessions();
        this.all = uniqBy([...this.all, ...(sessions || []).map((v) => ({ ...v, logs: [] }))], "id");
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async fetchLogData(sessionId: number) {
      if (!filterStore.startDatetime || !filterStore.endDatetime) return false;

      try {
        const index = this.all.findIndex((s) => s.id === sessionId);
        if (index === -1) throw new Error("Session not found");

        const session = cloneDeep(this.mappedData.find((s) => s.id === sessionId));

        this.loadingLogIds.push(sessionId);

        const sessionLogData: any = await Api.sessionLogs({
          sessionId: session.id,
          since: session.starttime,
          till: session.updated + 1,
        });

        const processedLogData = sessionLogData?.map((log) => ({
          consumption: log.consumption ?? 0,
          current: log.current ?? 0,
          voltage: log.voltage ?? 0,
          logitem_timestamp: log.logitem_timestamp,
          timestamp: log.timestamp,
        })) || [
          {
            session_id: sessionId,
            consumption: 0,
            current: 0,
            voltage: 0,
            logitem_timestamp: session.updated,
            timestamp: "",
          },
        ];

        const calculatedLogs = calculateLogs(session, processedLogData);

        this.all[index].logs = calculatedLogs;
      } catch (error) {
      } finally {
        this.loadingLogIds.splice(this.loadingLogIds.indexOf(sessionId), 1);
      }
    },
    modifyProcessedDevices(payload: any) {
      const index = this.processedDevices.findIndex((d) => d.identifier == payload.identifier);
      if (index == -1) return this.processedDevices.push(payload);
      this.processedDevices[index] = payload;
    },
    removeProcessedDevices(identifier: string) {
      this.processedDevices = this.processedDevices.filter((d) => d.identifier != identifier);
    },
    subscribeStopSessionAction(identifier: string) {},
  },
});
