import moment from "moment-timezone";
import SortHelpers from "./SortHelpers";
class DeviceHelpers {
  STABLE_AMPERAGE_CONVERTER = 0.8;

  deviceModes = {
    always_off: 0,
    always_on: 1,
    smart: 2,
    bluetooth: 3,
  };
  models = {
    plugzio_ocpp: "Plugzio OCPP",
    ocpp: "3rd Party OCPP",
    universal: "Universal",
    global: "Global",
    integrated: "Integrated",
  };
  communicationTypes = {
    threeG: "3G",
    wifi: "WiFi",
    ltem: "LTE-M",
  };

  filter(plugs, filters) {
    if (!filters) return plugs;
    if (filters.search) {
      if (!filters.enableRegexSearch) {
        let search = filters.search.toLowerCase();
        plugs = plugs.filter((outlet) => {
          let identifier = outlet.identifier ? outlet.identifier.toLowerCase() : "";
          let description = outlet.description ? outlet.description.toLowerCase() : "";
          let accessPlan = outlet.access_plan ? outlet.access_plan.toLowerCase() : "";
          let remaining = outlet.remaining ? outlet.remaining.toLowerCase() : "";
          let start = outlet.start ? outlet.start.toLowerCase() : "";
          let expiry = outlet.expiry ? outlet.expiry.toLowerCase() : "";
          let auto = outlet.auto ? outlet.auto.toLowerCase() : "";
          let service_tier_id = typeof outlet.service_tier_id === "string" ? outlet.service_tier_id.toLowerCase() : "";
          let serial_no = outlet.serial_no ? outlet.serial_no.toLowerCase() : "";
          let last_heard_time_text = (outlet.last_heard_time_text || "").toLowerCase();
          const subscriptionModelText = (outlet.subscriptionModelText || "").toLowerCase();
          const pingStatus = typeof outlet.ping_status == "boolean" ? (outlet.ping_status ? "ping: pass" : "ping: fail") : "";
          const addCustomWifiNetworkStatus =
            typeof outlet.custom_wifi_network_status == "boolean" ? (outlet.custom_wifi_network_status ? "custom wi-fi network: pass" : "custom wi-fi network: fail") : "";
          const rebootStatus = typeof outlet.reboot_status == "boolean" ? (outlet.reboot_status ? "reboot: pass" : "reboot: fail") : "";
          const pingAfterRebootStatus =
            typeof outlet.ping_after_reboot_status == "boolean" ? (outlet.ping_after_reboot_status ? "ping after reboot: pass" : "ping after reboot: fail") : "";

          if (
            identifier.indexOf(search) > -1 ||
            description.indexOf(search) > -1 ||
            accessPlan.indexOf(search) > -1 ||
            remaining.indexOf(search.trim()) > -1 ||
            start.indexOf(search) > -1 ||
            expiry.indexOf(search) > -1 ||
            auto.indexOf(search.trim()) > -1 ||
            service_tier_id.indexOf(search) > -1 ||
            serial_no.indexOf(search) > -1 ||
            last_heard_time_text.indexOf(search) > -1 ||
            subscriptionModelText.indexOf(search) > -1 ||
            pingStatus.indexOf(search) > -1 ||
            addCustomWifiNetworkStatus.indexOf(search) > -1 ||
            rebootStatus.indexOf(search) > -1 ||
            pingAfterRebootStatus.indexOf(search) > -1
          )
            return plugs;
        });
      } else {
        try {
          const search = new RegExp(filters.search, "");
          plugs = plugs.filter((outlet) => {
            const identifier = outlet.identifier || "";
            const description = outlet.description || "";
            const accessPlan = outlet.access_plan || "";
            const remaining = outlet.remaining || "";
            const start = outlet.start || "";
            const expiry = outlet.expiry || "";
            const auto = outlet.auto || "";
            const service_tier_id = (outlet.service_tier_id || "").toString();
            const serial_no = outlet.serial_no || "";
            let last_heard_time_text = outlet.last_heard_time_text || "";
            const subscriptionModelText = outlet.subscriptionModelText || "";
            const pingStatus = typeof outlet.ping_status == "boolean" ? (outlet.ping_status ? "Ping: Pass" : "Ping: Fail") : "";
            const addCustomWifiNetworkStatus =
              typeof outlet.custom_wifi_network_status == "boolean" ? (outlet.custom_wifi_network_status ? "Custom Wi-Fi Network: pass" : "Custom Wi-Fi Network: Fail") : "";
            const rebootStatus = typeof outlet.reboot_status == "boolean" ? (outlet.reboot_status ? "Reboot: Pass" : "Reboot: Fail") : "";
            const pingAfterRebootStatus =
              typeof outlet.ping_after_reboot_status == "boolean" ? (outlet.ping_after_reboot_status ? "Ping After Reboot: Pass" : "Ping After Reboot: Fail") : "";

            return (
              identifier.match(search) ||
              description.match(search) ||
              accessPlan.match(search) ||
              remaining.match(search) ||
              start.match(search) ||
              expiry.match(search) ||
              auto.match(search) ||
              service_tier_id.match(search) ||
              serial_no.match(search) ||
              last_heard_time_text.match(search) ||
              subscriptionModelText.match(search) ||
              pingStatus.match(search) ||
              addCustomWifiNetworkStatus.match(search) ||
              rebootStatus.match(search) ||
              pingAfterRebootStatus.match(search)
            );
          });
        } catch (error) {
          console.log(error.message);
          plugs = [];
        }
      }
    }

    if (filters.sort_direction_desc) plugs.sort(SortHelpers.compareValues(filters.sort_by, "desc"));
    else plugs.sort(SortHelpers.compareValues(filters.sort_by));

    if (filters.hasOwnProperty("state") && filters.state !== "all") plugs = plugs.filter((plug) => plug.active_state == filters.state);
    if (filters.hasOwnProperty("status") && filters.status !== "all") plugs = plugs.filter((plug) => filters.status === plug.plugmode || (filters.status > 2 && plug.plugmode > 2));
    if (filters.hasOwnProperty("communication_type") && filters.communication_type !== "all")
      plugs = plugs.filter((plug) => filters.communication_type === plug.communication_type);
    if (filters.hasOwnProperty("power_setting") && filters.power_setting !== "all") plugs = plugs.filter((plug) => filters.power_setting === plug.plugmode);
    return plugs;
  }
  format(plug, variables) {
    if (!plug) return null;

    plug.active = !!variables.sessions ? !!variables.sessions.find((a) => a.plug_id === plug.id) : false;
    plug.active_state = plug.active || plug.plugmode === 1;

    plug.plan = null;
    if (!!plug.plan_id) plug.plan = !!variables.plans ? variables.plans.find((plan) => plan.id === plug.plan_id) : null;
    if (plug.plan && typeof plug.plan.params == "string") plug.plan.params = JSON.parse(plug.plan.params);

    plug.access_settings = plug.ispublic ? "Public" : "Private";
    plug.access_plan = plug.ispublic && plug.plan ? plug.plan.params.description : "";

    plug.mode = "Always Off";
    plug.color = "#f44336";
    if (plug.plugmode == 1) {
      plug.mode = "Always On";
      plug.color = "#00acc1";
    } else if (plug.plugmode == 2) {
      plug.mode = "Smart";
      plug.color = "#FFA500";
    } else if (plug.plugmode >= 3) {
      plug.mode = "Bluetooth";
      plug.color = "#000";
    }

    const start = moment.unix(plug.start_timestamp);
    const expiry = moment.unix(plug.expiry_timestamp);
    const today = moment();
    const diff = expiry.diff(today, "days");
    plug.auto = plug.payment_wallet_id ? "ON" : "OFF";
    plug.auto_class = plug.payment_wallet_id ? "text-green" : "text-orange";
    plug.start = start.format("DD-MMM-YYYY hh:mm:ss A");
    plug.expiry = expiry.format("DD-MMM-YYYY hh:mm:ss A");
    plug.diff = diff;

    plug.expired = "bill-expired";
    plug.remaining = "Disabled";
    plug.remaining_raw = 0;
    if (plug.state != 0) {
      plug.expired = diff <= 7 ? "bill-warning" : diff <= 30 ? "bill-notify" : "";
      plug.remaining_raw = plug.diff;
      plug.remaining = plug.diff < 1 ? `${Math.abs(plug.diff)} Days Expired` : plug.diff + " Days";
    }

    const { communication, communication_type } = this.parseSerialNumber(plug.serial_no || "") || {};
    plug.communication = communication;
    plug.communication_type = communication_type;

    plug.logs = [];
    plug.expanded = false;
    plug.busy = false;

    return plug;
  }
  formatNetworkResponse(data: any) {
    if (!data) return null;
    return {
      ...data,
      strength: data.strength ? (typeof data.strength === "number" ? `${(data.strength || 0).toFixed(2)}%` : data.strength) : null,
      quality: data.quality ? (typeof data.strength === "number" ? `${(data.quality || 0).toFixed(2)}%` : data.quality) : null,
    };
  }
  parseSerialNumber(serial_number) {
    const model_regexs = {
      plugzio_ocpp: /\d{3}H\d{2}V\d/,
      ocpp: /\d{3}P\d{2}V\d/,
      universal: /\d{3}[CLWF]\d{2}V\dU/,
      global: /\d{3}[CLWF]\d{2}V\dG/,
      integrated: /\d{3}[CLWF]\d{2}V\d/,
    };

    const communication_regexs = {
      threeG: /\d{3}C/,
      wifi: /\d{3}[WF]/,
      ltem: /\d{3}L/,
      ocpp: /\d{3}P/,
      plugzio_ocpp: /\d{3}H/,
    };

    let model = null;
    for (const key in model_regexs) {
      if (serial_number.match(model_regexs[key])) {
        model = key;
        break;
      }
    }

    let communication = null;
    for (let key in communication_regexs) {
      if (serial_number.match(communication_regexs[key])) {
        if (key === "ocpp") key = "wifi"; // ocpp always has WiFi communication type
        if (key === "plugzio_ocpp") key = "wifi"; // plugziocpp has Ethernet/Cellular/WiFi communication type, however for now we only support WiFi

        communication = key;
        break;
      }
    }

    const voltage = serial_number.match(/\d{3}/);
    const current = serial_number.match(/[A-Z]\d{2}[A-Z]/);
    const version = serial_number.match(/V[0-9]+/);

    return {
      model: this.models[model],
      communication: this.communicationTypes[communication],
      voltage: voltage ? voltage[0] : "",
      current: current ? current[0].substring(1, 3) : "",
      version: version ? version[0].replace("V", "") : "",
      communication_type: communication == "wifi" ? "WiFi" : "Cellular",
      get power() {
        const power = (parseInt(this.voltage) * parseInt(this.current)) / 1000;
        return isNaN(power) ? "" : power.toFixed(2);
      },
    };
  }
  parseInventoryStatus(status) {
    const texts = ["Unknown", "Assembled", "In Certification", "Certified", "Ready-to-Ship", "Shipped", "Decommissioned", "Demo"];

    return texts[status] ? texts[status] : "Invalid Value";
  }
  getRowColors(item, isDarkTheme) {
    const plug = item.internalItem.raw;

    let backgroundColor = "";

    if (plug.state === 0 || plug.remaining_raw === 0) backgroundColor = isDarkTheme ? "rgba(182, 182, 169, 0.35)" : "rgba(50, 50, 50, 0.25)";
    else if (plug.diff <= 7) backgroundColor = "rgba(244, 67, 54, 0.35)";
    else if (plug.diff <= 30) backgroundColor = "rgba(226, 220, 30, 0.35)";

    return { style: { backgroundColor } };
  }
  // Device should already contains wallet object
  getSubscriptionModels(device: any, dataplan: any) {
    const currency = device.wallet?.currency || "";
    const cost = dataplan?.[`cost_${currency.toLowerCase()}`] || 0;

    let payAsYouGo = null;
    try {
      payAsYouGo = JSON.parse(dataplan.pay_as_you_go);
    } catch (e) {}

    const payAsYouGoCost = (payAsYouGo?.priceList || []).find((v) => v.currency === currency)?.cost || 0;
    if (!cost && !payAsYouGoCost) return ["Free"];

    let subscriptionModels = [];

    if (cost > 0) subscriptionModels.push(`$${cost.toFixed(2)}/month`);
    if (payAsYouGoCost > 0) {
      subscriptionModels.push(`$${payAsYouGoCost.toFixed(2)}/activation`);
    }

    return subscriptionModels;
  }
}

export default new DeviceHelpers();
