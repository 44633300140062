<template />

<script setup>
import { authStore, globalStore } from "@/store-pinia";
import { Capacitor } from "@capacitor/core";
import { useIntercom } from "@mathieustan/vue-intercom";
import { capitalize } from "lodash-es";
import { computed, nextTick, onBeforeUnmount, onMounted, watch } from "vue-demi";

const $intercom = useIntercom();

const user_id = computed(() => `${authStore.isAuthenticated ? authStore.role : "unknown"}-${authStore.email || globalStore.intercom.email || "undefined"}`);
const intercomConfig = computed(() => ({
  user_id: user_id.value,
  name: user_id.value,
  email: authStore.email || globalStore.intercom.email || "undefined",
  environment: capitalize(import.meta.env.VITE_APP_SERVER),
  "User Type": capitalize(authStore.role || "-"),
  location: `${globalStore.location.latitude && globalStore.location.longitude ? `${globalStore.location.latitude}, ${globalStore.location.longitude}` : "-"}`,
}));

onMounted(() => {
  nextTick(() => {
    $intercom.boot(intercomConfig.value);
  });
  nextTick(() => {
    document.body.classList.add(`intercom-hide-bubble`);
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "ios") {
      document.body.classList.add(`intercom-ios`);
    }
  });
});
onBeforeUnmount(() => {
  document.body.classList.remove(`intercom-hide-bubble`);
  if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === "ios") {
    document.body.classList.remove(`intercom-ios`);
  }
  nextTick(() => {
    $intercom.shutdown();
    document.cookie = document.cookie
      .split(" ")
      .filter((s) => s.includes("intercom"))
      .map((s) => `${s.split("=")[0]}=;`)
      .join(" ");
  });
});

watch(
  () => globalStore.location,
  () => {
    $intercom.update({
      location: `${globalStore.location.latitude && globalStore.location.longitude ? `${globalStore.location.latitude}, ${globalStore.location.longitude}` : "-"}`,
    });
  },
  { deep: true }
);
watch(
  () => intercomConfig.value,
  () => {
    nextTick(() => {
      $intercom.update(intercomConfig.value);
    });
  },
  { deep: true }
);
watch(
  () => globalStore.intercom.show,
  () => {
    document.body.classList[globalStore.intercom.show ? "remove" : "add"](`intercom-hide-bubble`);
    nextTick(() => $intercom[!globalStore.intercom.show ? "hide" : "show"]());
  }
);
</script>

<style lang="scss">
.intercom-hide-bubble {
  .intercom-lightweight-app,
  .intercom-app {
    display: none !important;
  }
}
.intercom-ios {
  .intercom-messenger-frame {
    top: 28px !important;
    max-height: calc(100% - 24px) !important;
  }
}
</style>
