export const OwnerProxyPermissions = {
  VIEW: "view",
  FULL: "full",
};

export const OwnerProxyOptions = {
  [OwnerProxyPermissions.VIEW]: null,
  [OwnerProxyPermissions.FULL]: ["general-modify"],
};

export const OwnerProxyFeaturePermissions = {
  DEVICE_CHANGE_MODE: ["general-modify"],
  DEVICE_LOAD_BALANCING_CREATE: ["general-modify"],
  DEVICE_LOAD_BALANCING_DELETE: ["general-modify"],
  DEVICE_LOAD_BALANCING_EDIT: ["general-modify"],
  DEVICE_NETWORK_CONFIGURE: ["general-modify"],
  DEVICE_NETWORK_INFO: ["general-modify"],
  DEVICE_NETWORK_REBOOT: ["general-modify"],
  DEVICE_NETWORK_TEST_CONNECTION: ["general-modify"],
  DEVICE_REGISTER: ["general-modify"],
  DEVICE_SET_USER_ACCESS: ["general-modify"],
  GET_STARTED: ["general-modify"],
  PAYMENT_PLAN_CREATE: ["general-modify"],
  PAYMENT_PLAN_EDIT: ["general-modify"],
  SERVICE_SUBSCRIPTION_CONFIGURE: ["general-modify"],
  SERVICE_SUBSCRIPTION_EXTEND: ["general-modify"],
  SERVICE_SUBSCRIPTION_REACTIVATE: ["general-modify"],
  SERVICE_SUBSCRIPTION_SET_AUTO_PAYMENT: ["general-modify"],
  SETTING_CHANGE_PASSWORD: [], // nothing allowed yet
  SETTING_OWNER_PROXY_CREATE: [], // nothing allowed yet
  SETTING_OWNER_PROXY_EDIT: [], // nothing allowed yet
  SETTING_PUBLIC_PROFILE: ["general-modify"],
  SETTING_SETTINGS: ["general-modify"],
  WALLET_CREATE: ["general-modify"],
  WALLET_EDIT: ["general-modify"],
  WALLET_REFUND: ["general-modify"],
  WALLET_TOPUP: ["general-modify"],
  WALLET_WITHDRAWAL: ["general-modify"],
};
