// import Vue from "vue"
import moment from "moment-timezone";

const MINUTE_UNIX = 60;
const HOUR_UNIX = MINUTE_UNIX * 60;
const DAY_UNIX = HOUR_UNIX * 24;

class TimeHelpers {
  getTotalDuration(duration, format = ":mm:ss") {
    let is_negative = duration < 0;
    duration = Math.abs(duration);
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration % 3600) / 60);
    let seconds = Math.floor(duration % 60);

    let formattedDuration = "";

    formattedDuration += hours.toString().padStart(2, "0") + ":";
    formattedDuration += minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");

    return (is_negative ? "-" : "") + formattedDuration;
  }
  getDuration(duration: number, format = ":mm:ss") {
    let is_negative = duration < 0;
    duration = Math.abs(duration);
    let d = moment.duration(duration);
    let h: any = Math.floor(d.asHours());
    if (h === 0) h = "00";
    let s = h + moment.utc(duration).format(format);

    return (is_negative ? "-" : "") + s;
  }
  lapse(start: any, end: any, format = ":mm:ss") {
    let ms = end - start;
    return this.getDuration(ms, format);
  }
  unixToTimeLapsed(timestamp: number | string, options?: { timezone?: string; showSecond?: boolean; showTimeOnDate?: boolean; isLongTimeUnit?: boolean }) {
    if (!timestamp || timestamp === "null") return null;

    const { timezone, showSecond, showTimeOnDate, isLongTimeUnit } = options || {};
    const unitMapper = {
      long: {
        second: "second",
        minute: "minute",
        hour: "hour",
      },
      short: {
        second: "sec",
        minute: "min",
        hour: "hr",
      },
    };
    const usedUnit = isLongTimeUnit ? unitMapper.long : unitMapper.short;

    const getText = (n: number, unit: "second" | "minute" | "hour") => `${n} ${usedUnit[unit]}${n > 1 ? "s" : ""} ago`;

    let duration = moment().unix() - timestamp;
    if (duration < MINUTE_UNIX) return !showSecond ? "Just now" : getText(duration, "second");

    if (duration < HOUR_UNIX) {
      let mins = Math.ceil(duration / MINUTE_UNIX);
      return getText(mins, "minute");
    }

    if (duration < DAY_UNIX) {
      let hours = Math.ceil(duration / HOUR_UNIX);
      return getText(hours, "hour");
    }

    let date = moment.unix(timestamp);
    if (!!timezone) date = date.tz(timezone);

    return date.format(showTimeOnDate ? "DD-MMM-YYYY hh:mm:ss A" : "DD-MMM-YYYY");
  }
  ago(timestamp: number, timezone = null) {
    let duration = moment().unix() - timestamp;
    if (duration < 60) {
      return "Just now";
    } else if (duration < 3600) {
      let mins = Math.ceil(duration / 60);
      return mins > 1 ? `${mins} mins ago` : `${mins} min ago`;
    } else if (duration < 86400) {
      let hours = Math.ceil(duration / 3600);
      return hours > 1 ? `${hours} hrs ago` : `${hours} hr ago`;
    } else {
      let date = moment.unix(timestamp);
      if (!!timezone) date = date.tz(timezone);
      return date.format("DD-MMM-YYYY");
    }
  }
  timeToUnix(time: string = "00:00:00", convertToJavascriptDate = true) {
    let t = time.split(":");
    let h = parseInt(t[0] || "0");
    let m = parseInt(t[1] || "0");
    let s = parseInt(t[2] || "0");

    return (h * 3600 + m * 60 + s) * (convertToJavascriptDate ? 1000 : 1);
  }
  handleTimeUpdate(v: string | number, type: string, tempHour?: string, tempMinute?: string, tempPeriod?: string) {
    const RADIX = 10;
    const NOON_HOUR = 12;
    const MIDNIGHT_HOUR = 0;

    const formatTimeValueToString = (value: number | string) => value.toString().padStart(2, "0");

    if (type === "hour" || type === "minute") {
      const value = formatTimeValueToString(v);
      if (type === "hour") tempHour = value;
      if (type === "minute") tempMinute = value;
    } else if (type === "period") {
      tempPeriod = v.toString();
      const hour = parseInt(tempHour || "0", RADIX);

      if (tempPeriod === "am") {
        tempHour = hour === NOON_HOUR ? formatTimeValueToString(MIDNIGHT_HOUR) : formatTimeValueToString(hour > NOON_HOUR ? hour - NOON_HOUR : hour);
      } else if (tempPeriod === "pm") {
        tempHour = hour === NOON_HOUR ? formatTimeValueToString(NOON_HOUR) : formatTimeValueToString(hour + NOON_HOUR);
      }
    }

    return { tempHour, tempMinute, tempPeriod };
  }
}

export default new TimeHelpers();
