import timezones from "@/data/timezones.json";
import Api from "@/library/apis/Api";
import { SortHelpers } from "@/library/helpers";
import AccessPlanHelpers from "@/library/helpers/AccessPlanHelpers";
import TimezoneHelper from "@/library/helpers/TimezoneHelper";
import { defineStore } from "pinia";
import { adminStore, globalStore, walletStore } from ".";

const mapData = (plan: any) => {
  let walletId = plan.params.walletId;
  plan.wallet = walletStore.mappedData.find((wallet) => wallet.id === walletId);
  plan.wallet_name = plan.wallet?.name || "";
  plan.searchable = AccessPlanHelpers.getSearchable(plan);
  return plan;
};

export default defineStore("Payment-Plan", {
  state: () => ({
    all: [],
    initialized: false,
    loading: false,
  }),
  getters: {
    mappedData: (state) => state.all.map(mapData),
    hiddenIds: () => {
      if (adminStore.managerview) return (adminStore.managerview.preferences || {}).hiddenPayments || [];
      return (globalStore.preferences || {}).hiddenPayments || [];
    },
    visibleData() {
      return this.mappedData.filter((paymentPlan: any) => !this.hiddenIds.includes(paymentPlan.id));
    },
    visibleMappedData() {
      return this.mappedData.filter((paymentPlan: any) => !this.hiddenIds.includes(paymentPlan.id));
    },
    hiddenData() {
      return this.mappedData.filter((paymentPlan: any) => this.hiddenIds.includes(paymentPlan.id));
    },
    getPlanById: (state) => (id: number) => state.all.find((plan) => plan.id === id),
    getMappedPlanById() {
      return (id: number) => this.mappedData.find((plan) => plan.id === id);
    },
  },
  actions: {
    async initialize() {
      await this.fetchData();
      this.initialized = true;
    },
    async fetchData() {
      try {
        this.loading = true;
        const data: any = await Api.plugPaymentAccessPlans();
        this.all = (data || [])
          .map((plan: any) => {
            if (typeof plan.params == "string") plan.params = JSON.parse(plan.params);
            plan.params.whRate = (plan.params.whRate * 1000000000) / 1000000;
            if (!plan.params.hasOwnProperty("hRate")) plan.params.hRate = 0.0;
            if (!plan.params.hasOwnProperty("description")) plan.params.description = "Payment Plan " + plan.id;
            plan.name = plan.params.description;
            plan.description = plan.name;
            plan.sort_variable_timezone = -99;
            if (plan.params.hasOwnProperty("timezone")) {
              const selectedTimezone = timezones.find((tm) => tm.value == plan.params.timezone);
              plan.sort_variable_timezone = selectedTimezone ? TimezoneHelper.getNumberOffsetFromFullTimezone(selectedTimezone.text) : null;
            }
            return plan;
          })
          .sort(SortHelpers.compareValues("name"));
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async updateOrCreate(id: number = null, data: any) {
      return new Promise(async (resolve, reject) => {
        try {
          await (id ? Api.plugPaymentAccessPlansUpdate(data) : Api.plugPaymentAccessPlansCreate(data));
          this.fetchData();
          globalStore.snackbar.message = id ? "Payment Plan Updated" : "Payment Plan Created";
          globalStore.snackbar.show = true;

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
});
