import Api from "@/library/apis/Api";
import moment from "moment-timezone";
import { defineStore } from "pinia";
import { globalStore } from ".";

export default defineStore("Log", {
  state: () => ({
    data: null,
  }),
  getters: {
    mappedData(state) {
      if (!state.data) return [];
      return state.data.map((d) => {
        d.formatted_timestamp = moment(d.timestamp * 1000)
          .tz(globalStore.timezone)
          .format("YYYY-MM-DD HH:mm:ss");
        return d;
      });
    },

    parsedMessages(state) {
      if (!state.data) return [];

      const timestamps = state.data.map((d) => d.timestamp);
      const timestamp_diff = (Math.max(...timestamps) - Math.min(...timestamps)) / 3600 / 24;
      let timeformat = "hh A";
      if (timestamp_diff > 720) timeformat = "YYYY";
      else if (timestamp_diff > 180) timeformat = "MMM YYYY";
      else if (timestamp_diff > 40) timeformat = "MMM Do";
      else if (timestamp_diff > 7) timeformat = "ddd, MMM D";

      return state.data.map((d) => {
        const parsed = JSON.parse(d.message);
        const data = {} as any;
        for (const key in parsed) {
          for (const valueKey in parsed[key]) {
            if (valueKey === "uptime") continue;
            const value = parsed[key][valueKey];
            data[valueKey] = !isNaN(value) ? parseFloat(parseFloat(value).toFixed(3)) : null;
          }
          if (!data.uptime) {
            const uptime = parsed[key].uptime || 0;
            data.raw_uptime = uptime;
            data.uptime = `${Math.floor(uptime / 3600)
              .toString()
              .padStart(2, "0")}:${Math.floor((uptime % 3600) / 60)
              .toString()
              .padStart(2, "0")}:${Math.floor(uptime % 60)
              .toString()
              .padStart(2, "0")}`;
          }
          if (!data.timestamp) {
            data.raw_timestamp = d.timestamp;
            data.timestamp = moment.unix(d.timestamp).tz(globalStore.timezone).format(timeformat);
          }
        }
        return data;
      });
    },
  },
  actions: {
    getData(payload: any) {
      return new Promise((resolve, reject) => {
        const { formData, reset } = payload;
        Api.getLog(formData)
          .then((data: any) => {
            this.data = reset ? data : [...this.data, ...data];
            resolve(data);
          })
          .catch(reject);
      });
    },
  },
});
