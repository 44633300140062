import Api from "@/library/apis/Api";
import { defineStore } from "pinia";
import { adminStore, authStore, deviceStore, globalStore } from ".";

export default defineStore("Load-Balancing", {
  state: () => ({
    collectives: [],
    addPlugToCollective: null,
  }),
  getters: {
    groupedDevices(state) {
      const groups = {};
      for (const collective of state.collectives) {
        if (!groups[collective.the_collective_id]) {
          groups[collective.the_collective_id] = {
            the_collective_id: collective.the_collective_id,
            name: collective.name,
            amperage: collective.amperage,
            devices: [],
            filter_string: `${collective.name}#####${collective.amperage} A#####`,
            // add more fields if needed
          };
        }
        if (!collective.plug_identifier) continue;

        const plug = deviceStore.mappedData.find((p) => p.identifier === collective.plug_identifier);
        if (!plug) continue;

        const [description, access_plan] = [(plug || {}).description || "", (plug || {}).access_plan || ""];
        groups[collective.the_collective_id].devices.push({
          plug_identifier: collective.plug_identifier,
          min_amperage: collective.min_amperage,
          max_amperage: collective.max_amperage,
          default_amperage: collective.default_amperage,
          vote: collective.vote,
          description,
          access_plan,

          // need data of always on and ratio

          // add more fields if needed
        });
        groups[collective.the_collective_id].filter_string +=
          `${collective.plug_identifier}#####${collective.min_amperage} A#####${collective.max_amperage}#####${collective.default_amperage || ""}#####${collective.vote || ""}#####${description}#####${access_plan}`;
      }
      return Object.values(groups);
    },
    plugIdentifierGroup(state) {
      const plugIdentifierGroups = {};
      for (const collective of state.collectives) {
        if (!collective.plug_identifier) continue;

        plugIdentifierGroups[collective.plug_identifier] = {
          name: collective.name,
          id: collective.the_collective_id,
        };
      }
      return plugIdentifierGroups;
    },
  },
  actions: {
    async get() {
      try {
        globalStore.loading = true;
        const ownerId = authStore.isAdmin ? adminStore.managerview?.id : null;
        const collectives = await Api.loadBalancingCollectives(authStore.isAdmin ? { ownerId } : {});
        this.collectives = collectives || [];
      } catch (error) {
        console.error(error);
      } finally {
        globalStore.loading = false;
      }
    },
  },
});
