import { CancelableRequestInterface } from "@/interfaces";
import { defineStore } from "pinia";

export default defineStore("Request", {
  state: () => ({
    runningRequests: [] as CancelableRequestInterface[],
  }),
  getters: {},
  actions: {
    remove(id: string) {
      const index = this.runningRequests.findIndex((r) => r.id === id);
      if (index !== -1) {
        try {
          this.runningRequests[index].abortController.abort();
        } catch (e) {}
        this.runningRequests.splice(index, 1);
      }
    },
    cancelRunningRequests() {
      this.runningRequests.forEach((r) => {
        try {
          r.abortController.abort();
        } catch (e) {}
      });
      this.runningRequests = [];
    },
  },
});
