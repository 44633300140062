import pinia from "@/pinia";
import makeAdminStore from "./adminStore";
import makeAuthStore from "./authStore";
import makeDeviceNetworkStore from "./deviceNetworkStore";
import makeDeviceStore from "./deviceStore";
import makeEventStore from "./eventStore";
import makeFilterStore from "./filterStore";
import makeGlobalStore from "./globalStore";
import makeLoadBalancingStore from "./loadBalancing";
import makeLogStore from "./logStore";
import makeOwnerProxyStore from "./ownerProxyStore";
import makePaymentPlanStore from "./paymentPlan";
import makeRequestStore from "./requestStore";
import makeSessionStore from "./sessionStore";
import makeWalletStore from "./walletStore";

const adminStore = makeAdminStore(pinia);
const authStore = makeAuthStore(pinia);
const deviceStore = makeDeviceStore(pinia);
const deviceNetworkStore = makeDeviceNetworkStore(pinia);
const eventStore = makeEventStore(pinia);
const filterStore = makeFilterStore(pinia);
const globalStore = makeGlobalStore(pinia);
const loadBalancingStore = makeLoadBalancingStore(pinia);
const logStore = makeLogStore(pinia);
const ownerProxyStore = makeOwnerProxyStore(pinia);
const paymentPlanStore = makePaymentPlanStore(pinia);
const requestStore = makeRequestStore(pinia);
const sessionStore = makeSessionStore(pinia);
const walletStore = makeWalletStore(pinia);

export {
  adminStore,
  authStore,
  deviceNetworkStore,
  deviceStore,
  eventStore,
  filterStore,
  globalStore,
  loadBalancingStore,
  logStore,
  ownerProxyStore,
  paymentPlanStore,
  requestStore,
  sessionStore,
  walletStore,
};
