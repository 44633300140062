const policyTexts = {
  "IN-terms-of-service": `
    <h1 style="margin-top:0!important; font-weight:bold;">Plugzio Power Private Limited : Products Term of Use</h1>
    <p class="text-justify mb-1">
      Plugzio Indi Pvt Limited  owns and operates all PLUGZIO products, referred to as "we," "our," or "us" in this Privacy Policy. 
      When you proceed or use the services you  agree to the Internet Privacy Policy of the website ("the Website"), as well as the privacy policies of all Plugzio products, 
      which include the website, and our mobile app. The information, materials, goods, and services provided through the Website/ app , as well as access to and use of PLUGZIO products, are all subject to these Terms of Use. 
      Before using PLUGZIO goods or utilizing our services , please read the Terms of Use and disclaimers carefully.
      The Terms of Use for Plugzio products may be amended from time to time. All our products, including the website and app, are continuously being upgraded / modified to enhance user experience and provided updated applications and features, 
      you should check to see if the Terms of Use have been updated or altered on a regular basis.
    </p>

    <h1 style="font-weight:bold;">Using Plugzio India Pvt Limited  Products</h1>
    <p class="text-justify mb-1">
      When you use any PLUGZIO product, you agree to be legally bound by these Terms of Use, as they may be updated and posted on our products at any time. 
      Without limiting the foregoing, you agree to be legally bound by these terms and conditions of use as they relate to your use of or access to PLUGZIO products by using or accessing PLUGZIO products.
      You may download content for non-commercial, private, personal use only, as long as all copyright, trademark, and other proprietary notices are visible. 
      As a result of any such downloading or copying, no right, title, or interest in any downloaded content is transferred to you. 
    </p>
    <p class="text-justify mb-1">
      Without PLUGZIO's express permission, you undertake not to copy, modify, alter, display, distribute, sell, broadcast, or transmit any material given by any of PLUGZIOs products in any way.
      Please ensure that the  personal information you submit is accurate and complete, and that all ordering or registration details (where applicable) include your correct name, address, and other requested information.
      Please read our Privacy Policy using the link provided for more information on how we handle your personal information.
    </p>

    <h1 style="font-weight:bold;">Privacy Statement and Policies</h1>
    <p class="text-justify mb-1">
      We are devoted to protecting our users' privacy. We value your privacy and want you to be able to use and enjoy our goods without having to give over any of your personal information. 
      The PLUGZIO Privacy Policy will apply to all personal data supplied to PLUGZIO when using our products. The Privacy Policy for the platform also applies if you register or log in to access the services of the PLUGZIO Mobile Application cloud-based platform branded "PLUGZIO." 
      During the use of PLUGZIO products, all information submitted to PLUGZIO must be accurate, full, and up to date. If we have cause to suspect that erroneous, incomplete, or outdated information has been submitted, we may restrict or deny access to any PLUGZIO product.
    </p>
    
    <h1 style="font-weight:bold;">Intellectual Property Rights (IP Rights)</h1>
    <p class="text-justify mb-1">
      Intellectual property rights, including copyrights, trade names, trademarks, service marks, and logos, protect PLUGZIO products and materials made available on all PLUGZIO products.
      These rights are either owned by PLUGZIO or utilised by PLUGZIO under licence or with permission from the owner of such rights. The design, style, look, appearance, graphics, pictures, images, articles, stories, and other content available on the PLUGZIO products are all protected by intellectual property rights.
      You may not modify, copy, reproduce, republish, upload, post, transmit, or distribute any material or information on or downloaded from any of our PLUGZIO products, including but not limited to text, graphics, video, messages, code, and/or software, by any means or in any manner without our prior written consent.
    </p>
  
    <h1 style="font-weight:bold;">Linked Sites</h1>
    <p class="text-justify mb-1">
      We offer no warranties or representations concerning any other websites that you may visit through our PLUGZIO products or that may link to our goods. 
      When you visit another website, you acknowledge that it is unrelated to us and that we have no control over its content or availability. 
      Furthermore, a link to another website does not imply that we endorse or accept responsibility for its content or use, and we will not be liable for any loss or damage caused or alleged to be caused by or in connection with the use of or reliance on any content, 
      goods, or services available on or through any other website or resource.
    </p>
    
    <h1 style="font-weight:bold;">Limitation of Liability, Disclaimer of Warranties, and Indemnification</h1>
    <p class="text-justify mb-1">
      To the extent permitted by law, neither PLUGZIO nor its affiliates will be liable for any direct, indirect, consequential, or other damages, 
      including but not limited to property damage, loss of use, loss of business, economic loss, loss of data, or loss of profits, arising out of or in connection with your use or access to, 
      or inability to use or access, any PLUGZIO product or its content. Any new features, such as new material and/or the release of new software tools or resources, are subject to these Terms of Use unless otherwise noted.
      PLUGZIO will make every effort to ensure that the information and materials supplied on any PLUGZIO product are accurate. PLUGZIO, on the other hand, cannot guarantee the veracity of all information and materials and disclaims all duty or liability for the accuracy, 
      completeness, or authenticity of any information or materials provided by PLUGZIO products. We do not guarantee that any PLUGZIO product will operate without interruption or error, or that the PLUGZIO products will be free of viruses or other components that could harm equipment or software. PLUGZIO makes no promise that PLUGZIO goods will work with the equipment or software you use,
      and it also makes no guarantee that PLUGZIO products will be available at all times or at any given time. You must not attempt to interfere with the proper functioning of any of our PLUGZIO products, including any computer system, server, website, router, or other internet-connected devices, by circumventing security, tampering with, hacking into, or otherwise disrupting any computer system, server, website, router, or other internet-connected devices. 
      We have the right to change, suspend, or terminate any feature of our PLUGZIO products, including your access to them, at any time. You undertake to keep PLUGZIO and its affiliates blameless from any liability or loss arising from your breach of these PLUGZIO products' Terms of Use, including your use of the Website.
    </p>
    
    <h1 style="font-weight:bold;">Software as a Service- Terms and Conditions</h1>
    <p class="text-justify mb-1">
      Please read these terms and conditions of use ('Terms and Conditions') carefully before purchasing or availing the products and services (“Products”) of PLUGZIO India  Private Limited (“Company”). 
      These Terms and Conditions govern your (“you”, “your” and “yourself”) purchase of Company’s Products. Purchasing the Company’s Products indicates that you have read, understood, accepted and agreed to be bound by these Terms and Conditions. 
      Thank you for purchasing the Products from the Company. Notwithstanding anything to the contrary contained in any documents including purchase order, your purchase of the Products of the Company shall be governed by the following terms:
    </p>
    <ol class="text-justify" style="font-size:smaller;">
      <li class="ml-5">The Products are warranted against any electronic or mechanical defects for a period of 1 (one) year. Should a defect occur, the Company will repair or, at its option, replace the defective unit/ parts with new or rebuilt materials without charge for either parts or labour. Replacement unit/parts will be warranted for the remaining portion of the original warranty period. This warranty does not cover installation or damage from accident, misuse, abuse, improper wiring, incorrect voltage, operating the unit against the instructions in the user manual or any Product which has been opened, altered or tampered with.</li>
      <li class="ml-5">Products once delivered, shall not be eligible for return and no return or refund request shall be entertained by the Company, Company shall deliver the Product on the location specified by you while placing order, on delivery of the Product you agree that the title of the Product and risk thereto transfers to you.</li>
      <li class="ml-5">Company may provide installation service of the Products purchased subject to the area you live in, on opting installation service for purchased Product, you agree that on or before arrival of the Company’s representative to the location for installation, such location shall be cleared and ready for installation in accordance with the specifications provided by the Company including having access to electricity and location for installation shall be free from any cracks, improper structure integrity or any other defects. Installation location shall be in legal possession of the user. Company or Company’s representative reserves the right to refuse to install the Product on finding any shortcoming in the location for the installation.</li>
      <li class="ml-5">Intellectual property rights, including copyrights, trade names, trademarks, service marks, and logos, protect PLUGZIO products and materials made available on all PLUGZIO products. These rights are either owned by PLUGZIO or utilised by PLUGZIO under licence or with permission from the owner of such rights. The design, style, look, appearance, graphics, pictures, images, articles, stories, and other content available on the PLUGZIO products are all protected by intellectual property rights. Except where expressly invited to do so, you may not modify, copy, reproduce, republish, upload, post, transmit, or distribute any material or information on or downloaded from any of our PLUGZIO products, including but not limited to text, graphics, video, messages, code, and/or software, by any means or in any manner without our prior written consent.</li>
      <li class="ml-5">The Company makes no representations or warranties regarding the Products and associated services and is not responsible or liable for your use of Products. You acknowledge and agree that your decision use any Products is at your sole risk, and the Company will not be liable for any loss or damage, including injury, illness or death, arising out of or in connection with your use of the Products, whether directly or indirectly.</li>
      <li class="ml-5">You shall not use any Products, documentation, or Company’s confidential information (whether in whole or in part) to facilitate, assist or otherwise engage in (either directly or indirectly) the development, modification, sale and/or marketing of equipment, software, technology, services and/or documentation that are competitive to any of Company’s Products or service offerings.</li>
      <li class="ml-5">You undertake that you shall not: (1) use the Products for anything other than for the purpose of personal usage or usage through the platform of the Company or usage permitted by the Company; (2) modify, adapt, create derivative works of, reverse engineer, decompile, or disassemble or reconstruct, the Products or any derivative work or attempt to recreate a similar product; (3) distribute, sell, lease, rent, lend, sublicense, encumber, assign, transfer in any manner whatsoever the Products, other than in the manner permitted by the Company in writing; (4) use the Products in breach of any applicable law or in any activity that is in violation or breach of applicable law; (5) permit any third party to perform any maintenance services on the Products or effect modification or enhancement or software/engineering changes to the Products without the prior written consent of the Company.</li>
      <li class="ml-5">All intellectual property rights in and to the Products and any other developments thereto shall at all times belong the Company and any infringement of any Company intellectual property rights shall be subject to legal action and damages.</li>
      <li class="ml-5">These Terms and Conditions and the relationship between you and the Company shall be governed by the laws of India and appropriate courts and forums at Mumbai, Maharashtra shall have exclusive jurisdiction in any proceedings arising out of these Terms and Conditions.</li>
      <li class="ml-5">Unless otherwise provided in the Terms and Conditions, all trademarks, service marks, logos, trade names, and designs, whether registered or unregistered, used in connection with the Products are owned by the Company or its third party licensors. You may not use or display any such trademarks, service marks, logos, trade names, or designs owned by the Company or its third party licensors without the appropriate owner’s prior written consent.</li>
      <li class="ml-5">You agree to indemnify, defend, and hold harmless each of the Company Parties (as defined below) from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) that such parties may incur as a result of or arising from your breach or violation of these Terms and Conditions (including without limitation or representation, warranties and or covenant) or misrepresentation with respect to your information or arising out of your violation of any applicable laws, regulations including but not limited to intellectual property rights, payment of statutory dues and taxes, fraudulent transactions or other rights. The Company reserves the right to assume the exclusive defence and control of any matter otherwise subject to indemnification by you and, in such case, you agree to cooperate with the Company' defence of such claim. This clause shall survive the expiry or termination of these Terms and Conditions.</li>
      <li class="ml-5">Notwithstanding anything to the contrary in any document including these Terms and Conditions, Company’s total, cumulative, and aggregate liability towards you shall not exceed the amount paid by you towards the Product in dispute, whether the Company’s liability is based in contract, tort, equity, at law, and/or otherwise, howsoever arising.</li>
      <li class="ml-5">In no event will the Company be liable for any indirect, special, consequential, punitive, or exemplary damages (including, without limitation, those resulting from loss of revenues, lost profits, loss of goodwill, loss of data, cost of procurement of substitute services, business interruption, or other intangible losses), arising out of or in connection with the Product, whether such damages are based on warranty, contract, tort, statute, or any other legal theory and even if any company party has been advised (or should have known) of the possibility of such damages.</li>
    </ol>

    <h1 style="font-weight:bold;">General</h1>
    <p class="text-justify mb-1">
      These Terms and Conditions constitute the entire and exclusive and final statement of the agreement between you and the Company with respect to the subject matter hereof, 
      superseding any prior agreements or negotiations between you and the Company with respect to such subject matter. The Company shall neither be liable to the other nor shall be in default if, 
      and to the extent that, the performance or delay in performance of any of its obligations under these Terms and Conditions is prevented, restricted, delayed or interfered with due to circumstances beyond the reasonable control of the Company or any force majeure event. 
      The failure of the Company to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision. 
      If any provision of these Terms and Conditions is found by a court of competent jurisdiction to be invalid, you nevertheless agree that the court should endeavour to give effect to the intentions of the Company and you as reflected in the provision, and that the other provisions of these Terms and Conditions remain in full force and effect. 
      These Terms and Conditions, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by the Company without restriction. The Company may, in its sole and absolute discretion, change these Terms and Conditions from time to time.
    </p>

    <h1 style="font-weight:bold;">Contact Us</h1>
    <p class="text-justify">
      Please contact us using the contact information listed <a class="link" href="mailto:info@plugzio.io">info@plugzio.io</a> 
      if you have any queries regarding this Privacy Policy.
    </p>
  `,
  "IN-privacy-policy": `
    <h1 style="margin-top:0!important; font-weight:bold;">Privacy Policy</h1>
    <p class="text-justify mb-1">
      PLUGZIO India. Pvt. Ltd. (“PLUGZIO”) owns and operates all PLUGZIO products, which will be referred to as "we," "our," and "us" in this Privacy Policy. 
      You agree to the Internet Privacy Policy of the website ("the Website"), as well as the privacy policies of all PLUGZIO products, by using PLUGZIO products, which include the website, 
      and PLUGZIO mobile app. We are devoted to protecting our users' privacy at PLUGZIO India. Pvt. Ltd. We value your privacy and want you to be able to use and enjoy our Website without having to give over any of your personal information. 
      The Privacy Policy governs the collection and use of personal data you may provide to us when using our services. This Privacy Policy supersedes any other terms and conditions that may apply to PLUGZIO goods. 
      We do not release any personally identifiable information about you to any third party without your permission unless otherwise stated in our Privacy Policy. This Privacy Policy should be read on a regular basis to keep up with any changes.
    </p>

    <h1 style="font-weight:bold;">Collect Or Receive Personal Data From You</h1>
    <p class="text-justify mb-1">
      PLUGZIO may acquire or receive personal data from you in a variety of ways as a result of your usage of PLUGZIO products. 
      You can choose to provide your personal data and other information to us via the Website's many communication channels, such as form submissions, chat submissions, blog subscriptions, emails, and phone calls. 
      When you access, view, and use the Website, we may use cookies or similar technologies to gather some Website usage information.
    </p>

    <h1 style="font-weight: bold;">The Types of Personal Data/Sensitive Personal Data We May Collect</h1>
    <p class="text-justify mb-1">You may provide us with the following personal information:</p>
    <ul class="text-justify" style="font-size:smaller;">
      <li class="ml-5">Name </li>
      <li class="ml-5">Phone number</li>
      <li class="ml-5">Contact information including email address</li>
      <li class="ml-5">Your date of birth</li>
      <li class="ml-5">Your gender</li>
      <li class="ml-5">Demographic information such as Pincode, preferences and interests</li>
      <li class="ml-5">Financial information such as bank account numbers, credit card numbers, debit card numbers, and other payment instrument numbers</li>
      <li class="ml-5">Any additional items of "sensitive personal data or information," as defined by the Information Technology (Reasonable Security Practices And Procedures And Sensitive Personal Data Of Information) Rules, 2011, which were enacted under the Information Technology Act of 2000.</li>
      <li class="ml-5">Your communication device's identification code, which you use to access PLUGZIO products or otherwise interact with PLUGZIO</li>
      <li class="ml-5">Other information relevant to customer surveys and/or offers</li>
      <li class="ml-5">The phone contact information like name, address, email, phone number, instant messenger information and organization.</li>
    </ul>

    <h1 style="font-weight: bold;">How We May Use The Data We Collect</h1>
    <p class="text-justify mb-1">The information we collect from you may be used for the following purposes:</p>
    <ul style="font-size: smaller;" class="ml-5">
      <li>Processing your requests and inquiries</li>
      <li>Correspond with you</li>
      <li>To support products or services you have obtained from us</li>
      <li>Internal record keeping</li>
      <li>Improvement of our products and services</li>
      <li>To conduct analysis of data</li>
      <li>Sending periodic promotional emails about new goods, special deals, or other information that may be of interest to you for marketing purposes. For the same reason, we may contact you via email or phone.</li>
      <li>For proper administering of PLUGZIO products</li>
      <li>Internal evaluations and data analysis for PLUGZIO goods are required (e.g., to determine the number of visitors to specific pages within the Website)</li>
      <li>To surveying and doing academic research</li>
      <li>To maintain the quality of PLUGZIO products</li>
      <li>To put information security practices in place</li>
      <li>To find out if there are any security flaws, computer contamination, or computer viruses.</li>
      <li>To look into, prevent, or respond to criminal actions including suspected fraud.</li>
      <li>As part of an inquiry or internal audit, do forensics on the affected computer resource.</li>
    </ul>

    <h1 style="font-weight: bold;">Security and Access To Information</h1>
    <p class="text-justify mb-1">
      PLUGZIO will aim to ensure the security, integrity, and privacy of any personal data or sensitive personal data that you may provide to us when using the Website. 
      If you notice that the information we have on file is missing or wrong, please contact us to update it.
    </p>

    <h1 style="font-weight: bold;">Contact Us</h1>
    <p class="text-justify mb-1">
      Please contact us using the contact information listed on <a class="link" href="mailto:info@plugzio.io">info@plugzio.io</a> 
      if you have any queries regarding this Privacy Policy.
    </p>
    <p class="text-justify mb-1">Grievance Officer-</p>
    <p class="text-justify mb-1">Contact Number- <a class="link" href="tel:+91-8360531520">+91-8360531520</a></p>
    <p class="text-justify mb-1">Email- <a class="link" href="mailto:info@plugzio.io">info@plugzio.io</a></p>
  `,
  "IN-return-policy": `
    <h1 style="margin-top:0!important; font-weight: bold;">Return Policy</h1>
    <p class="text-justify mb-1">
      At Plugzio , we strive to give you the finest online services possible. 
      In the odd event that a manufacturing error occurs, you will be offered full support in replacing the item. 
      Send an email to <a class="link" href="mailto:info@plugzio.io">info@plugzio.io</a> if you have any further questions.
    </p>

    <h1 style="font-weight: bold;">Cancellation Policy</h1>
    <p class="text-justify mb-1">
      We handle orders on the same day to bring them to you as quickly as possible. 
      We can cancel and refund your order as long as it hasn't been processed.
    </p>
    <p class="text-justify mb-1">
      To cancel your order, please contact our support team at the email address or phone number listed below:
    </p>
    <ul class="text-justify ml-5" style="font-size: smaller;">
      <li>If you need to cancel an order, please contact <a class="link" href="mailto:info@plugzio.io">info@plugzio.io</a> or phone <a class="link" href="tel:+91-8360531520">+91-8360531520</a> with your order number.</li>
      <li>In the event that payment for an order is not received, PLUGZIO will immediately cancel the order. An email notification is sent to you when we cancel the order.</li>
    </ul>
  `,
  "terms-of-service": `
    <h1 style="font-weight:bold;" class="text-justify">Restrictions and Limitations</h1>
    You must comply with the following requirements when using our services:
    <ol class="ml-5 text-justify" style="font-size: smaller;">
      <li> You may not purchase, use or access our services for the purpose of building a competing product</li>
      <li> You may not reverse engineer the system or its API</li>
      <li> You may not circumvent the limitations placed on the activities
      of the users via the user interface and under normal conditions. For
      example you may not use the backend API with your own front-end
      clients.</li>
      <li>You may not abuse any functionality of the website, that would
      be damaging to our servers, or puts undue load on our system. For
      example you may not abuse the feedback mechanism or generate
      accounts in automated ways</li>
      <li>Except as expressly permitted in the Terms, you agree not to
      reproduce, duplicate, copy, sell, trade, or exploit for any
      commercial purposes, any portion of the Service , use of the
      Service, or access to the Service.</li>
    </ol>

    <h1 style="font-weight: bold;" class="text-justify">Licenses</h1> Subject to your compliance to the terms of service
    you are granted a limited non-transferable, non-sublicensable,
    revocable permission to use the service and access the associated
    dashboards.

    <h1 style="font-weight: bold;">Disclaimer of Waranty</h1>
    <ol class="ml-5 text-justify" style="font-size: smaller;">
      <li>Your use of the service is at your sole risk. the service is
      provided on an "AS IS" and "AS AVAILABLE" basis. Plugzio expressly
      disclaims all warranties, representations and conditions of any kind,
      whether express or implied, including, but not limited to the implied
      warranties, representations and conditions of merchantability, fitness
      for a particular purpose and non-infringement.</li>
      <li>Plugzio makes no warranty that (i) the service will meet your
      requirements, (ii) the service will be uninterrupted, timely, secure,
      or error-free, (iii) the results that may be obtained from the use of
      the service will be accurate or reliable, (iv) the quality of any
      products, services, information, or other material purchased or
      obtained by you through the service will meet your expectations, and
      (v) any errors in the software will be corrected.</li>
      <li>Any material downloaded or otherwise obtained through the use of
      the service is done at your own discretion and risk and that you will
      be solely responsible for any damage to your computer system or loss
      of data that results from the download of any such material.</li>
      <li>No advice or information, whether oral or written, obtained by you
      from Plugzio or through or from the service shall create any warranty
      not expressly stated in the terms.</li>
    </ol>
    <h1 style="font-weight: bold;">Limitation of Liability</h1>
    <p class="text-justify">
      You expressly understand and agree that Plugzio shall not be liable
      for any direct, indirect, incidental, special, consequential, or
      exemplary damages, including but not limited to, damages for loss of
      profits, goodwill, use, data, or other intangible losses (even if
      Plugzio has been advised of the possibility of such damages),
      resulting from: (i) the use or the inability to use the service; (ii)
      the cost of procurement of substitute goods and services resulting
      from any goods, data, information, or services purchased or obtained
      or messages received or transactions entered into through or from the
      service; (iii) unauthorized access to or alteration of your
      transmissions or data; (iv) statements or conduct of any third party
      on the service; or (v) any other matter relating to the service.
    </p>

    <h1 style="font-weight: bold;">Exclusion of Limitations</h1>
    <p class="text-justify"> 
      Some jurisdictions do not allow
      the exclusion of certain warranties, representations and conditions or
      the limitation or exclusion of liability for incidental or
      consequential damages. Accordingly, some of the above limitations may
      not apply to you.
    </p>
    <h1 style="font-weight: bold;">Termination of Service and Suspension of Devices</h1> 
    <p class="text-justify"> 
      You agree that we have the right to terminate your subscription at any time
      with or without any reason or notice. If you have purchased any
      devices from us we might at our own discretion, turn your devices
      into "dumb" devices, after which it will act as a regular device
      with no smart features. Furthermore you have the right to request
      your wallet balance to be sent to you via the normal methods
      available to other users, subject to subtracting any money that you
      owe plugzio, plugzio commissions and other fees that the gateway
      providers charge.
    </p>
    <p class="text-justify">
      You may also request a disconnection of service for all or some of
      your purchased devices, in which case at your request we can transfer
      your wallet balance to you subject to the terms described above and
      suspend your devices. Future re-activations of the devices may be
      subject to a re-connection fee.
    </p>
  `,
  "privacy-policy": `
    <h1 style="margin-top: 0!important; font-weight:bold;">Registration</h1>
    <p style="margin-bottom: 15px;" class="text-justify">
        In order to fully use this website, a user must first complete the registration form. 
        During registration, a user may be required to give certain information (such as name and email address). 
        This information is used to better serve you and also contact you about the products/services on our site in which you have expressed interest. 
        You may be given the option to provide demographic information (such as gender or age or the type of Electric Vehicle you own) about yourself. 
        Disclosing some of that information is optional.
    </p>

    <h1 style="font-weight: bold;">Information Collection, Use, and Sharing</h1>
    <p style="margin-bottom: 15px;" class="text-justify">
        We are the sole owners of the information collected on this site. 
        We only have access to/collect information that you voluntarily give us via email or other direct contact from you. 
        We will not sell or rent this information to anyone. 
    </p>
    <p style="margin-bottom: 15px;" class="text-justify">
        We will use your information to respond to you, regarding the reason you contacted us. 
        We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, 
        e.g. to ship an order.
    </p>
    <p style="margin-bottom: 15px;" class="text-justify">
        Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.
    </p>

    <h1 style="font-weight: bold;">Security</h1>
    <p style="margin-bottom: 15px;" class="text-justify">
        We take precautions to protect your information. 
        When you submit sensitive information via the website, your information is protected both online and offline.
    </p>
    <p style="margin-bottom: 15px;" class="text-justify">
        Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. 
        You can verify this by looking for a lock icon in the address bar and looking for "https" at the beginning of the address of the Web page.
    </p>
    <p style="margin-bottom: 15px;" class="text-xs-justify">
        While we use encryption to protect sensitive information transmitted online, we also protect your information offline. 
        Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. 
        The computers/servers in which we store personally identifiable information are kept in a secure environment.
    </p>

    <h1 style="font-weight: bold;">Orders</h1>
    <p style="margin-bottom: 15px;" class="text-justify">
        We request information from you on our order form. 
        To buy from us, you must provide contact information (like name and shipping address) and financial information (like credit card number, expiration date). 
        This information is used for billing purposes and to fill your orders. If we have trouble processing an order, we'll use this information to contact you.
    </p>

    <h1 style="font-weight: bold;">Cookies and Analytics</h1>
    <p style="margin-bottom: 15px;" class="text-justify">
        We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to help us improve your access to our site and identify repeat visitors to our site. 
        For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. 
        Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.
    </p>
    <p style="margin-bottom: 15px;" class="text-justify">
        Some of our business partners may use cookies on our site (for example, advertisers). However, we have no access to or control over these cookies.
    </p>
    <p style="margin-bottom: 15px;" class="text-justify">
        Our site may also gather analytics information on user behavior to better optimize the experience of the users.
    </p>

    <h1 style="font-weight: bold;">Surveys and Contests</h1>
    <p style="margin-bottom: 15px;" class="text-justify">
        From time-to-time our site requests information via surveys or contests. 
        Participation in these surveys or contests is completely voluntary and you may choose whether or not to participate and therefore disclose this information. 
        Information requested may include contact information
  `,
};
const policyTitles = {
  "terms-of-service": "Terms of Service",
  "privacy-policy": "Privacy Policy",
  "return-policy": "Return Policy",
};

class TextHelpers {
  getPolicyText(policyName = "terms-of-service", countryCode = "US") {
    return policyTexts[`${countryCode === "IN" ? "IN-" : ""}${policyName}`] || "";
  }
  getPolicyTitle(policyName = "terms-of-service") {
    return policyTitles[policyName] || "";
  }
}

export default new TextHelpers();
