<template>
  <v-row v-if="visible" class="policy flex-row">
    <v-row class="policy-links">
      <v-col>
        <span class="link d-flex justify-center" @click="openDialog('terms-of-service')">Term of Service</span>
      </v-col>
      <v-col>
        <span class="link d-flex justify-center" @click="openDialog('privacy-policy')">Privacy Policy</span>
      </v-col>
      <v-col>
        <span class="link d-flex justify-center" @click="openDialog('return-policy')">Return Policy</span>
      </v-col>
    </v-row>
    <v-row class="flex-row">
      <v-col class="policy-copyright flex-column mb-2">
        <span>Copyright Plugzio Power Private Limited, All rights reserved</span>
        <span>Pkt .U&V, Number 132-D, Shalimar Bagh, New Delhi 110088, India</span>
        <span>Contact us at +91 8360531520</span>
      </v-col>
    </v-row>
  </v-row>
</template>

<script lang="ts" setup>
import { TextHelpers } from "@/library/helpers";
import { globalStore } from "@/store-pinia";
import { computed, ref } from "vue-demi";

const props = withDefaults(
  defineProps<{
    layout?: string;
  }>(),
  {
    layout: "column",
  }
);

const selected = ref(null);

const countryCode = computed(() => globalStore.countryCode);
const visible = computed(() => countryCode.value === "IN");

const dialogTitle = computed(() => {
  let text = "";
  switch (selected.value) {
    case "terms-of-service":
      text = "Term of Service";
      break;
    case "privacy-policy":
      text = "Privacy Policy";
      break;
    case "return-policy":
      text = "Return Policy";
      break;
  }
  return text;
});

const openDialog = (type: string) => {
  selected.value = type;
  globalStore.setState({
    globalDialog: {
      show: true,
      title: dialogTitle.value,
      content: `<div class="document">${TextHelpers.getPolicyText(selected.value, countryCode.value)}</div>`,
      onClosed: () => (selected.value = null),
      showCancel: false,
    },
  });
};
</script>
