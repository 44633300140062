import { WalletTransactionInterface } from "@/interfaces";
import Api from "@/library/apis/Api";
import { SortHelpers, WalletHelpers } from "@/library/helpers";
import { cloneDeep } from "lodash-es";
import moment from "moment-timezone";
import { defineStore } from "pinia";
import { adminStore, globalStore, sessionStore } from ".";

const defaultState = () => ({
  topup: {
    mobikwik: {
      active: false,
      menu: null,
      balance: 0,
    },
  },
});

const mapData = (wallet: any, currencies: string[]) => {
  return {
    ...wallet,
    get amount() {
      return this.total_cost === 0 ? "0.00" : parseFloat(this.total_cost).toFixed(2);
    },
    get description() {
      return this.name;
    },
    get currency() {
      return this.currency_code;
    },
  };
};

export default defineStore("Wallet", {
  state: () => ({
    all: [],
    initialized: false,
    loading: false,
    transactions: {} as Record<number, WalletTransactionInterface[]>,
    topup: {
      mobikwik: {
        active: false,
        menu: null,
        balance: 0,
      },
      paypal: {
        walletId: null,
        orderId: "",
        error: false,
        errorCode: "",
        errorMessage: "",
      },
    },
    isFetchingWalletIdsTransactions: [] as number[],
    isDataFetched: false,
    withdrawalProfiles: {
      paypalList: [],
      wireList: [],
      chequeList: [],
      achList: [],
    },
  }),
  getters: {
    mappedData: (state) =>
      state.all
        .map((wallet) => ({
          ...wallet,
          get expense() {
            return (sessionStore.statistics?.expenses || []).find((expense: any) => expense.wallet_id === wallet.id)?.total_cost || 0;
          },
          get revenue() {
            return (sessionStore.statistics?.revenues || []).find((revenue: any) => revenue.owner_wallet_id === wallet.id)?.total_cost || 0;
          },
          transactions:
            (state.transactions[wallet.id] || []).length > 0
              ? state.transactions[wallet.id].map((o) => {
                  o.record_timestamp_text = moment.unix(o.record_timestamp).tz(globalStore.timezone).format("ll");
                  o.class = o.credit ? "text-success" : "text-error";
                  o.money = o.credit ? `+${o.credit}` : `-${o.debit}`;
                  if (!o.credit && !o.debit) {
                    o.money = "0";
                    o.class = "general-text";
                  }
                  o.session_id = WalletHelpers.getSessionIdFromTransaction(o);
                  o.value = parseFloat(o.money as string);
                  return o;
                })
              : [],
        }))
        .sort(SortHelpers.compareValues("description")),
    hiddenIds: () => {
      if (adminStore.managerview) return (adminStore.managerview.preferences || {}).hiddenWallets || [];
      if (adminStore.userview) return (adminStore.userview.preferences || {}).hiddenWallets || [];
      return (globalStore.preferences || {}).hiddenWallets || [];
    },
    visibleData() {
      return this.mappedData.filter((wallet: any) => !this.hiddenIds.includes(wallet.id));
    },
    hiddenData() {
      return this.mappedData.filter((wallet: any) => this.hiddenIds.includes(wallet.id));
    },
    onlyUsdAndCadWallets() {
      return this.mappedData.filter((wallet: any) => !this.hiddenIds.includes(wallet.id) && ["CAD", "USD"].includes(wallet.currency));
    },
  },
  actions: {
    async initialize() {
      await this.fetchData();
      this.initialized = true;
    },
    async fetchData() {
      try {
        this.loading = true;
        const wallets: any = await Api.wallets();
        this.all = (wallets || []).map((wallet: any) => ({ ...wallet, name: `${wallet.description} (${wallet.currency})` }));
      } catch (error) {
      } finally {
        this.loading = false;
        this.isDataFetched = true;
      }
    },
    fetchTransactions(walletId: number) {
      return new Promise(async (resolve, reject) => {
        try {
          this.isFetchingWalletIdsTransactions.push(walletId);
          const data: WalletTransactionInterface[] = (await Api.walletTransactions(walletId)) as any;
          this.transactions[walletId] = (data || []).reverse().sort((a, b) => a.record_timestamp - b.record_timestamp);
          resolve(true);
        } catch (error) {
          reject(error);
        } finally {
          this.isFetchingWalletIdsTransactions.splice(this.isFetchingWalletIdsTransactions.indexOf(walletId), 1);
        }
      });
    },
    setTopupMobikwikState(payload: any) {
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          this.topup.mobikwik[key] = payload[key];
        }
      }
    },
    resetTopupMobikwikState() {
      this.topup.mobikwik = cloneDeep(defaultState().topup.mobikwik);
    },
    async fetchWithdrawalProfiles() {
      await this.fetchData();
      this.loading = true;
      try {
        const profiles = {
          paypalList: [],
          wireList: [],
          chequeList: [],
          achList: [],
        };

        this.all.forEach((item: any) => {
          const parsedParams = typeof item.params === "string" ? JSON.parse(item.params) : item.params;
          const withdrawalProfile = parsedParams?.withdrawalProfile || {};

          if (Array.isArray(withdrawalProfile.paypalList)) {
            profiles.paypalList = [...profiles.paypalList, ...withdrawalProfile.paypalList];
          }
          if (Array.isArray(withdrawalProfile.wireList)) {
            profiles.wireList = [...profiles.wireList, ...withdrawalProfile.wireList];
          }
          if (Array.isArray(withdrawalProfile.chequeList)) {
            profiles.chequeList = [...profiles.chequeList, ...withdrawalProfile.chequeList];
          }
          if (Array.isArray(withdrawalProfile.achList)) {
            profiles.achList = [...profiles.achList, ...withdrawalProfile.achList];
          }
        });

        this.withdrawalProfiles = profiles;
      } catch (error) {
        console.error("Error processing withdrawal profiles:", error);
      } finally {
        this.loading = false;
      }
    },
    async topupPaypal(orderId: string, currency?: string) {
      return new Promise(async (resolve, reject) => {
        this.topup.paypal.orderId = orderId;
        try {
          await Api.walletTopupPaypal(JSON.stringify({ orderId }), currency);
          resolve(true);
        } catch (error) {
          this.topup.paypal.error = true;
          this.topup.paypal.errorCode = error.status instanceof Promise ? await error.status : error.status;
          this.topup.paypal.errorMessage = error.message instanceof Promise ? await error.message : error.message;
          reject(error);
        }
      });
    },
  },
});
