import moment from "moment-timezone";

class DateHelpers {
  constructor() {}

  processDates(payload: any, timezone: string) {
    let till = moment.unix(payload.till).endOf("day");
    let since = moment.unix(payload.since).startOf("day");

    let hours = moment(till).diff(moment(since), "hours");
    let days = moment(till).diff(moment(since), "days");
    let set = [];
    let mode: any = "day";
    let modeFormat = "ddd, MMM D";
    let count = 0;

    if (hours <= 24) {
      mode = "hour";
      modeFormat = "hA";
    } else {
      if (days <= 40) {
        mode = "day";
        modeFormat = "ddd, MMM D";
      } else if (days > 40 && days <= 180) {
        mode = "week";
        modeFormat = "MMM Do";
      } else if (days > 180 && days <= 720) {
        mode = "month";
        modeFormat = "MMM YYYY";
      } else {
        mode = "year";
        modeFormat = "YYYY";
      }
    }

    till = moment(till.endOf(mode).toDate());

    while (true) {
      if (mode === "hour") {
        if (count > 23) {
          break;
        }
      } else {
        if (since > till) {
          break;
        }
      }

      let sinceSet: any;
      let tillSet: any;
      let timeSet: any;

      sinceSet = moment(since.startOf(mode).toDate().toISOString()).unix();
      tillSet = moment(since.endOf(mode).toDate().toISOString()).unix();
      if (mode === "week") {
        timeSet = "Week of " + moment(since.startOf(mode).add(1, "day").toDate().toISOString()).format(modeFormat);
      } else {
        timeSet = moment(since.startOf(mode).toDate().toISOString()).format(modeFormat) + "";
      }
      since = since.add(1, mode);
      set.push({
        sinceSet,
        tillSet,
        timeSet,
      });
      count++;
    }
    return set;
  }
  changeUnixToYMDFormat(unix: any) {
    return moment.unix(unix).format("YYYY-MM-DD");
  }

  // processLogDates: (since, till) => {
  //   let hours = moment(till).diff(moment(since), "hours");
  //   let set = [];
  // },
  // setSinceDate: (date) => {
  //   return moment(date * 1000)
  //     .startOf("day")
  //     .unix();
  // },
  // setTillDate: (date) => {
  //   return moment(date * 1000)
  //     .endOf("day")
  //     .unix();
  // },
}

export default new DateHelpers();
