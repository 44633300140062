import { formatCost, formatRate, formatTwoDigitTime, secondToHourMinuteText, timeBasedGetText, whToKwh } from "@/library/helpers";
import { capitalize } from "lodash-es";
// import Vue from "vue"
import moment from "moment-timezone";

const CONSUMPTION_STEP = 1000; //1 kwh step
const CONSUMPTION_MAX = 100000; // 100 kwh max

export function getPaymentPlanText({ type, values }) {
  const texts = {
    "consumption-less": ({ maxConsumption }) => `For the first ${!isNaN(parseFloat(maxConsumption)) ? whToKwh(maxConsumption) : "..."} kWh of electricity consumed`,
    "consumption-between": ({ minConsumption, maxConsumption }) =>
      `Between ${!isNaN(parseFloat(minConsumption)) ? whToKwh(minConsumption) : "..."} kWh and ${
        !isNaN(parseFloat(maxConsumption)) ? whToKwh(maxConsumption) : "..." || "..."
      } kWh of electricity consumed`,
    "consumption-more": ({ minConsumption }) => `For more than ${!isNaN(parseFloat(minConsumption)) ? whToKwh(minConsumption) : "..."} kWh of electricity consumed`,
    "duration-less": ({ maxDuration }) => `For the first ${secondToHourMinuteText(maxDuration) || "..."} of the session`,
    "duration-between": ({ minDuration, maxDuration }) =>
      `Between session duration of ${secondToHourMinuteText(minDuration) || "..."} and ${secondToHourMinuteText(maxDuration) || "..."}`,
    "duration-more": ({ minDuration }) => `For session duration longer than ${secondToHourMinuteText(minDuration) || "..."}`,
    date: ({ minDay, minMonth, maxDay, maxMonth, maxHour = 0, maxMinute = 0 }) => {
      const from = (minDay || minMonth) && timeBasedGetText({ values: { day: minDay, month: minMonth, hour: 0, minute: 0 }, type: "date" });
      const to = (maxDay || maxMonth) && timeBasedGetText({ values: { day: maxDay, month: maxMonth, hour: maxHour, minute: maxMinute }, type: "date" });
      return `Every year for <b class="text-plugzio">calendar days</b> between <b class="text-plugzio">${from || "..."}</b> and <b class="text-plugzio">${to || "..."}</b>`;
    },
    day: ({ minWeekday, maxWeekday }) => {
      const from = !isNaN(parseInt(minWeekday)) && timeBasedGetText({ values: { weekday: minWeekday }, type: "day" });
      const to = !isNaN(parseInt(maxWeekday)) && timeBasedGetText({ values: { weekday: maxWeekday }, type: "day" });
      return `Every  ${
        minWeekday != maxWeekday || isNaN(parseInt(minWeekday)) || isNaN(parseInt(maxWeekday)) ? "week from " + (from || "...") + " to " + (to || "...") : from + " of the week"
      }`;
    },
    time: ({ maxHour, minHour, maxMinute, minMinute, maxAmPm, minAmPm }) => {
      const from =
        (!isNaN(parseInt(minHour)) || !isNaN(parseInt(minMinute)) || !!minAmPm) && timeBasedGetText({ values: { hour: minHour, minute: minMinute, amPm: minAmPm }, type: "time" });
      const to =
        (!isNaN(parseInt(maxHour)) || !isNaN(parseInt(maxMinute)) || !!maxAmPm) && timeBasedGetText({ values: { hour: maxHour, minute: maxMinute, amPm: maxAmPm }, type: "time" });
      return `Every day for <b class="text-plugzio">time of day</b> between <b class="text-plugzio">${from || "..."}</b> and <b class="text-plugzio">${to || "..."}</b>`;
    },
  };
  return texts[type]({ ...values });
}

export function isPaymentPlanValueSame({ type, values }) {
  const isSame = {
    "consumption-less": ({}) => false,
    "consumption-between": ({ minConsumption, maxConsumption }) =>
      !isNaN(parseFloat(minConsumption)) &&
      !isNaN(parseFloat(maxConsumption)) &&
      (!isNaN(parseFloat(minConsumption)) ? parseFloat(minConsumption) : "...") === (!isNaN(parseFloat(maxConsumption)) ? parseFloat(maxConsumption) : "..."),
    "consumption-more": ({}) => false,
    "duration-less": ({}) => false,
    "duration-between": ({ minDuration, maxDuration }) =>
      secondToHourMinuteText(minDuration) &&
      secondToHourMinuteText(maxDuration) &&
      (secondToHourMinuteText(minDuration) || "...") === (secondToHourMinuteText(maxDuration) || "..."),
    "duration-more": ({}) => false,
    date: ({ minDay, minMonth, maxDay, maxMonth, maxHour = 0, maxMinute = 0 }) =>
      ((minDay || minMonth) && timeBasedGetText({ values: { day: minDay, month: minMonth, hour: 0, minute: 0 }, type: "date" })) ===
      ((maxDay || maxMonth) && timeBasedGetText({ values: { day: maxDay, month: maxMonth, hour: maxHour, minute: maxMinute }, type: "date" })),
    day: ({ minWeekday, maxWeekday }) => {
      return (
        (minWeekday && timeBasedGetText({ values: { weekday: minWeekday }, type: "day" })) === maxWeekday && timeBasedGetText({ values: { weekday: maxWeekday }, type: "day" })
      );
    },
    time: ({ maxHour, minHour, maxMinute, minMinute, maxAmPm, minAmPm }) =>
      ((!isNaN(parseInt(minHour)) || !isNaN(parseInt(minMinute)) || !!minAmPm) &&
        timeBasedGetText({ values: { hour: minHour, minute: minMinute, amPm: minAmPm }, type: "time" })) ===
        (!isNaN(parseInt(maxHour)) || !isNaN(parseInt(maxMinute)) || !!maxAmPm) && timeBasedGetText({ values: { hour: maxHour, minute: maxMinute, amPm: maxAmPm }, type: "time" }),
  };
  return isSame[type]({ ...values });
}

export function calculatePricing({ paymentPlan, start = null, end = null, currency }: any) {
  const now = start ? start : moment();
  let current = moment(now.format("YYYY-MM-DD HH:mm:ss"));
  let durationInSeconds = 0;
  let max = end ? end : moment().add(24, "hours");
  let minText = null;
  let maxText = null;
  let startTimestamp = null;
  let endTimestamp = null;
  let pricings = [];
  let lastText = null;
  let text = null;
  let lastPlans = null;
  while (current < max) {
    const { text: currentText, plans } = findText({ paymentPlan, momenttime: current, duration: durationInSeconds, currency, showActivationFee: pricings.length === 0 });
    text = currentText;
    if (!lastText) lastText = text;
    if (!lastPlans) lastPlans = plans;
    if (!minText) minText = current.format("YYYY-MM-DD HH:mm:ss") == moment().format("YYYY-MM-DD HH:mm:ss") ? "Now" : current.format("hh:mm A");
    if (!startTimestamp) startTimestamp = moment(current.format("YYYY-MM-DD HH:mm:ss"));
    if (lastText === text || maxText === null) maxText = current.format("hh:mm A");
    if (lastText === text || endTimestamp === null) endTimestamp = moment(current.format("YYYY-MM-DD HH:mm:ss"));
    if (lastText !== text) {
      maxText = current.format("hh:mm A");
      startTimestamp = moment(endTimestamp.format("YYYY-MM-DD HH:mm:ss"));
      endTimestamp = moment(current.format("YYYY-MM-DD HH:mm:ss"));
      pricings.push({ text: `${minText} - ${maxText}`, time: { start: startTimestamp, end: endTimestamp }, planText: lastText, plans: lastPlans });
      minText = maxText;
      const { text: currentText, plans } = findText({ paymentPlan, momenttime: current, duration: durationInSeconds, currency, showActivationFee: pricings.length === 0 });
      lastText = currentText;
      lastPlans = plans;
    }
    const next = 15 - (current.minute() % 15);
    current = current.add(next, "minutes");
    durationInSeconds += next * 60;
    if (current > max && lastText != null) {
      maxText = max.format("hh:mm A");
      endTimestamp = moment(max.format("YYYY-MM-DD HH:mm:ss"));
      pricings.push({ text: `${minText} - ${maxText}`, time: { start: startTimestamp, end: endTimestamp }, planText: lastText, plans: lastPlans });
    }
  }
  return pricings.length == 0 ? [{ text: `Now - ${max.format("hh:mm A")}`, time: { start: current, end: max }, planText: lastText, plans: lastPlans }] : pricings;
}

function timeMatch({ condition, current, duration = 0, timezone, consumption }) {
  const currentYear = current.year();
  const currentWeekday = current.day();
  const type = getConditionalType(condition, false);
  if (type === "date") {
    const minDate = moment(`${currentYear}-${condition.minMonth.toString().padStart(2, "0")}-${condition.minDay.toString().padStart(2, "0")} 00:00:00`).tz(timezone);
    const maxDate = moment(`${currentYear}-${condition.maxMonth.toString().padStart(2, "0")}-${condition.maxDay.toString().padStart(2, "0")} 23:59:59`).tz(timezone);
    if (minDate > maxDate) return !(maxDate < current && current <= minDate);
    else return minDate < current && current <= maxDate;
  } else if (type === "day") {
    const maxWeekday = condition.maxWeekday;
    const minWeekday = condition.minWeekday;
    const currentTime = current.format("HH:mm:ss");
    if (minWeekday == maxWeekday) return true;
    if (currentTime == "00:00:00" && maxWeekday == currentWeekday) return true;
    if (minWeekday < maxWeekday) return minWeekday <= currentWeekday && currentWeekday <= maxWeekday;
    if (minWeekday > maxWeekday) return minWeekday <= currentWeekday || currentWeekday < maxWeekday;
  } else if (type == "time") {
    const minTime = formatTwoDigitTime(condition.minHour) + ":" + formatTwoDigitTime(condition.minMinute) + ":00";
    const maxTime = formatTwoDigitTime(condition.maxHour) + ":" + formatTwoDigitTime(condition.maxMinute) + ":00";

    const currentTime = current.format("HH:mm:ss");
    if (minTime > maxTime) return !(maxTime < currentTime && currentTime <= minTime);
    else return minTime < currentTime && currentTime <= maxTime;
  } else if (type === "duration" && duration >= 0) {
    const { minDuration, maxDuration } = condition;
    if (minDuration & maxDuration) return minDuration <= duration && duration < maxDuration;
    if (minDuration) return minDuration <= duration;
    if (maxDuration) return duration < maxDuration;
  } else if (type === "consumption" && consumption >= 0) {
    const { maxConsumption, minConsumption } = condition;
    if (minConsumption && maxConsumption) return minConsumption <= consumption && consumption < maxConsumption;
    if (minConsumption) return minConsumption <= consumption;
    if (maxConsumption) return consumption < maxConsumption;
  }
  return false;
}

export function findPlan({ plan = null, momenttime = null, duration = 0, consumption, tz = "UTC" }) {
  let current = momenttime ? moment(momenttime.format("YYYY-MM-DD HH:mm:ss")) : moment();
  const timezone = plan.timezone || tz;
  current = current.tz(timezone);

  if (plan.unless) {
    for (let i = 0; i < plan.unless.length; i++) {
      const condition = plan.unless[i].condition;
      if (timeMatch({ condition, current, duration, timezone, consumption })) {
        return findPlan({ plan: plan.unless[i].plan, momenttime, duration, consumption, tz: timezone });
      }
    }
  }
  return plan;
}

export function findText({ paymentPlan = null, momenttime = null, duration = 0, currency, showActivationFee = false }) {
  let current = momenttime ? moment(momenttime.format("YYYY-MM-DD HH:mm:ss")) : moment();
  const timezone = paymentPlan.timezone || "UTC";
  current = current.tz(timezone);

  let currentConsumption = 0;
  let lastConsumption = 0;
  let validPlans = [];
  let lastPlanText = null;
  let lastPlanFullText = null;
  let lastPlan = null;
  while (currentConsumption < CONSUMPTION_MAX) {
    const plan = findPlan({ plan: paymentPlan, momenttime, duration, consumption: currentConsumption, tz: timezone });
    const { full: fullText, withoutActivationFeeText } = planToText(plan, currency);
    if (!lastPlanFullText) lastPlanFullText = fullText;
    if (!lastPlanText) lastPlanText = withoutActivationFeeText;
    if (!lastPlan) lastPlan = plan;
    if (lastPlanText !== withoutActivationFeeText) {
      validPlans.push({
        consumptionText: `<strong>${lastConsumption / 1000} - ${currentConsumption / 1000} kWh</strong>`,
        planText: showActivationFee ? lastPlanFullText : lastPlanText,
        plan: lastPlan,
      });
      showActivationFee = false;
      lastConsumption = currentConsumption;
      lastPlanFullText = fullText;
      lastPlanText = withoutActivationFeeText;
    }
    currentConsumption += CONSUMPTION_STEP;
    if (currentConsumption >= CONSUMPTION_MAX) {
      validPlans.push({
        consumptionText: `<strong>${lastConsumption / 1000}+ kWh</strong>`,
        planText: showActivationFee ? lastPlanFullText : lastPlanText,
        plan,
      });
    }
  }
  if (validPlans.length === 1)
    return {
      text: validPlans[0].planText,
      plans: [validPlans[0].plan],
    };

  return {
    text: validPlans.map((t) => `${t.consumptionText}:<br><div style="padding-left: 12px;">${t.planText}</div>`).join(""),
    plans: validPlans.map((p) => p.plan),
  };

  function planToText(plan, currency) {
    const disabledText = `<span class="error--text">Device is disabled during this time period. Sessions will automatically terminate. Please contact management for more information.</span>`;
    if (plan.disabled)
      return {
        full: disabledText,
        activationFeeText: disabledText,
        withoutActivationFeeText: disabledText,
      };
    const activationFeeText = `Activation Fee (${currency}): <strong>${formatCost(plan.startSessionCost || 0)}</strong><br>`;
    const withoutActivationFeeText = `Duration Rate (${currency}): <strong>${formatRate(plan.hRate || 0, "h")}</strong><br>Consumption Rate (${currency}): <strong>${formatRate(
      ((plan.whRate || 0) * 1000000000) / 1000000,
      "kWh"
    )}</strong>`;
    return {
      full: `${activationFeeText}${withoutActivationFeeText}`,
      activationFeeText,
      withoutActivationFeeText,
    };
  }
}

export function isPricingFree(pricings = [], only_activation = false) {
  let isFree = true;
  for (let i = 0; i < pricings.length; i++) {
    const price = pricings[i];
    if (!price.plans) continue;
    for (let j = 0; j < price.plans.length; j++) {
      let { startSessionCost, hRate, whRate } = price.plans[j];
      startSessionCost = startSessionCost || 0;
      hRate = hRate || 0;
      whRate = whRate || 0;
      if (only_activation) {
        if (startSessionCost > 0 || hRate > 0 || whRate > 0) isFree = false;
        break;
      }
      if (startSessionCost > 0 || hRate > 0 || whRate > 0) {
        isFree = false;
        break;
      }
    }
    if (!isFree || only_activation) break;
  }
  return isFree;
}

export function getConditionalType(condition, detailed = true) {
  const conditions = {
    date: ["minMonth", "maxMonth", "minDay", "maxDay"],
    day: ["minWeekday", "maxWeekday"],
    time: ["minHour", "maxHour", "minMinute", "maxMinute"],
    duration: ["minDuration", "maxDuration"],
    consumption: ["minConsumption", "maxConsumption"],
  };
  let selectedKey = null;
  for (const type in conditions) {
    const properties = conditions[type];
    for (const k in condition) {
      if (properties.includes(k)) {
        selectedKey = type;
        break;
      }
    }
    if (selectedKey) break;
  }
  if (detailed && ["duration", "consumption"].includes(selectedKey)) {
    if (!condition[`min${capitalize(selectedKey)}`]) selectedKey = `${selectedKey}-less`;
    else if (!condition[`max${capitalize(selectedKey)}`]) selectedKey = `${selectedKey}-more`;
    else selectedKey = `${selectedKey}-between`;
  }
  return selectedKey;
}
