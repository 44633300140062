import { authStore } from "@/store-pinia";

const routerView = {
  template: "<router-view />",
};

export const PageTypes = {
  AUTHENTICATED: "authenticated",
  NOT_AUTHENTICATED: "not-authenticated",
};

const handleRedirect = (page: string = PageTypes.AUTHENTICATED) => {
  const role = location.href.includes("/owner/") ? "owner" : location.href.includes("/admin/") ? "admin" : "user";
  if (page === PageTypes.AUTHENTICATED && !authStore.isAuthenticated) return { name: role !== "user" ? role + "-login" : "login" };
  if (page === PageTypes.NOT_AUTHENTICATED && authStore.isAuthenticated) return { name: PageTypes.AUTHENTICATED };

  const defaultRedirect = authStore.isAuthenticated ? "/dashboard" : "/login";

  const redirect = location.pathname.replace("/demo-contractor", "") + location.search || "/";
  const prefix = role !== "user" ? "/" + role : "";
  return prefix + (redirect === "/" ? defaultRedirect : redirect);
};

export default [
  {
    path: "/",
    name: PageTypes.AUTHENTICATED,
    component: routerView,
    redirect: handleRedirect(PageTypes.AUTHENTICATED),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Landing.vue"),
      },
      {
        path: "/admin",
        name: "admin-dashboard",
        component: () => import("@/views/Landing.vue"),
      },
      {
        path: "/owner",
        name: "owner-dashboard",
        component: () => import("@/views/Landing.vue"),
      },
      {
        path: "/owner/account-selection",
        name: "owner-account-selection",
        component: () => import("@/views/owner/AccountSelection.vue"),
      },
      {
        path: "/activate-device/:plug_id",
        name: "activate-device",
        component: () => import("@/views/ActivateDevice.vue"),
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/Settings.vue"),
      },
      {
        path: "/owner/settings",
        name: "owner-settings",
        component: () => import("@/views/Settings.vue"),
      },
      {
        path: "/admin/managerview",
        name: "admin-managerview",
        component: () => import("@/views/admin/ManagerView.vue"),
      },
      {
        path: "/admin/managerview-configuration",
        name: "admin-managerview-configuration",
        component: () => import("@/views/Settings.vue"),
      },
      {
        path: "/admin/userview-configuration",
        name: "admin-userview-configuration",
        component: () => import("@/views/Settings.vue"),
      },
      {
        path: "/sessions",
        name: "sessions",
        component: () => import("@/views/Sessions.vue"),
      },

      {
        path: "/wallets",
        name: "wallets",
        component: () => import("@/views/Wallets.vue"),
      },
      {
        path: "/owner/wallets",
        name: "owner-wallets",
        component: () => import("@/views/Wallets.vue"),
      },
      {
        path: "/admin/managerview-wallets",
        name: "admin-managerview-wallets",
        component: () => import("@/views/Wallets.vue"),
      },
      {
        path: "/admin/userview-wallets",
        name: "admin-userview-wallets",
        component: () => import("@/views/Wallets.vue"),
      },

      {
        path: "/outlet-management",
        name: "outlet-management",
        component: () => import("@/views/owner/OutletManagement.vue"),
      },
      {
        path: "/owner/outlet-management",
        name: "owner-outlet-management",
        component: () => import("@/views/owner/OutletManagement.vue"),
      },
      {
        path: "/admin/managerview-outlet-management",
        name: "admin-managerview-outlet-management",
        component: () => import("@/views/owner/OutletManagement.vue"),
      },
      {
        path: "/owner/device-network-management",
        name: "owner-device-network-management",
        component: () => import("@/views/owner/DeviceNetworkManagement/DeviceNetworkManagement.vue"),
      },
      {
        path: "/admin/managerview-device-network-management",
        name: "admin-managerview-device-network-management",
        component: () => import("@/views/owner/DeviceNetworkManagement/DeviceNetworkManagement.vue"),
      },
      {
        path: "/access-plans",
        name: "access-plans",
        component: () => import("@/views/owner/AccessPlans.vue"),
      },
      {
        path: "/owner/access-plans",
        name: "owner-access-plans",
        component: () => import("@/views/owner/AccessPlans.vue"),
      },
      {
        path: "/admin/managerview-paymentplans",
        name: "admin-managerview-paymentplans",
        component: () => import("@/views/owner/AccessPlans.vue"),
      },

      {
        path: "/payment-plan-form",
        name: "payment-plan-form",
        component: () => import("@/views/owner/PaymentPlanForm/PaymentPlanForm.vue"),
      },
      {
        path: "/payment-plan-form/:id",
        name: "payment-plan-form-edit",
        component: () => import("@/views/owner/PaymentPlanForm/PaymentPlanForm.vue"),
      },
      {
        path: "/owner/payment-plan-form",
        name: "owner-payment-plan-form",
        component: () => import("@/views/owner/PaymentPlanForm/PaymentPlanForm.vue"),
      },
      {
        path: "/owner/payment-plan-form/:id",
        name: "owner-payment-plan-form-edit",
        component: () => import("@/views/owner/PaymentPlanForm/PaymentPlanForm.vue"),
      },
      {
        path: "/admin/managerview-payment-plan-form",
        name: "admin-managerview-payment-plan-form",
        component: () => import("@/views/owner/PaymentPlanForm/PaymentPlanForm.vue"),
      },
      {
        path: "/admin/managerview-payment-plan-form/:id",
        name: "admin-managerview-payment-plan-form-edit",
        component: () => import("@/views/owner/PaymentPlanForm/PaymentPlanForm.vue"),
      },

      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/Settings.vue"),
      },
      {
        path: "/owner/settings",
        name: "owner-settings",
        component: () => import("@/views/Settings.vue"),
      },
      {
        path: "/admin/settings",
        name: "admin-settings",
        component: () => import("@/views/Settings.vue"),
      },
      // {
      //   path: "/advance-settings",
      //   name: "advance-settings",
      //   component: AdvanceSettings,
      // },
      // {
      //   path: "/owner/advance-settings",
      //   name: "owner-advance-settings",
      //   component: AdvanceSettings,
      // },
      {
        path: "/admin/userview",
        name: "admin-userview",
        component: () => import("@/views/admin/UserView.vue"),
      },
      {
        path: "/admin/userview-dashboard",
        name: "admin-userview-dashboard",
        component: () => import("@/views/Landing.vue"),
      },
      {
        path: "/admin/userview-sessions",
        name: "admin-userview-sessions",
        component: () => import("@/views/Sessions.vue"),
      },

      {
        path: "/admin",
        name: "admin-dashboard",
        component: () => import("@/views/Landing.vue"),
      },
      {
        path: "/admin/wifisetup",
        name: "admin-wifisetup",
        component: () => import("@/views/admin/wifisetup/WifiSetup.vue"),
      },

      {
        path: "/admin/deviceview",
        name: "admin-deviceview",
        component: () => import("@/views/admin/deviceview/DeviceView.vue"),
      },
      {
        path: "/admin/deviceview/info-and-logs",
        name: "admin-deviceview-info-and-logs",
        component: () => import("@/views/admin/deviceview/InfoAndLogs.vue"),
      },
      {
        path: "/admin/deviceview/ocpp-config",
        name: "admin-deviceview-ocpp-config",
        component: () => import("@/views/admin/deviceview/OcppConfig.vue"),
      },
      {
        path: "/admin/deviceview/heartbeat-analytics",
        name: "admin-deviceview-heartbeat-analytics",
        component: () => import("@/views/admin/deviceview/HeartbeatAnalytics.vue"),
      },
      {
        path: "/admin/deviceview-dashboard",
        name: "admin-deviceview-dashboard",
        component: () => import("@/views/Landing.vue"),
      },
      {
        path: "/admin/managerview-dashboard",
        name: "admin-managerview-dashboard",
        component: () => import("@/views/Landing.vue"),
      },
      {
        path: "/owner/load-balancing",
        name: "owner-load-balancing",
        component: () => import("@/views/owner/LoadBalancing/LoadBalancing.vue"),
      },
      {
        path: "/owner/load-balancing-form",
        name: "owner-load-balancing-form",
        component: () => import("@/views/owner/LoadBalancing/LoadBalancingForm.vue"),
      },
      {
        path: "/owner/load-balancing-form/:id",
        name: "owner-load-balancing-form-edit",

        component: () => import("@/views/owner/LoadBalancing/LoadBalancingForm.vue"),
      },
      {
        path: "/admin/managerview-load-balancing",
        name: "admin-managerview-load-balancing",
        component: () => import("@/views/owner/LoadBalancing/LoadBalancing.vue"),
      },
      {
        path: "/admin/managerview-load-balancing-form",
        name: "admin-managerview-load-balancing-form",
        component: () => import("@/views/owner/LoadBalancing/LoadBalancingForm.vue"),
      },
      {
        path: "/admin/managerview-load-balancing-form/:id",
        name: "admin-managerview-load-balancing-form-edit",
        component: () => import("@/views/owner/LoadBalancing/LoadBalancingForm.vue"),
      },
      {
        path: "/owner/get-started",
        name: "owner-get-started",
        component: () => import("@/views/owner/GetStarted/GetStarted.vue"),
      },
      {
        path: "/service-subscription",
        name: "service-subscription",
        component: () => import("@/views/owner/ServiceSubscription/ServiceSubscription.vue"),
      },
      {
        path: "/owner/service-subscription",
        name: "owner-service-subscription",
        component: () => import("@/views/owner/ServiceSubscription/ServiceSubscription.vue"),
      },
      {
        path: "/admin/managerview-subscription",
        name: "admin-managerview-subscription",
        component: () => import("@/views/owner/ServiceSubscription/ServiceSubscription.vue"),
      },
      {
        path: "/owner/configure-subscription",
        name: "owner-configure-subscription",
        component: () => import("@/views/owner/OwnerConfigureSubscription.vue"),
      },
      {
        path: "/admin/configure-device-subscriptions-time",
        name: "admin-configure-device-subscriptions-time",
        component: () => import("@/views/admin/ConfigureDeviceSubscriptionTime.vue"),
      },
      {
        path: "/admin/configure-device-subscriptions-plan",
        name: "admin-configure-device-subscriptions-plan",
        component: () => import("@/views/admin/ConfigureDeviceSubscriptionPlan.vue"),
      },
    ],
  },
  {
    path: "/",
    name: PageTypes.NOT_AUTHENTICATED,
    component: routerView,
    redirect: handleRedirect(PageTypes.NOT_AUTHENTICATED),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/owner/login",
        name: "owner-login",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/admin/login",
        name: "admin-login",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/login/:status",
        name: "registered",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/owner/login/:status",
        name: "owner-registered",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/register",
        name: "register",
        component: () => import("@/views/Register.vue"),
      },
      {
        path: "/owner/register",
        name: "owner-register",
        component: () => import("@/views/Register.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/ForgotPassword.vue"),
      },
      {
        path: "/owner/forgot-password",
        name: "owner-forgot-password",
        component: () => import("@/views/ForgotPassword.vue"),
      },
      {
        path: "/forgot-password/:status",
        name: "new-password",
        component: () => import("@/views/ForgotPassword.vue"),
      },
      {
        path: "/owner/forgot-password/:status",
        name: "owner-new-password",
        component: () => import("@/views/ForgotPassword.vue"),
      },
    ],
  },
  // {
  //   path: "/getting-started",
  //   name: "getting-started",
  //   component: GettingStarted,
  // },
  // {
  //   path: "/device-network-management",
  //   name: "device-network-management",
  //   component: DeviceNetworkManagement,
  // },
  // {
  //   path: "/terms-of-service",
  //   name: "terms-of-service",
  //   component: Terms,
  // },
  // {
  //   path: "/privacy-policy",
  //   name: "privacy-policy",
  //   component: Privacy,
  // },
  // {
  //   path: "/setup",
  //   name: "setup",
  //   component: Setup,
  // },
  // {
  //   path: "/ble-configuration",
  //   name: "ble-configuration",
  //   component: BleConfiguration,
  // },
  // {
  //   path: "/verify-mobikwik",
  //   name: "verify-mobikwik",
  //   component: VerifyMobikwik,
  // },

  // // alias routes
  // {
  //   path: "/owner/verify-mobikwik",
  //   name: "owner-verify-mobikwik",
  //   component: VerifyMobikwik,
  // },
  // {
  //   path: "/owner/getting-started",
  //   name: "owner-getting-started",
  //   component: GettingStarted,
  // },
  // {
  //   path: "/owner/device-network-management",
  //   name: "owner-device-network-management",
  //   component: DeviceNetworkManagement,
  // },
  // {
  //   path: "/owner/terms-of-service",
  //   name: "owner-terms-of-service",
  //   component: Terms,
  // },
  // {
  //   path: "/owner/privacy-policy",
  //   name: "owner-privacy-policy",
  //   component: Privacy,
  // },
  // {
  //   path: "/owner/setup",
  //   name: "owner-setup",
  //   component: Setup,
  // },
  // {
  //   path: "/owner/ble-configuration",
  //   name: "owner-ble-configuration",
  //   component: BleConfiguration,
  // },
  // {
  //   path: "/owner/account-selection",
  //   name: "owner-account-selection",
  //   component: AccountSelection,
  // },
  // {
  //   path: "/test",
  //   name: "test",
  //   component: Test,
  // },
  // {
  //   path: "/owner/test",
  //   name: "owner-test",
  //   component: Test,
  // },

  {
    path: "/:pathMatch(.*)",
    component: () => import("@/views/NotFound.vue"),
  },
] as any;
