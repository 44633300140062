import { AUTHENTICATED_ROUTES } from "@/constants";
import router from "@/router";
import { authStore } from "@/store-pinia";

class RouteHelpers {
  isAdminUrl() {
    return !!window.location.href.match(/\/admin/);
  }
  isLocal() {
    return !window.location.href.match(/my./) && !window.location.href.match(/manage\./) && !window.location.href.match(/api\./);
  }
  isProduction() {
    return (
      !window.location.href.match(/localhost/) &&
      !window.location.href.match(/demo-contractor/) &&
      !window.location.href.match(/\/dev/) &&
      !window.location.href.match(/api-staging\./)
    );
  }
  isOwnerUrl() {
    return window.location.href.match(/\/owner/) || window.location.href.match(/manage./);
  }
  isAuthenticatedPage() {
    return AUTHENTICATED_ROUTES.includes(router.currentRoute.value.name as string);
  }
  redirectToCorrectUrl() {
    if (!this.isLocal()) {
      let url = window.location.href;
      if (authStore.role == "owner") {
        url = url.replace("my.", "manage.");
      } else if (authStore.role == "user") {
        url = url.replace("manage.", "my.");
      }
      (window as any).location = url;
    }
  }
}

export default new RouteHelpers();
