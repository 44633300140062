import { CancelableRequestInterface, PreferenceSetInterface, WalletCreateInterface, WalletSetDescriptionInterface, WalletSetParamsInterface } from "@/interfaces";
import { ApiHelpers, xmlHelpers } from "@/library/helpers";
import { adminStore, authStore, filterStore, globalStore, ownerProxyStore, requestStore } from "@/store-pinia";
import { Capacitor, CapacitorHttp } from "@capacitor/core";
import axios from "axios";
import { isArray } from "lodash-es";
import { v4 as uuidv } from "uuid";
class Api {
  API_URL = import.meta.env.VITE_APP_SERVER !== "local" ? import.meta.env.VITE_APP_API_URL : "/api";
  LOG_API_URL = "https://logs.plugz.io";
  constructor() {}

  // This function will execute the api
  runApi(method = "POST", url = "", payload = {}, headers = {}, httpRequestProvider = null, resultModifier = {}) {
    return new Promise((resolve, reject) => {
      if (!navigator.onLine) return reject("Network Error");

      const config: any = {
        url,
        method,
        headers,
      };
      if (method == "POST") config.data = payload;
      else if (method == "GET") config.params = payload;

      if (!headers.hasOwnProperty("Accept")) config.headers["Accept"] = "application/json, text/plain, */*";
      if (!headers.hasOwnProperty("Content-Type")) config.headers["Content-Type"] = "application/json";
      else if (headers["Content-Type"] === "application/x-www-form-urlencoded" && Capacitor.isNativePlatform()) {
        config.data = new URLSearchParams(config.data).toString();
      }

      const token = localStorage.getItem("key");
      if (!!token) config.headers["X-jwt"] = token;

      let request;
      // This is for canceling the axios request when needed
      const abortController = new AbortController();
      const cancelableRequest: CancelableRequestInterface = {
        id: uuidv(),
        url,
        abortController,
      };
      requestStore.runningRequests.push(cancelableRequest);

      if (httpRequestProvider == "axios") request = axios.request({ ...config, signal: abortController.signal });
      else if (httpRequestProvider == "capacitor-http") request = CapacitorHttp.request(config);
      else request = Capacitor.isNativePlatform() ? CapacitorHttp.request(config) : axios.request({ ...config, signal: abortController.signal });

      const id = uuidv();
      globalStore.setState({ apiCalls: [...globalStore.apiCalls, { url, id }] });
      request
        .then((res) => {
          if (res.status == 200) {
            const returns = {
              status: 200,
              data: null,
            };
            try {
              returns.data = JSON.parse(res.data);
            } catch (e) {
              returns.data = res.data;
            }
            if (Object.keys(resultModifier).length) {
              Object.keys(resultModifier).forEach((key) => {
                if (isArray(returns.data)) returns.data = returns.data.map((item) => ({ ...item, [key]: resultModifier[key](item) }));
                else if (typeof returns.data === "object") returns.data[key] = resultModifier[key](returns.data);
              });
            }
            resolve(returns.data);
          } else {
            const error_message = this.getErrorMessage(res.data, res.status);
            let error = {
              ...res,
              response: {
                data: error_message,
                status: res.status,
              },
            };
            reject(error);
          }
        })
        .catch(async (error) => {
          if (!error.response || ![400, 401, 500].includes(error.response.status)) {
            return reject(error);
          }
          const msg = this.getErrorMessage(error.response.data, error.response.status);
          error.response.data = msg instanceof Promise ? await msg : msg;
          reject(error);
        })
        .finally(() => {
          requestStore.remove(cancelableRequest.id);
          globalStore.setState({ apiCalls: globalStore.apiCalls.filter((call) => call.id !== id) });
        });
    });
  }
  async getErrorMessage(message, status = 400) {
    let error_message = message;
    if (status === 400) {
      switch (message) {
        case "Plug is already in the system":
          error_message = "Device is already registered to a manager";
          break;
        case "User is not authorized to use this plug":
          error_message = "Access to this device is restricted. For access, please contact management.";
          break;
        case "Insufficient wallet fund":
          error_message = "Insufficient funds in wallet";
          break;
        case "Plug not found in the system":
          error_message = "Device does not exist";
          break;
        case "Plug not reachable":
          error_message = "Device not reachable";
          break;
        case `Username doesn't exist`:
          error_message = "User does not exist";
          break;
        case `Plug is disabled`:
          error_message = "Device has been disabled";
          break;
        case `Plug mode change failed`:
          error_message = `Failed to change device's power settings. Device maybe offline.`;
          break;
        case `The plug is already in use`:
          error_message = `This device is already in use`;
          break;
        case `Username exists`:
          error_message = `E-mail is already registered. Please try signing in`;
          break;
      }
    } else if (status === 401) {
      console.log("--- 401 ---", message);
      if (message === "Valid token needed") {
        await authStore.logout();
        authStore.redirectToLogin();
      }
    } else if (status === 500) {
      error_message = "Error 500. Please contact support using the 'Chat With Us' tab";
    }
    return error_message;
  }

  /** Authentication API */
  login(formData, headers = {}) {
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/authenticate`, formData, headers, "capacitor-http");
  }
  signup(role, formData) {
    return this.runApi("POST", `${this.API_URL}/${role}/signup`, formData);
  }
  changePassword(role, formData) {
    return this.runApi("POST", `${this.API_URL}/${role}/change-password`, formData);
  }
  resetPassword(role, formData) {
    return this.runApi("POST", `${this.API_URL}/${role}/reset-password`, formData);
  }
  setPassword(role, formData) {
    return this.runApi("POST", `${this.API_URL}/${role}/set-password`, formData);
  }
  /** End Authentication API */

  /** Admin Related API */
  adminUser(formData) {
    let api_url = `${this.API_URL}/admin/user`;
    return this.runApi("POST", api_url, formData);
  }
  walletsRefund(formData) {
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/wallets/refund`, {
      ...formData,
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });
  }
  adminOwner(formData) {
    let api_url = `${this.API_URL}/admin/owner`;
    return this.runApi("POST", api_url, formData);
  }
  plugSetExpiry(formData) {
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug/set-expiry`, formData);
  }
  plugSetState(formData) {
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug/set-state`, formData);
  }
  plugSetMode(formData: any) {
    let parsedFormData = typeof formData === "string" ? JSON.parse(formData) : formData;
    parsedFormData = Object.assign({}, parsedFormData, ApiHelpers.getActAsPayload(ownerProxyStore.actAs));
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug/set-mode`, JSON.stringify(parsedFormData));
  }
  /** End Admin Related API */

  /** Log API */
  getLog(formData) {
    const headers = { "x-log-token": "LettherebeloggoleberehtteL23", "Content-Type": "multipart/form-data" };
    return this.runApi("POST", `${this.LOG_API_URL}/get-log`, formData, headers);
  }
  lastLog(formData) {
    const headers = { "x-log-token": "LettherebeloggoleberehtteL23", "Content-Type": "multipart/form-data" };
    return this.runApi("POST", `${this.LOG_API_URL}/last-log`, formData, headers);
  }
  /** End Log API */

  /** Plugs or Device Related API */
  plug(formData) {
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug`, formData);
  }
  plugs(formData: any = {}, apiPrefix?: string) {
    if (!apiPrefix) apiPrefix = ApiHelpers.getApiPrefix(authStore.role, adminStore.activeView);
    formData = {
      ...formData,
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview, adminStore.userview),
    };
    return this.runApi("POST", `${this.API_URL}/${apiPrefix}/plugs`, formData);
  }
  plugUsers(plugId: number) {
    const route = authStore.isAdmin ? "admin/plug/users" : "owner/plug-users";
    return this.runApi("POST", `${this.API_URL}/${route}`, {
      plugId,
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });
  }
  plugPaymentAccessPlans() {
    const apiPrefix = ApiHelpers.getApiPrefix(authStore.role, adminStore.activeView);
    return this.runApi("POST", `${this.API_URL}/${apiPrefix}/plug-payment-access-plans`, {
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });
  }
  plugDataplanConfig(formData: any) {
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug/get-dataplan-config`, { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) });
  }
  plugRequestSession(formData = {}) {
    return this.runApi("POST", `${this.API_URL}/plug/request-session`, formData);
  }
  plugSessionUpdate(formData = {}, headers = {}) {
    return this.runApi("POST", `${this.API_URL}/plug/session-update`, formData, headers);
  }
  plugBillingPaymentLog(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug/billing-payment-log`, formData);
  }
  plugPaymentAccessPlansUpdate(formData: any) {
    let prefix = "plug-payment-access-plan";
    if (authStore.isOwner) prefix = "plug-payment-access-plans";

    return this.runApi("POST", `${this.API_URL}/${authStore.role}/${prefix}/update`, {
      ...formData,
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
    });
  }
  plugRegister(formData: any) {
    const endpoint = authStore.isAdmin ? "admin/plug/register" : "owner/register-plug";
    return this.runApi("POST", `${this.API_URL}/${endpoint}`, {
      ...formData,
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
    });
  }
  plugSetAccess(access: string, formData: any) {
    let requestData = typeof formData === "string" ? JSON.parse(formData) : formData;
    requestData = { ...requestData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug/set-access-${access}`, JSON.stringify(requestData));
  }
  plugSetPaymentWallet(formData: any) {
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug/set-payment-wallet`, {
      ...formData,
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
    });
  }
  userPlugs(formData: any) {
    const apiPrefix = ApiHelpers.getApiPrefix(authStore.role, adminStore.activeView);
    return this.runApi("POST", `${this.API_URL}/${apiPrefix}/user-plugs`, {
      ...formData,
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });
  }
  setUserAccessToPlug(is_grant: boolean, formData: any) {
    const grant = is_grant ? "give" : "revoke";

    let parsedFormData = typeof formData === "string" ? JSON.parse(formData) : formData;
    if (ownerProxyStore.actAs) {
      parsedFormData = Object.assign({}, parsedFormData, ApiHelpers.getActAsPayload(ownerProxyStore.actAs));
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/${grant}-user-access-to-plug`, JSON.stringify(parsedFormData));
  }
  ownerPlugRenewService(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/owner/plug/renew-service`, formData);
  }
  plugSetDescription(formData: any) {
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug/set-description`, {
      ...formData,
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });
  }
  plugPaymentAccessPlansCreate(formData: any) {
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug-payment-access-plans/create`, {
      ...formData,
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });
  }
  plugSendCommand(formData: any) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/plug/send-command`, formData);
  }
  userPlugAccess(formData: any) {
    return this.runApi("POST", `${this.API_URL}/user/plug-access`, formData);
  }
  plugGetAsyncToken(role, formData) {
    return this.runApi("POST", `${this.API_URL}/${role}/plug/get-async-token`, formData);
  }
  plugPostAsyncSessions(role, formData) {
    return this.runApi("POST", `${this.API_URL}/${role}/plug/post-async-sessions`, formData);
  }
  userPlugSetAutoStart(formData) {
    return this.runApi("POST", `${this.API_URL}/user/plug/set-auto-start`, formData);
  }
  adminPlugDecomission(formData) {
    return this.runApi("POST", `${this.API_URL}/admin/plug/decomission`, formData);
  }
  adminPlugReplace(formData) {
    return this.runApi("POST", `${this.API_URL}/admin/plug/replace`, formData);
  }
  adminManufacturedPlugFetch(formData) {
    return this.runApi("POST", `${this.API_URL}/admin/manufactured-plug/fetch`, formData);
  }
  adminPlugGetDataplanConfig(formData) {
    return this.runApi("POST", `${this.API_URL}/admin/plug/get-dataplan-config`, formData);
  }
  adminPlugSetDataplanConfig(formData) {
    return this.runApi("POST", `${this.API_URL}/admin/plug/set-dataplan-config`, formData);
  }
  /** End Plugs or Device Related API */

  /** Sessions Related API */
  ongoingSessions() {
    const apiPrefix = ApiHelpers.getApiPrefix(authStore.role, adminStore.activeView);
    return this.runApi("POST", `${this.API_URL}/${apiPrefix}/ongoing-sessions`, {
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });
  }
  sessionLogs(formData: any) {
    let api_url = `${this.API_URL}`;
    if (authStore.isAdmin) api_url += "/admin/session";
    else api_url += `/${authStore.role}/sessions`;

    formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };

    return this.runApi("POST", `${api_url}/log`, formData);
  }
  sessions(formData: any) {
    let apiPrefix = ApiHelpers.getApiPrefix(authStore.role, adminStore.activeView);
    if (apiPrefix === "admin") {
      apiPrefix = "admin/owner";
      if (adminStore.isDeviceView) {
        apiPrefix = "admin/plug";
        formData.plugIdentifier = adminStore.deviceview?.identifier;
      }
    }
    formData = {
      ...formData,
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview, adminStore.userview),
    };
    return this.runApi("POST", `${this.API_URL}/${apiPrefix}/sessions`, formData);
  }
  sessionsEnd(formData) {
    let api_url = `${this.API_URL}/user/sessions/end`;
    return this.runApi("POST", api_url, formData);
  }
  sessionsStart(formData) {
    let api_url = `${this.API_URL}/user/sessions/start`;
    return this.runApi("POST", api_url, formData);
  }
  ownerKillSession(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/owner/kill-session`, formData);
  }
  /** End Sessions Related API */

  /** Wallet Related API */
  wallets(formData: any = {}, apiPrefix?: string) {
    if (!apiPrefix) apiPrefix = ApiHelpers.getApiPrefix(authStore.role, adminStore.activeView);
    formData = {
      ...formData,
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview, adminStore.userview),
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    };
    return this.runApi("POST", `${this.API_URL}/${apiPrefix}/wallets`, formData);
  }
  adminWallet(formData) {
    return this.runApi("POST", `${this.API_URL}/admin/wallet`, formData);
  }
  walletsCreate(formData: WalletCreateInterface) {
    const apiPrefix = ApiHelpers.getApiPrefix(authStore.role, adminStore.activeView);
    formData = {
      ...formData,
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview, adminStore.userview),
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    };
    return this.runApi("POST", `${this.API_URL}/${apiPrefix}/wallets/create`, formData);
  }
  walletsSetDescription(formData: WalletSetDescriptionInterface) {
    formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/wallets/set-description`, formData);
  }
  walletTransactions(walletId: number) {
    const apiPrefix = authStore.isAdmin ? "admin/wallet" : `${authStore.role}/wallets`;
    const formData = { walletId, since: filterStore.startDatetimeStartOfDay, till: filterStore.endDatetimeEndOfDay, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    return this.runApi("POST", `${this.API_URL}/${apiPrefix}/transactions`, formData);
  }
  walletTopupPaypal(formData, currency) {
    if (ownerProxyStore.actAs) {
      const currentValue = typeof formData === "string" ? JSON.parse(formData) : formData;
      formData = JSON.stringify({ ...currentValue, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) });
    }
    const endpoint = currency === "SGD" ? `/paypal-singapore` : `/paypal-v2`;
    return this.runApi("POST", `${this.API_URL}/wallet/topup${endpoint}`, formData);
  }
  walletTopupRazorpay(formData) {
    if (ownerProxyStore.actAs) {
      const currentValue = typeof formData === "string" ? JSON.parse(formData) : formData;
      formData = JSON.stringify({ ...currentValue, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) });
    }
    return this.runApi("POST", `${this.API_URL}/wallet/topup/razorpay`, formData);
  }
  walletTopupMobikwik(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/wallet/topup/mobikwik`, formData);
  }
  walletRazorpayCreateOrder(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/wallet/razorpay/create-order`, formData);
  }
  walletMobikwikCreateOrder(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/wallet/mobikwik/create-order`, formData);
  }
  walletsSetParams(formData: WalletSetParamsInterface) {
    formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/wallets/set-params`, formData);
  }
  walletsRequestPayout(formData: any) {
    formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/wallets/request-payout`, formData);
  }
  /** End Wallet Related API */

  /** Mobile App Related API */
  userPushNotification(mode, formData) {
    return this.runApi("POST", `${this.API_URL}/user/push-notification/${mode}`, formData);
  }
  /** End Mobile App Related API */

  /** Public Profile Related API */
  ownerGetPublicProfile(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/owner/get-public-profile`, formData);
  }
  ownerSetPublicProfile(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/owner/set-public-profile`, formData);
  }
  /** End Public Profile Related API */

  /** General API */
  sendFeedback(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/send-feedback`, formData);
  }
  version(formData = {}) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("GET", `${this.API_URL}/version`, formData);
  }
  /** End General API */

  /** Settings API */
  getPrefs(apiPrefix: string, formData: any = {}) {
    formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    return this.runApi("POST", `${this.API_URL}/${apiPrefix}/get-prefs`, formData);
  }
  setPrefs(formData: PreferenceSetInterface) {
    const apiPrefix = ApiHelpers.getApiPrefix(authStore.role, adminStore.activeView);
    const currentValue = typeof formData === "string" ? JSON.parse(formData) : formData;

    formData = JSON.stringify({
      ...currentValue,
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview, adminStore.userview),
    }) as any;
    return this.runApi("POST", `${this.API_URL}/${apiPrefix}/set-prefs`, formData);
  }
  adminOwnerRename(formData) {
    return this.runApi("POST", `${this.API_URL}/admin/owner/rename`, formData);
  }
  /** End Settings API */

  /** KillSession API */
  adminPlugsessionkill(formData) {
    return this.runApi("POST", `${this.API_URL}/admin/plug/session/kill`, formData);
  }
  /** End KillSession API */

  /** Owner Proxies API */
  proxySet(role: string, formData: any) {
    const currentValue = typeof formData === "string" ? JSON.parse(formData) : formData;

    formData = JSON.stringify({
      ...currentValue,
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    }) as any;

    return this.runApi("POST", `${this.API_URL}/${role}/owner-proxy/set`, formData);
  }
  proxyProxies(formData: any) {
    const currentValue = typeof formData === "string" ? JSON.parse(formData) : formData;

    formData = JSON.stringify({
      ...currentValue,
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    }) as any;

    return this.runApi("POST", `${this.API_URL}/${authStore.role}/owner-proxy/proxies`, formData);
  }
  proxyOwners(formData, includeActAs = true) {
    if (includeActAs && ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    if (adminStore.managerview) {
      formData = ApiHelpers.appendValueToPayload(formData, "ownerProxyUsername", adminStore.managerview?.owner_username);
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/owner-proxy/owners`, formData);
  }
  proxyRemove(role: string, formData: any) {
    const currentValue = typeof formData === "string" ? JSON.parse(formData) : formData;

    formData = JSON.stringify({
      ...currentValue,
      ...ApiHelpers.getUsernamePayloadOfViewAs(adminStore.activeView, adminStore.managerview),
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    }) as any;

    return this.runApi("POST", `${this.API_URL}/${role}/owner-proxy/remove`, formData);
  }
  /** End Owner Proxies API */

  /** Admin Userview Set-Password API  */
  adminUserSetPassword(formData) {
    return this.runApi("POST", `${this.API_URL}/admin/user/set-password`, formData);
  }
  /** Admin Userview Set-Password API  */
  /** Mobikwik */
  mobikwikMakeChecksum(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/mobikwik/make-checksum`, formData);
  }
  mobikwikCheckExistingUser(mobileNumber) {
    let payload = JSON.stringify({
      endpoint: "/querywallet",
      params: {
        action: "existingusercheck",
        cell: mobileNumber,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 500,
      },
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${this.API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });

    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 500,
    //       statuscode: 0,
    //       statusdescription: "User Exists",
    //       status: "SUCCESS",
    //       emailaddress: "arya.bawanta@gmail.com",
    //       nonzeroflag: "n"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikCreateUser(mobileNumber, email = "", otp = "") {
    let payload = JSON.stringify({
      endpoint: "/createwalletuser",
      params: {
        cell: mobileNumber,
        email,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 502,
        otp,
      },
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${this.API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });

    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 502,
    //       statuscode: 0,
    //       statusdescription: "User Created",
    //       status: "SUCCESS",
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikGenerateOTP(mobileNumber, amount = 0) {
    const payload = JSON.stringify({
      endpoint: "/otpgenerate",
      params: {
        amount,
        cell: mobileNumber,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 504,
        tokentype: 1,
      },
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${this.API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 504,
    //       statuscode: 0,
    //       statusdescription: "Message Sent to xxxxxxxx",
    //       status: "SUCCESS",
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikGenerateToken(mobileNumber, amount, otp) {
    let payload = JSON.stringify({
      endpoint: "/tokengenerate",
      params: {
        amount,
        cell: mobileNumber,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 507,
        otp,
        tokentype: 1,
      },
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${this.API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 507,
    //       statuscode: 0,
    //       statusdescription: "Token Generated",
    //       status: "SUCCESS",
    //       token: "e099cd677d2a4581aa2828e1c3991b01"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikCheckBalance(mobileNumber, token = "") {
    const payload = JSON.stringify({
      endpoint: "/userbalance",
      params: {
        cell: mobileNumber,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 501,
        token,
      },
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${this.API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 501,
    //       statuscode: 0,
    //       statusdescription: "Balance Amount",
    //       status: "SUCCESS",
    //       balanceamount: 0
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikAddMoneyToWalletAndDebit(mobileNumber, token = "", orderid = "", amount = 0, redirecturl = "https://api.plugz.io") {
    const payload = JSON.stringify({
      endpoint: "/addmoneytowalletanddebit",
      params: {
        amount,
        cell: mobileNumber,
        merchantname: "plugzio",
        mid: "plugzioid",
        orderid,
        redirecturl,
        token,
      },
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${this.API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 503,
    //       statuscode: 0,
    //       statusdescription: "Amount Debited",
    //       status: "SUCCESS",
    //       balanceamount: 2111,
    //       debitedamount: 1,
    //       refId: "1234567890"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikProcessDebit(mobileNumber, token = "", orderid = "", txntype = "debit", amount = 0, comment = "Debit") {
    const payload = JSON.stringify({
      endpoint: "/debitwallet",
      params: {
        amount,
        cell: mobileNumber,
        comment,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 503,
        orderid,
        token,
        txntype,
      },
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${this.API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       messagecode: 503,
    //       statuscode: 0,
    //       statusdescription: "Amount Debited",
    //       status: "SUCCESS",
    //       balanceamount: 2111,
    //       debitedamount: 1,
    //       refId: "1234567890"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikCheckStatus(orderid = "") {
    let payload = JSON.stringify({
      endpoint: "/checkstatus",
      params: {
        mid: "plugzioid",
        orderid,
      },
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${this.API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response, "wallet");
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       statuscode: 0,
    //       orderid: "12345",
    //       statusmessage: "success",
    //       amount: 1,
    //       refId: "1234567890",
    //       ordertype: "payment"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  mobikwikTokenRegenerate(cell, token) {
    let payload = JSON.stringify({
      endpoint: "/tokenregenerate",
      params: {
        cell,
        merchantname: "plugzio",
        mid: "plugzioid",
        msgcode: 507,
        token,
        tokentype: 1,
      },
      ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs),
    });

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.runApi("POST", `${this.API_URL}/mobikwik/make-api-call`, payload);
        const parsedResponse = xmlHelpers.parse(response);
        if (parsedResponse.status === "FAILURE") throw parsedResponse.statusdescription;
        resolve(parsedResponse);
      } catch (error) {
        reject(error);
      }
    });
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     resolve({
    //       statuscode: 0,
    //       status: "SUCCESS",
    //       messagecode: 507,
    //       statusdescription: "Token Generated",
    //       token: "f4f72b7f7693484cb8bdc068f8cc14b3",
    //       checksum: "1234567890"
    //     })
    //   } catch (error) {
    //     reject(error)
    //   }
    // })
  }
  /** END Mobilwik */

  /** Load Balancing Related API */
  loadBalancingCollectives(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/load-balancing/collectives`, formData);
  }
  loadBalancingCollectiveAddPlug(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/load-balancing/collective/add-plug`, formData);
  }
  loadBalancingCollectiveCreate(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/load-balancing/collective/create`, formData);
  }
  loadBalancingCollectiveDelete(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/load-balancing/collective/delete`, formData);
  }
  loadBalancingCollectiveGet(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/load-balancing/collective/get`, formData);
  }
  loadBalancingCollectiveRemovePlug(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/load-balancing/collective/remove-plug`, formData);
  }
  loadBalancingCollectiveUpdate(formData) {
    if (ownerProxyStore.actAs) {
      formData = { ...formData, ...ApiHelpers.getActAsPayload(ownerProxyStore.actAs) };
    }
    return this.runApi("POST", `${this.API_URL}/${authStore.role}/load-balancing/collective/update`, formData);
  }
  /** End Load Balancing API */
}

export default new Api();
