import pinia from "@/pinia";
import "@mdi/font/css/materialdesignicons.css";
import { createApp } from "vue";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";
import App from "./App.vue";
import router from "./router";
// import store from "./store/store";
// import axios from "axios";
// import VueNumeralFilter from "vue-numeral-filter";
// import LightTimeline from "vue-light-timeline";
// import VueMoment from "vue-moment";
// import moment from "moment-timezone";
// import VueAgile from "vue-agile";
import "./window-settings";
// import sessions from "@/mixins/sessions";
// import { DeviceHelpers, log } from "./library/helpers";
import { authStore, globalStore } from "@/store-pinia";
import { createIntercom } from "@mathieustan/vue-intercom";
// import RouteHelpers from "./library/helpers/RouteHelpers";
import { createGtm } from "@gtm-support/vue-gtm";
import { ConsoleHelpers } from "./library/helpers";
import "./version";

if ((window as any).version) console.log("version : " + (window as any).version);

const app = createApp(App);
app.use(router);
app.use(pinia);
app.use(
  createVuetify({
    components,
    directives,
    icons: {
      defaultSet: "mdi",
    },
  })
);

const intercom = createIntercom({ appId: import.meta.env.VITE_APP_INTERCOM_APP_ID });
app.use(intercom);
(window as any).Intercom("onShow", () => (globalStore.intercom.show = true));
(window as any).Intercom("onHide", () => (globalStore.intercom.show = false));

ConsoleHelpers.allowLog = import.meta.env.VITE_APP_SERVER !== "production";

app.mount("#app");

// const role = RouteHelpers.isAdminUrl() ? "admin" : RouteHelpers.isOwnerUrl() ? "owner" : "user";

// console.log(`base: ${window.UI_URL}`);
// console.log(`host: ${window.location.host}`);
// console.log(`api: ${window.API_URL}`);
// console.log(`log api: ${window.LOG_API_URL}`);
// console.log("is capacitor:", Capacitor.isNativePlatform());

// // Vue set
// sync(store, router);
// app.config.productionTip = false;
// app.use(Vuetify, {
//   theme: {
//     primary: "#424242",
//     accent: "#2b8a2b",
//   },
// });
// app.use(LightTimeline);
// app.use(VueNumeralFilter);
// app.use(VueMoment, { moment });
// app.use(VTooltip);
// app.use(VueAgile);
// app.use(VueMeta);
// app.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID })

// Admin is not having google tag, so please handle that later
if (!authStore.isAdmin) {
  const gtmId = authStore.isOwner ? import.meta.env.VITE_APP_MANAGER_GTM_ID : import.meta.env.VITE_APP_USER_GTM_ID;
  app.use(
    createGtm({
      id: gtmId,
      vueRouter: router,
    })
  );
}

// // Axios set
// window.axios = axios;
// axios.defaults.timeout = 30000;

// // For easier testing
// window.device_parse_serial_number = DeviceHelpers.parseSerialNumber;
