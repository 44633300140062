<template>
  <div id="app" ref="app">
    <v-app :theme="theme">
      <NavBar v-if="isAuthenticatedPage" />
      <v-main
        class="d-flex align-center justify-center flex-column"
        :class="{
          'v-content-with-toolbar': isAuthenticatedPage,
          'v-content-with-toolbar-show-sidebar': isAuthenticatedPage && globalStore.showSidebar,
          'v-content-responsive': globalStore.responsive,
        }"
      >
        <div
          class="pb-5rem fill-height full-inner-height w-100"
          :class="{
            'd-flex align-center justify-center': globalStore.offline,
          }"
        >
          <Offline v-if="globalStore.offline" />
          <router-view v-else-if="ownerProxyInitialized" :key="ownerProxyStore.actAs" />
          <div v-else class="d-flex justify-center align-center h-full">
            <v-progress-circular class="loading-circle mb-3" size="50" indeterminate color="orange" />
          </div>
        </div>

        <Policy v-if="isAuthenticatedPage" class="policy-footer" :layout="globalStore.responsive ? 'column' : 'row'" />
        <v-snackbar v-model="snackbar.show" bottom :timeout="3000">
          {{ snackbar.message }}

          <v-icon size="small" icon="mdi-close" color="#bbbbbb" @click="globalStore.snackbar.show = false" />
        </v-snackbar>

        <v-snackbar
          :key="JSON.stringify(globalStore.offlineModeSnackbar)"
          v-model="globalStore.offlineModeSnackbar.show"
          bottom
          :timeout="globalStore.offlineModeSnackbar.timeout"
          :color="globalStore.offlineModeSnackbar.color"
        >
          {{ globalStore.offlineModeSnackbar.message }}
          <v-icon size="small" icon="mdi-close" color="#bbbbbb" @click="globalStore.setOffline({ offlineModeSnackbar: { show: false } })" />
        </v-snackbar>

        <PlugCloseToExpiration v-if="authStore.isOwner" />
      </v-main>
      <OpenAppSnackbar v-if="globalStore.showOpenAppSnackbar" />
      <GlobalDialog />
      <Intercom />
    </v-app>
  </div>
</template>

<script lang="ts" setup>
import NavBar from "@/components/NavBar.vue";
import Offline from "@/components/Offline.vue";
import OpenAppSnackbar from "@/components/OpenAppSnackbar.vue";
import Intercom from "@/components/intercom/Intercom.vue";
import GlobalDialog from "@/components/modals/GlobalDialog.vue";
import Policy from "@/components/policy/Policy.vue";
import RouteHelpers from "@/library/helpers/RouteHelpers";
import { adminStore, authStore, eventStore, globalStore, ownerProxyStore, sessionStore } from "@/store-pinia";
import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Filesystem } from "@capacitor/filesystem";
import { PushNotifications } from "@capacitor/push-notifications";
import { useSwipe } from "@vueuse/core";
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch, watchEffect } from "vue-demi";
import { useRoute, useRouter } from "vue-router";
import PlugCloseToExpiration from "./components/modals/PlugCloseToExpiration.vue";
import { sleep } from "./library/helpers";

const router = useRouter();

authStore.$onAction(({ name }) => {
  if (name === "redirectToLogin") {
    nextTick(() => {
      if (RouteHelpers.isAuthenticatedPage()) {
        router.push({ name: `${authStore.roleSetting.routePrefix}login` });
      }
    });
  }
});

const isAuthenticatedPage = computed(() => RouteHelpers.isAuthenticatedPage());
const ownerProxyInitialized = computed(() => !authStore.isAuthenticated || ownerProxyStore.initialized);
const reloadOnStateChange = computed(() => globalStore.isReloadOnStateChange);

const snackbar = computed({
  get: () => globalStore.snackbar,
  set: (v) => {
    if (!v) globalStore.snackbar.show = false;
  },
});

const isDark = computed(() => {
  return globalStore.preferences?.settings?.darkTheme || false;
});

const theme = computed(() => {
  if (!isAuthenticatedPage.value) return "light";
  return isDark.value ? "dark" : "light";
});

const route = useRoute();
watch(
  () => route,
  () => {
    // Default route is null, then it is changed to correct route object
    // the isAuthenticatedPage handler can only be called once the route is changed
    if (!isAuthenticatedPage.value) {
      ownerProxyStore.resetState();
      ownerProxyStore.initialized = true;
    }
    var metaThemeColor = document.querySelector("meta[name=theme-color]");

    nextTick(() => {
      document.body.classList.remove("admin-mode");
      document.body.classList.remove("user-mode");
      document.body.classList.remove("owner-mode");
      document.body.classList.add(`${authStore.role}-mode`);

      metaThemeColor.setAttribute("content", authStore.roleSetting?.color);
    });
  },
  { deep: true }
);

const handleNetworkChange = () => {
  globalStore.setOffline({
    offline: !navigator.onLine,
    offlineModeSnackbar: {
      message: navigator.onLine ? "You've back online" : "You are currently offline",
      timeout: navigator.onLine ? 3000 : -1,
      show: true,
      color: navigator.onLine ? "#2b8a2b" : "#212121",
    },
  });

  if (navigator.onLine) setTimeout(() => location.reload(), 3000);
};
const handleDeeplink = async (evt) => {
  globalStore.universalLink = evt;
  let resetPasswordUrl = evt.url.match(/reset-password-verification/) || (evt.url.match(/forgot-password/) && evt.url.match(/verifyjwt/)) || false;
  const isVerifyMobikwik = evt.url.match(/\?verify-mobikwik=true_/) || false;
  if (isVerifyMobikwik) {
    const [baseUrl, query] = evt.url.split(/\?verify-mobikwik=true_/);
    const [orderId, phone, token] = query.split("_");

    location.replace(
      `${baseUrl}${baseUrl.includes("/demo-contractor") ? "#/" : "#/"}${authStore.roleSetting.urlPrefix}verify-mobikwik?orderId=${orderId}&phone=${phone}&token=${token}`
    );
  } else if (!!evt.params.external_id) {
    localStorage.setItem("external-id", evt.params.external_id);
    router.push(`/activate-device/${evt.params.external_id}`);
  } else if (resetPasswordUrl) {
    localStorage.setItem("reset-password", evt.url);
    router.push({ path: `/forgot-password/verify` });
  } else if (evt.params.payload) {
    localStorage.setItem("verify", evt.url);
    router.push({ path: `/login/verify` });
  } else {
    const hash = evt.hash.slice(1);
    router.push({ path: hash });
  }

  // Delay 1s to give space for the router to push the new route
  await sleep(1000);
  globalStore.reloadOnStateChange = true;
};

const handleMobileAppAndDeeplinks = () => {
  if (!Capacitor.isNativePlatform()) {
    const url = new URL(document.URL);
    const mappedUrl = {
      url: url.href,
      scheme: "https",
      host: url.host,
      path: url.pathname,
      params: url.href.split("?")[1] ? Object.fromEntries(new URLSearchParams(url.href.split("?")[1])) : {},
      hash: url.hash,
    };
    handleDeeplink(mappedUrl);

    let firebasePath = `${window.location.href.match(/demo-contractor/) ? "/demo-contractor" : ""}/static/firebase-messaging-sw.js`;
    Notification.requestPermission().then(async (permission) => {
      const { default: firebaseMessaging } = await import("./firebase");
      const { getToken, onMessage } = await import("firebase/messaging");
      if (permission === "granted") {
        const serviceWorkerRegistration = await navigator.serviceWorker.register(firebasePath);
        getToken(firebaseMessaging, {
          vapidKey: import.meta.env.VITE_APP_FIREBASE_VAPID_KEY,
          serviceWorkerRegistration,
        })
          .then((token) => {
            globalStore.firebaseToken = token;
            onMessage(firebaseMessaging, ({ data }) => {
              console.log("[firebase] message received", data);

              if (sessionStore.processedDevices.map((v) => v.identifier).includes(data.plugIdentifier)) {
                sessionStore.removeProcessedDevices(data.plugIdentifier);
                sessionStore.fetchData();
              }
            });
          })
          .catch((err) => {
            console.log("An error occurred while retrieving firebase token. ", err);
          });
      }
    });

    return;
  }

  CapacitorApp.addListener("appStateChange", ({ isActive }) => {
    console.log("App state changed. Active =", isActive);
    // TODO: remove authStore.isAuthenticated if the app need to be refreshed on unauthenticated pages
    if (isActive && reloadOnStateChange.value && authStore.isAuthenticated) location.reload();
  });

  const onResume = () => eventStore.onMobileAppResumed();
  const onPause = () => eventStore.onMobileAppPaused();

  const onDeviceReady = async () => {
    console.log("device is ready");

    CapacitorApp.addListener("appUrlOpen", function (event) {
      globalStore.reloadOnStateChange = false;
      const url = new URL(event.url);
      const mappedUrl = {
        url: url.href,
        scheme: "https",
        host: url.host,
        path: url.pathname,
        params: Object.fromEntries(url.searchParams),
        hash: url.hash,
      };
      console.log("open url", mappedUrl);
      console.log("open ur params", mappedUrl.params);
      handleDeeplink(mappedUrl);
    });

    let FilesystemPermission = await Filesystem.checkPermissions();
    console.log(FilesystemPermission);
    if (FilesystemPermission.publicStorage === "prompt") {
      FilesystemPermission = await Filesystem.requestPermissions();
    }

    console.log("Initializing Capacitor Push Notification");

    let pushNotificationPermission = await PushNotifications.checkPermissions();
    console.log("request notification permission", pushNotificationPermission);
    if (pushNotificationPermission.receive === "prompt") {
      pushNotificationPermission = await PushNotifications.requestPermissions();
    }
    console.log("request notification permission", pushNotificationPermission);
    if (pushNotificationPermission.receive === "granted") {
      console.log("Notification permission granted");
      PushNotifications.register();
    } else {
      console.error("Notification permission not granted");
    }

    /** Listeners */
    PushNotifications.addListener("registration", (token) => {
      console.log("Push registration success, token: " + token.value);
      globalStore.firebaseToken = token.value;
    });

    PushNotifications.addListener("registrationError", (error) => {
      console.log("Error on registration: " + JSON.stringify(error));
    });

    PushNotifications.addListener("pushNotificationReceived", (notification) => {
      console.log("Push received: " + JSON.stringify(notification));

      if (sessionStore.processedDevices.map((v) => v.identifier).includes(notification.data.plugIdentifier)) {
        sessionStore.removeProcessedDevices(notification.data.plugIdentifier);
        sessionStore.fetchData();
      }
      globalStore.firebaseMessage = notification;
    });

    PushNotifications.addListener("pushNotificationActionPerformed", ({ notification: { data } }) => {
      console.log("Push action performed: " + JSON.stringify(data));
      const sessionEvent = data.event;
      const sessionId = data.sessionId;
      let queryString = "status=on";
      if (sessionEvent === "session-ended") queryString = `openSessionIdDialog=${sessionId}`;
      router.push(`/sessions?${queryString}`);
    });
  };

  document.addEventListener("deviceready", onDeviceReady, false);
  document.addEventListener("resume", onResume, false);
  document.addEventListener("pause", onPause, false);
};

watch(
  () => route.name,
  () => {
    adminStore.setActiveView(route.name);
    // New version of capacitor required splash screen, so everytime location reloaded, the splash screen appears
    // if (isMobileApplication && reloadOnStateChange.value) location.reload();
  }
);

const app = ref(null);
const { isSwiping, direction } = useSwipe(app);
watchEffect(() => {
  if (isSwiping.value) {
    eventStore.onSwiped(direction.value);
  }
});

onMounted(() => {
  // these are same with previously GLobal/initialize
  globalStore.initializeWindow();
  globalStore.initializeTimezone();
  globalStore.initializeLocation();

  adminStore.setActiveView(route.name);
  // this.$store.dispatch("setTime", this.timeSpan);

  // network event listeners
  if (!navigator.onLine) handleNetworkChange();
  window.addEventListener("online", handleNetworkChange);
  window.addEventListener("offline", handleNetworkChange);

  handleMobileAppAndDeeplinks();

  if (authStore.role == "user" && !Capacitor.isNativePlatform()) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      globalStore.showOpenAppSnackbar = true;
    }
  }

  // // BLE is not implemented now, so this code is commented
  // if (this.role != "admin") {
  //   this.processStoredBleSessions();
  //   this.$router.afterEach((to, from) => {
  //     this.processStoredBleSessions();
  //   });
  // }
});

onBeforeUnmount(() => {
  window.removeEventListener("online", handleNetworkChange);
  window.removeEventListener("offline", handleNetworkChange);
});

// BLE is not implemented now, so this code is commented
//   async processStoredBleSessions() {
//     console.groupCollapsed("[STORED BLE SESSIONS PROCESSING] Processing stored BLE sessions...");
//     if (!navigator.onLine) {
//       console.error("[STORED BLE SESSIONS PROCESSING] No internet connection detected, please connect to internet to upload the processed BLE sessions");
//       console.groupEnd();
//       return;
//     }
//     let cache = JSON.parse(localStorage.getItem("__plugzio_storage__"));
//     if (!cache) console.log("[STORED BLE SESSIONS PROCESSING] No stored sessions detected...");

//     for (const email in cache) {
//       console.groupCollapsed("[STORED BLE SESSIONS PROCESSING] Processing stored BLE sessions for user " + email);
//       const bleDevices = cache[email].hasOwnProperty("bleDevices") ? cache[email].bleDevices : [];
//       if (bleDevices.length == 0) console.log("[STORED BLE SESSIONS PROCESSING] No devices found");
//       for (let index = 0; index < bleDevices.length; index++) {
//         const bleDevice = bleDevices[index];
//         console.groupCollapsed("[STORED BLE SESSIONS PROCESSING] Processing ble with device id : " + bleDevice.device_id);
//         const signature = bleDevice.signature;
//         const chunks = bleDevice.chunks;
//         const sessions = bleDevice.sessions;
//         if (sessions.length > 0 && chunks && signature) {
//           console.log("[STORED BLE SESSIONS PROCESSING] Uploading ble sessions...");
//           await Api.plugPostAsyncSessions("user", `{"plugIdentifier":"${bleDevice.device_id}", "signature":"${signature}","sessionsChunks":${chunks}}`)
//             .then(async () => {
//               console.log("[STORED BLE SESSIONS PROCESSING] Stored sessions successfully uploaded. Removing uploaded sessions...");
//               this.$store.dispatch("setBleDevice", {
//                 device_id: bleDevice.device_id,
//                 properties: { sessions: [], signature: null, chunks: null },
//                 email: email,
//               });

//               console.log("[STORED BLE SESSIONS PROCESSING] Renew Device token");
//               await Api.plugGetAsyncToken("user", `{"plugIdentifier":"${bleDevice.device_id}"}`)
//                 .then((data) => {
//                   this.$store.dispatch("setBleDevice", {
//                     device_id: bleDevice.device_id,
//                     properties: { credential: data },
//                     email: email,
//                   });
//                   console.log("[STORED BLE SESSIONS PROCESSING] Successfully renew device token");
//                 })
//                 .catch((error) => console.error("[STORED BLE SESSIONS PROCESSING] " + error));
//             })
//             .catch((error) => console.error("[STORED BLE SESSIONS PROCESSING] " + error));
//         } else console.log("[STORED BLE SESSIONS PROCESSING] No sessions detected, stopping the process");

//         console.groupEnd();
//       }
//       console.groupEnd();
//     }
//     console.groupEnd();
//   },
//   },

// import mixin from "./mixins";
// import Api from "./library/apis/Api";
// import { Capacitor } from "@capacitor/core";
// import { App } from "@capacitor/app"
// import { capitalize } from "lodash-es";
// import PlugCloseToExpiration from "./components/modals/PlugCloseToExpiration";
// import NavBar from "@/components/NavBar";
// import Intercom from "@/components/intercom/Intercom.vue";
// import { AUTHENTICATED_ROUTES } from "@/constants"

// export default {
// https://dev.to/js_bits_bill/dynamic-open-graph-meta-tags-with-vuejs-and-node-js-bits-2a11
// metaInfo() {
//   return {
//     title: this.title,
//     titleTemplate: `%s - Plugzio`,
//     meta: [
//       { name: "title", content: `Plugzio ${capitalize(this.role)} Dashboard` },
//       { name: "og:title", content: `Plugzio ${capitalize(this.role)} Dashboard` },
//       {
//         name: "description",
//         content: this.role == "user" ? `Login to activate a device monitor usage and access your wallet` : "Login to manage your devices, view analytics and access your wallets",
//       },
//       {
//         name: "og:description",
//         content: this.role == "user" ? `Login to activate a device monitor usage and access your wallet` : "Login to manage your devices, view analytics and access your wallets",
//       },
//       { name: "og:url", content: window.location.origin + window.location.pathname },
//       { name: "og:image", content: window.location.origin + window.location.pathname + "/static/PlugzioLogoHorizontal.png" },
//       { name: "twitter:image", content: window.location.origin + window.location.pathname + "/static/favicon-152x152.png" },
//     ],
//   };
// },
// mixins: [mixin],
// components: {
//   NavBar,
//   PlugCloseToExpiration,
//   Intercom
// },
// data() {
//   return {
//     actionSub: null,
//   };
// },
// computed: {
//   ...mapState({
//     actAsOwner: state => state.OwnerProxy.actAs,
//   }),
//   ...mapGetters("Auth", {
//     roleSetting: "getRoleSetting",
//   }),
//   ...mapGetters("OwnerProxy", {
//     ownerProxyInitialized: "initialized",
//   }),
//   isApp() {
//     return Capacitor.isNativePlatform();
//   },
//   title() {
//     let route = this.$route.name || "";
//     let title = route.replace(/-+/g, " ").replace(/(?:^|\s)\S/g, (a) => {
//       return a.toUpperCase();
//     });
//     return title.replace("Owner", "Management");
//   },
// },
// };
</script>

<style lang="scss">
@import "./styles/colors";
@import "./styles/app.scss";

a {
  text-decoration: none;
  color: green;
}

.tooltip-icon {
  font-size: 24px !important;
  color: #9a9a9a !important;
}
.container {
  @media only screen and (max-width: 460px) {
    padding: 16px 6px 6px;
  }
}

#app {
  font-family: Roboto, sans-serif;
}
h1 span {
  font-size: 1em;
}
h1 {
  font-weight: 300;
  font-size: 1.6em;
  margin: 0 0 10px 10px;
  @media only screen and (max-width: 960px) {
    font-size: 16px;
    margin: 6px 0 10px 10px;
    font-weight: 600;
  }
}

.main-drawer-menu {
  .v-list__tile__title {
    font-weight: 400;
  }
}

.card-tile-header {
  h3 {
    font-weight: 500;
  }
}

button {
  outline: none;
}

.v-content-with-toolbar {
  padding-top: 58px !important;
}

.v-alert {
  width: 100%;
  margin: 0;
  text-align: left;
}

.plugzio-color--text {
  color: #0d920d !important;
}

.plugzio-green-darken {
  color: #0d920d !important;
  filter: opacity(1) !important;
  font-weight: 450;
}

.plugzio-button {
  color: #0d920d !important;
  border: 1px solid #0d920d;
  min-width: 140px;
  &:disabled,
  &.v-btn.v-btn--disabled {
    color: #9a9a9a !important;
    border: 1px solid #9a9a9a;
    .v-icon {
      color: #9a9a9a !important;
    }
  }
}

.plugzio-danger-button {
  color: #ff5252 !important;
  border: 1px solid #0d920d;
  min-width: 140px;
}

.create-button {
  margin-top: -4px;
  @media only screen and (max-width: 960px) {
    margin-top: 1px;
  }
}

.title-menu {
  font-size: 14px;
  margin: 0;
  @media only screen and (max-width: 420px) {
    font-size: 12px;
  }
}

.page-toolbar {
  margin-top: -5px;
  @media only screen and (max-width: 960px) {
    padding: 4px 9px 0 0;
  }
}

.page-toolbar-btn {
  text-transform: none;
  min-width: auto !important;
  @media only screen and (max-width: 460px) {
    margin-right: 0;
  }
}

.v-list {
  .menu-active,
  .v-list__tile--active {
    background: $user-color !important;
    color: #ffffff !important;
  }
}

.v-input__prepend-inner {
  padding-right: 10px !important;
}

.theme--light.v-text-field--outline .v-input__slot {
  border: 1px solid #f1f1f1 !important;
  background: #f9f9f9 !important;
  border-radius: 4px;
}

.theme--light.v-messages {
  color: rgba(0, 0, 0, 0.64);
}

.snackbar-close-button {
  margin: 0 -12px 0 20px !important;
}

.tile-button {
  border: 2px solid #0d920d;
  padding: 2em 0 2em 0;
}

.date-range {
  margin: -5px 0 0 0;
}

.document-container {
  margin: 0 auto;
  max-width: 900px;
}

.document {
  h2 {
    text-align: center;
  }
  h1 {
    margin: 30px 10px 10px 0 !important;
  }
}

.notice {
  font-size: 1.4em;
  font-weight: 300;
  .notice-description {
    font-size: 0.8em;
  }
  h4 {
    font-weight: 400;
  }
  @media only screen and (max-width: 460px) {
    font-size: 1.2em;
  }
}

.modal-description {
  font-size: 0.9em;
  margin: -1em 0 0;
  color: gray;
}

.info-icon {
  margin: 0 6px -3px 0;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  .pace-progress {
    background: orange;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 3px;
  }
}

.pace-inactive {
  display: none;
}

.pointer {
  cursor: pointer !important;
}

.pull-right {
  float: right;
}

.rotate-icon {
  transform: scaleY(-1);
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}

.full-inner-height {
  height: 100%;
}

.white-bg {
  background: #ffffff;
}

.center-margin {
  margin-left: auto;
  margin-right: auto;
}

.p-relative {
  position: relative;
}

.theme--light.v-label {
  color: rgba(0, 0, 0, 0.75);
}

.hide {
  display: none !important;
}

.tooltip-icon {
  color: #9a9a9a !important;
}

.tooltip-icon:hover {
  color: #333333 !important;
}

.tooltip {
  display: block !important;
  z-index: 201;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 1.5em;
  .tooltip-inner {
    background: rgba(0, 0, 0, 0.75);
    color: white;
    border-radius: 3px;
    padding: 12px 16px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;
    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;
    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition:
      opacity 0.15s,
      visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.solo-button .v-input__slot {
  border: 1px solid #f1f1f1 !important;
  background: #f9f9f9 !important;
  border-radius: 4px;
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgba(249, 249, 249, 1) inset;
  -webkit-text-fill-color: black !important;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
<style lang="scss">
@import "./styles/colors";

html,
body {
  margin: 0;
  bottom: 0;
  background: #f0f4f3 !important;
}
body,
.v-toolbar,
.v-navigation-drawer {
  padding-top: calc(env(safe-area-inset-top) - 8px) !important;
}
.theme--light {
  .v-toolbar {
    background-color: $user-color !important;
  }
  .v-toolbar__content {
    background-color: white;
  }
}

.owner-mode {
  .v-list {
    .menu-active,
    .v-list__tile--active {
      background: $owner-color !important;
      color: #ffffff !important;
    }
  }
  .pace {
    .pace-progress {
      background: $user-color !important;
    }
  }
}
.user-mode {
  .v-list {
    .menu-active,
    .v-list__tile--active {
      background: $user-color !important;
      color: #ffffff !important;
    }
  }
  .pace {
    .pace-progress {
      background: $user-color !important;
    }
  }
}
.admin-mode {
  .v-list {
    .menu-active,
    .v-list__tile--active {
      background: $admin-color !important;
      color: #ffffff !important;
    }
  }
  .pace {
    .pace-progress {
      background: $user-color !important;
    }
  }
}
#app {
  user-select: text;
}
// body {
//   margin-top: constant(safe-area-inset-top);
//   margin-top: env(safe-area-inset-top);
//   padding-top: constant(safe-area-inset-top);
//   padding-top: env(safe-area-inset-top);
// }
</style>
