export function formatNumber(num) {
  if (!num) return 0;
  var parts = num.toString().split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}
export function formatAmount(amount, currency = null) {
  return currency ? new Intl.NumberFormat("en-IN", { minimumSignificantDigits: 2, style: "currency", currency }).format(amount) : formatNumber(parseFloat(amount).toFixed(2));
}
export function formatCost(cost, currency = null) {
  return cost == null || cost === 0 ? "FREE" : formatAmount(cost, currency);
}
export function formatRate(rate, unit = "h", currency = null) {
  if (rate == null || rate === 0 || isNaN(rate)) return "FREE";
  if (unit != "" && unit != null) unit = " / " + unit;
  return formatAmount(parseFloat(rate), currency) + unit;
}

export function formatDecimal(value: any) {
  return parseFloat(value || "0").toFixed(2);
}

export function stringToDecimal(string, decimalPlaces = 2) {
  if (!string) return "0.00";
  return parseFloat(string).toFixed(decimalPlaces);
}

export function floorNumberToTwoDecimal(number) {
  if (!number) return 0.0;
  return Math.floor(number * 100) / 100;
}

// Basically usd/wh = 1000 * usd/kwh
// We need to multiply by 1_000_000_000 to handle javascript weird number division, check https://stackoverflow.com/questions/5037839/avoiding-problems-with-javascripts-weird-decimal-calculations
export function formatConsumptionRate(rate: any) {
  rate = parseFloat(rate || "0");
  if (rate === 0 || isNaN(rate)) return "FREE";
  return formatAmount((parseFloat(rate) * 1_000_000_000) / 1_000_000) + " / kWh";
}
