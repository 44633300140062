<template>
  <v-dialog v-model="isVisible" :max-width="maxWidth">
    <v-card>
      <v-card-title>
        <h5 v-html="title" />
      </v-card-title>
      <v-card-text class="text-xs-justify">
        <!-- This is v-flex previously -->
        <div class="flex flex-column items-center">
          <p v-if="!$slots.text" v-html="text" />
          <slot name="text" />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="cancelButton" color="gray" variant="text" @click="emit('cancel')">
          {{ cancelButton }}
        </v-btn>
        <v-spacer />
        <v-btn v-if="confirmButton" color="green" variant="text" @click="emit('confirmed')">
          {{ confirmButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { computed } from "vue-demi";

const props = withDefaults(
  defineProps<{
    title?: string;
    text?: string;
    show?: boolean;
    cancelButton?: string;
    confirmButton?: string;
    maxWidth?: number | string;
  }>(),
  {
    title: "Are you sure?",
    text: "Navigating away from this page will cause you to lose any changes you have made.",
    show: true,
    cancelButton: "Cancel",
    confirmButton: "Confirm",
    maxWidth: 400,
  }
);

const emit = defineEmits(["update:show", "cancel", "confirmed"]);

const isVisible = computed({
  get: () => props.show,
  set: (value: boolean) => emit("update:show", value),
});
</script>
