import Api from "@/library/apis/Api";
import { ConsoleHelpers, DeviceHelpers, sleep, SortHelpers, TimeHelpers } from "@/library/helpers";
import { cloneDeep } from "lodash-es";
import moment from "moment-timezone";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { adminStore, deviceStore, filterStore, globalStore, paymentPlanStore, sessionStore, walletStore } from ".";

const EXPIRATION_NOTIFICATION_THRESHOLD = 60 * 60 * 24 * 30; // 30 days

const defaultState = () => ({
  deviceModeForm: {
    show: false,
    deviceId: null,
  },
  deviceUserForm: {
    show: false,
    mode: null,
    deviceId: null,
    username: null,
  },
  form: {
    show: false,
    showOffline: false,
    loading: false,
    deviceId: null, // if not defined, it means it's a create device
    inputs: {
      offlineRegistrationSecretToken: null, // To register device that is offline
      identifier: null,
      description: null,
      modeAccess: null,
      hideDevice: false,
      planId: null,
      autopaymentDisabled: null,
      autopaymentWalletId: null,
    },
  },
});

export const mapDeviceData = (v: any, payload?: { users?: any; dataplan?: any; billing_logs?: any; wallets?: any }): any => {
  const device = cloneDeep(v);
  const { users, dataplan, billing_logs, wallets } = payload || {};

  device.plan = null;
  if (!!device.plan_id) device.plan = paymentPlanStore.all.find((plan) => plan.id === device.plan_id);
  if (device.plan && typeof device.plan.params == "string") device.plan.params = JSON.parse(device.plan.params);

  device.wallet = null;
  if (!!device.payment_wallet_id) device.wallet = (wallets || walletStore.all).find((wallet) => wallet.id == device.payment_wallet_id);

  const { communication, communication_type } = DeviceHelpers.parseSerialNumber(device.serial_no || "") || {};

  const start = moment.unix(device.start_timestamp).tz(globalStore.timezone);
  const expiry = moment.unix(device.expiry_timestamp).tz(globalStore.timezone);
  const today = moment().tz(globalStore.timezone);
  const diff = expiry.diff(today, "days");

  return {
    ...device,
    billing_logs,
    dataplan,
    // TODO: idk if we need these commented out properties, just delete it if not needed
    // logs :"" [],
    // expanded :"" false,
    // busy :"" false,
    active: sessionStore.all.find((a) => !a.endtime && a.plug_id === device.id),
    get active_state() {
      return this.active || this.isAlwaysOn;
    },
    access_settings: device.ispublic ? "Public" : "Private",
    access_plan: device.ispublic && device.plan ? device.plan.params.description : "",
    auto: device.payment_wallet_id ? "ON" : "OFF",
    auto_class: device.payment_wallet_id ? "text-green" : "text-orange",
    get color() {
      if (this.isAlwaysOn) return "#00acc1";
      if (this.isSmart) return "#FFA500";
      if (this.isBluetooth) return "#000";
      return "#f44336";
    },
    communication,
    communication_type,
    isAlwaysOff: device.plugmode === 0,
    isAlwaysOn: device.plugmode === 1,
    isSmart: device.plugmode === 2,
    isBluetooth: device.plugmode >= 3,
    isNotAlwaysOnAndOff: device.plugmode >= 2,
    get mode() {
      if (this.isAlwaysOn) return "Always On";
      if (this.isSmart) return "Smart";
      if (this.isBluetooth) return "Bluetooth";
      return "Always Off";
    },
    start: start.format("DD-MMM-YYYY hh:mm:ss A"),
    expiry: expiry.format("DD-MMM-YYYY hh:mm:ss A"),
    diff,
    expired: device.state != 0 && diff <= 7 ? "bill-warning" : diff <= 30 ? "bill-notify" : "bill-expired",
    remaining_raw: device.state != 0 ? diff : 0,
    remaining: device.state != 0 ? (diff < 1 ? `${Math.abs(diff)} Days Expired` : `${diff} Days`) : "Disabled",
    get last_heard_time_text() {
      const lapsedTime = TimeHelpers.unixToTimeLapsed(device.last_heard_time, { showSecond: true, timezone: globalStore.timezone, showTimeOnDate: true, isLongTimeUnit: true });
      return lapsedTime ? lapsedTime : "Never Connected";
    },
    get subscriptionModels() {
      return DeviceHelpers.getSubscriptionModels(device, dataplan);
    },
    get subscriptionModelText() {
      return device.payment_wallet_id ? this.subscriptionModels.join("###") : "N/A"; // ### Separator for easier search
    },
    users: users || [],
  };
};

export default defineStore("Device", {
  state: () => ({
    ...defaultState(),
    all: [],
    initialized: false,
    loading: false,
    loadingDataplanConfigs: {} as Record<string, boolean>,
    dataplan: {} as Record<string, any[]>,
    billing_logs: {} as Record<string, any[]>,
    isFetchingDeviceIdsUsers: [] as number[],
    users: {} as Record<number, any[]>,
    refreshListKey: uuidv4(),
  }),
  getters: {
    mappedData: (state) =>
      state.all
        .map((v) => mapDeviceData(v, { users: state.users[v.id], dataplan: state.dataplan[v.identifier], billing_logs: state.billing_logs[v.id] }))
        .sort(SortHelpers.compareValues("identifier")),
    hiddenDeviceIdentifiers: () => (adminStore.managerview?.preferences || globalStore.preferences)?.hiddenDevices || [],
    // For some reason this variable not re-calculated when the mapped data changed, so using the state directly instead
    visibleDevices(state) {
      return state.all
        .filter((d) => !this.hiddenDeviceIdentifiers?.includes(d.identifier))
        .map((v) => mapDeviceData(v, { users: state.users[v.id], dataplan: state.dataplan[v.identifier], billing_logs: state.billing_logs[v.id] }))
        .sort(SortHelpers.compareValues("identifier"));
    },
    getDeviceById() {
      return (id: number) => this.mappedData.find((w) => w.id === id);
    },
    getUsersOfDevice() {
      return (deviceId: number) => this.mappedData.find((w) => w.id === deviceId)?.users || [];
    },
    closestExpirationDate() {
      const is_dismissed = localStorage.__plugzio_plug_expiration_notification_dismissed__;
      if (this.all.length === 0 || is_dismissed === "true") return null;

      const current_timestamp = Math.floor(new Date().getTime() / 1000);
      const expiry_threshold = current_timestamp + EXPIRATION_NOTIFICATION_THRESHOLD;
      const plug_expiration_timestamps = this.all
        .filter((plug) => plug.state != 0 && plug.payment_wallet_id == null && plug.expiry_timestamp < expiry_threshold && plug.expiry_timestamp > current_timestamp)
        .map((plug) => plug.expiry_timestamp);

      if (!plug_expiration_timestamps.length) return null;

      return Math.min(...plug_expiration_timestamps);
    },
  },
  actions: {
    async initialize() {
      await this.fetchDataWithOngoingSessions();
      this.initialized = true;
    },

    async fetchData(options?: any) {
      try {
        if (!options?.hideLoading) this.loading = true;

        const data: any = await Api.plugs();

        const updateFields = options?.fields || [];
        if (updateFields.length > 0 && !!data) {
          for (let i = 0; i < data.length; i++) {
            const newData = data[i];
            const index = this.all.findIndex((d) => d.id === data[i].id);
            if (index === -1) continue;

            for (const field of updateFields) {
              if (this.all[index].hasOwnProperty(field)) this.all[index][field] = newData[field];
            }
          }

          return;
        }

        this.all = cloneDeep(data || []);
        this.refreshListKey = uuidv4();
        // if (plugs) commit("SET_PLUG_CLOSEST_EXPIRATION_TIMESTAMP", plugs);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    async fetchSingleDeviceDataplan(identifier: string) {
      this.loadingDataplanConfigs[identifier] = true;

      try {
        const response: any = await Api.plugDataplanConfig({ plugIdentifier: identifier });

        if (response && response.external_identifier === identifier) {
          this.dataplan[identifier] = response || null;
        }
      } catch (error) {
        ConsoleHelpers.logError(error);
      } finally {
        this.loadingDataplanConfigs[identifier] = false;
      }
    },

    async fetchAllDataplans(options?: any) {
      if (!options?.hideLoading) this.loading = true;

      try {
        await this.fetchData(options);

        const dataplanPromises = this.all.filter((plug) => plug?.identifier).map((plug) => this.fetchSingleDeviceDataplan(plug.identifier));

        await Promise.all(dataplanPromises);
      } catch (error) {
        ConsoleHelpers.logError(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchDeviceBillingLog(deviceId?: number, options?: any) {
      const plugId = deviceId;
      if (!plugId) {
        return;
      }

      try {
        if (!options?.hideLoading) this.loading = true;

        const fetchedLogs = await Api.plugBillingPaymentLog({ plugId: plugId, since: filterStore.startDatetimeStartOfDay, till: filterStore.endDatetimeEndOfDay });
        this.billing_logs[deviceId] = fetchedLogs || null;
      } catch (error) {
        ConsoleHelpers.logError(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchDataWithOngoingSessions(options?: any) {
      await Promise.all([this.fetchData(options), sessionStore.fetchOngoingSessions()]);
    },
    fetchUsers(deviceId: number) {
      return new Promise(async (resolve, reject) => {
        try {
          this.isFetchingDeviceIdsUsers.push(deviceId);
          const data = (await Api.plugUsers(deviceId)) as any;

          // Note that this is assuming the payment plans are already fetched and have the lastest data
          this.users[deviceId] = (data || [])
            .map((user) => ({
              ...user,
              plan: paymentPlanStore.mappedData.find((plan) => plan.id === user.plug_payment_access_plan_id),
            }))
            .sort(SortHelpers.compareValues("username"));
          ConsoleHelpers.log("Users", deviceId, this.users[deviceId]);
          resolve(true);
        } catch (error) {
          ConsoleHelpers.logError(error);
          reject(error);
        } finally {
          this.isFetchingDeviceIdsUsers.splice(this.isFetchingDeviceIdsUsers.indexOf(deviceId), 1);
        }
      });
    },
    setAccess(payload: any, ispublic: boolean, planId?: number) {
      return new Promise(async (res, rej) => {
        try {
          if (ispublic) payload.planId = planId;
          const response = await Api.plugSetAccess(ispublic ? "public" : "private", payload);
          res(response);
        } catch (error) {
          rej(error);
        }
      });
    },
    setModeAndAccess(mode: number, ispublic: boolean, planId?: number) {
      return new Promise(async (resolve, reject) => {
        let setModeSuccess = true;
        let setAccessSuccess = true;
        const setMode = () =>
          new Promise(async (res, rej) => {
            try {
              const response = await Api.plugSetMode({ plugId: deviceStore.deviceModeForm.deviceId, mode });
              res(response);
            } catch (error) {
              setModeSuccess = false;
              rej(error);
            }
          });
        globalStore.loading = true;
        try {
          await Promise.all([
            setMode().catch((e) => {
              setModeSuccess = false;
              throw e;
            }),
            this.setAccess({ plugId: deviceStore.deviceModeForm.deviceId }, ispublic, planId).catch((e) => {
              setAccessSuccess = false;
              throw e;
            }),
          ]).catch((e) => {
            throw e;
          });
          resolve(true);
        } catch (error) {
          let errorMessage = error.response.data;
          if (setModeSuccess && !setAccessSuccess)
            errorMessage = `Device's power settings has changed, but the device's access settings has not been changed. Please contact customer support using the "Chat With Us" button on the navigation bar.`;
          else if (!setModeSuccess && setAccessSuccess)
            errorMessage = `Access settings has successfully been changed, but the device's power settings has not been changed. Please make sure the device is online and connected to the internet.`;
          else if (!setModeSuccess && !setAccessSuccess)
            errorMessage = `Failed to change both device access and power settings. Please contact customer support using the "Chat With Us" button on the navigation bar.`;
          reject(
            error.response.data === "Device has been disabled"
              ? `Device has been disabled due to expired subscription. Please contact customer support using the "Chat With Us" button on the navigation bar.`
              : errorMessage
          );
        } finally {
          await sleep(500);
          await this.fetchDataWithOngoingSessions({ hideLoading: true });

          if (setModeSuccess && setAccessSuccess) {
            globalStore.snackbar.show = true;
            globalStore.snackbar.message = "Device mode change request sent";
            this.reset("deviceModeForm");
          }

          globalStore.loading = false;
        }
      });
    },
    setUserAccess(username: string, planId?: number) {
      return new Promise(async (resolve, reject) => {
        try {
          const data: any = { username, plugId: this.deviceUserForm.deviceId };
          if (this.deviceUserForm.mode !== "revoke") {
            if (!username || !planId) {
              reject("Please complete required fields");
              return;
            }
            data.plugPaymentAccessPlanId = planId;
          }
          globalStore.loading = true;
          const response = await Api.setUserAccessToPlug(this.deviceUserForm.mode !== "revoke", data);
          globalStore.snackbar.show = true;
          globalStore.snackbar.message = response;
          this.fetchUsers(this.deviceUserForm.deviceId);
          this.reset("deviceUserForm");

          resolve(true);
        } catch (error) {
          reject(
            error?.response?.data === "Device has been disabled"
              ? `Device has been disabled due to expired subscription. Please contact customer support using the "Chat With Us" button on the navigation bar.`
              : error?.response?.data || error
          );
        } finally {
          globalStore.loading = false;
        }
      });
    },
    bulkSetUserAccess(deviceIds: number[], payload: any, isGrant: boolean = true) {
      return new Promise(async (resolve, reject) => {
        try {
          const requests = [];
          for (const deviceId of deviceIds) {
            requests.push(Api.setUserAccessToPlug(isGrant, { ...payload, plugId: deviceId }));
          }
          await Promise.all(requests);
          globalStore.snackbar.show = true;
          globalStore.snackbar.message = isGrant ? "User was given access to plugs" : "User access to plugs was revoked";

          resolve(true);
        } catch (error) {
          reject("Could not complete requests. Please try again");
        }
      });
    },
    bulkSetAccess(deviceIds: number[], mode: number, isPublic: boolean = true, planId?: number) {
      return new Promise(async (resolve, reject) => {
        try {
          const requests = [];
          for (const deviceId of deviceIds) {
            requests.push(Api.plugSetMode({ plugId: deviceId, mode }));
            if (mode > 1) {
              let payload: any = { plugId: deviceId };
              if (isPublic) payload.planId = planId;
              requests.push(Api.plugSetAccess(isPublic ? "public" : "private", payload));
            }
          }
          await Promise.all(requests);
          globalStore.snackbar.show = true;
          globalStore.snackbar.message = "Device mode change request sent";

          resolve(true);
        } catch (e) {
          let error = e.response.data;
          if (e.response.status !== 500) {
            error = "Some devices failed to update";
          }
          reject(error);
        } finally {
          await sleep(500);
          await this.fetchDataWithOngoingSessions({ hideLoading: true });
        }
      });
    },
    async register(setAutopayment: boolean = false) {
      return new Promise(async (resolve, reject) => {
        try {
          this.form.loading = true;
          const payload: any = {
            plugIdentifier: this.form.inputs.identifier,
            description: this.form.inputs.description,
          };
          if (this.form.showOffline) payload.offlineRegistrationToken = this.form.inputs.offlineRegistrationSecretToken;

          await Api.plugRegister(payload);
          await this.setAccess({ plugIdentifier: this.form.inputs.identifier }, this.form.inputs.modeAccess, this.form.inputs.planId);

          if (setAutopayment) {
            await Api.plugSetPaymentWallet({ plugIdentifier: this.form.inputs.identifier, walletId: this.form.inputs.autopaymentWalletId });
          }

          globalStore.snackbar.show = true;
          globalStore.snackbar.message = "Plug Registered";
          this.form = defaultState().form;
          this.fetchDataWithOngoingSessions({ hideLoading: true });

          resolve(true);
        } catch (e) {
          const msg = e.response?.data || "";
          const error = msg instanceof Promise ? await msg : msg;
          if (["device not reachable"].includes(error.toLowerCase())) {
            this.form.show = false;
            this.form.showOffline = true;
          }
          reject(error);
        } finally {
          this.form.loading = false;
        }
      });
    },
    reset(key: string) {
      if (this.hasOwnProperty(key)) this[key] = defaultState()[key];
    },
    showEditDeviceForm(deviceId: number) {
      const device = this.getDeviceById(deviceId);
      if (!device) return;
      this.form.show = true;
      this.form.deviceId = deviceId;
      this.form.inputs.identifier = device.identifier;
      this.form.inputs.description = device.description;
      this.form.inputs.modeAccess = device.ispublic;
      this.form.inputs.planId = device.plan_id;
      this.form.inputs.hideDevice = this.hiddenDeviceIdentifiers.includes(device.identifier);
      this.form.inputs.autopaymentDisabled = !device.payment_wallet_id;
      this.form.inputs.autopaymentWalletId = device.payment_wallet_id;
    },
    async update(setAutopayment: boolean = false) {
      return new Promise(async (resolve, reject) => {
        try {
          this.form.loading = true;
          await Api.plugSetDescription({ plugId: this.form.deviceId, description: this.form.inputs.description });

          if (setAutopayment) {
            await Api.plugSetPaymentWallet({ plugId: this.form.deviceId, walletId: this.form.inputs.autopaymentWalletId });
          }

          const preferences = adminStore.managerview?.preferences || globalStore.preferences || {};
          let hiddenDevices = preferences.hiddenDevices || [];
          if (!this.form.inputs.hideDevice) hiddenDevices = hiddenDevices.filter((v) => v !== this.form.inputs.identifier);
          else if (!hiddenDevices.includes(this.form.inputs.identifier)) hiddenDevices.push(this.form.inputs.identifier);
          await globalStore.updatePreferences({ hiddenDevices });

          this.fetchData({ hideLoading: true });

          this.form = defaultState().form;

          globalStore.snackbar.show = true;
          globalStore.snackbar.message = "Plug Updated";
          resolve(true);
        } catch (error) {
          reject(error?.response?.data || false);
        } finally {
          this.form.loading = false;
        }
      });
    },
  },
});
