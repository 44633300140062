/* These are to save the application settings on window-level */

import { Capacitor } from "@capacitor/core";

const href = window.location.href;
const subDomain = window.location.host.split(".")[0];
const subPath = window.location.pathname !== "/" ? window.location.pathname : "";
const isApp = Capacitor.isNativePlatform();
const server = import.meta.env.VITE_APP_SERVER || "production";

// No settings required
// (window as any).API_URL = "";

// General settings
(window as any).REQUEST_TIMEOUT = 30000;
window.SESSION_REFRESH_INTERVAL = 300000;
(window as any).SESSION_STALE_TIMEOUT = 1800000;
(window as any).CAROUSEL_TIME = 30000;
(window as any).mobikwikSettings = {
  merchantName: import.meta.env.VITE_APP_MOBIKWIK_MERCHANT_NAME,
  merchantId: import.meta.env.VITE_APP_MOBIKWIK_MERCHANT_ID,
  endpoint: import.meta.env.VITE_APP_MOBIKWIK_API_URL,
  openInTab: false,
};
(window as any).PAYPAL_CLIENT_ID = import.meta.env.VITE_APP_PAYPAL_CLIENT_ID;
(window as any).PAYPAL_SINGAPORE_CLIENT_ID = import.meta.env.VITE_APP_PAYPAL_CLIENT_ID_SINGAPORE;

//Stop error resizeObserver
const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function (...args: any[]) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

if ((subDomain === "my" || subDomain === "manage") && !!subPath) {
  (window as any).PAYPAL_CLIENT_ID = "AaQ75SaX7D4j3vi2xRNZ8GjaxSTP8wR6z9eE0vJ6z6mgDQq-MYLYAHy_QMcvm9hXsqm89YqYQOhpfbUK";
  (window as any).PAYPAL_SINGAPORE_CLIENT_ID = "AcOn7JuGOzx7z7GwwNoqheNfV4HQTtWcBIhtqJ0ZAXcYWjqub7zFB7p3vIfvYPOkF5ea9MsFUGrpSZny";
}

// // Settings override
// if (subDomain === "my" || subDomain === "manage") {
//   if (subPath) {
//     console.log("Master with Production Database");
//     // https://my.plugz.io/demo-contractor#/login and https://manage.plugz.io/demo-contractor#/login

//     (window as any).PAYPAL_CLIENT_ID = (window as any).PAYPAL_CLIENT_ID_SANDBOX;
//   } else {
//     console.log("Production with Production Database");
//     // https://my.plugz.io/ and https://manage.plugz.io/
//   }
// } else {
//   if (subPath) {
//     console.log("Master with Staging Database");
//     // https://api-staging.plugz.io/demo-contractor#/

//     (window as any).PAYPAL_CLIENT_ID = (window as any).PAYPAL_CLIENT_ID_SANDBOX;
//   } else {
//     console.log("Release Candidate with Staging Database");
//     // https://api-staging.plugz.io/#/owner/register and https://api-staging.plugz.io/#/register

//     (window as any).PAYPAL_CLIENT_ID = (window as any).PAYPAL_CLIENT_ID_SANDBOX;
//   }
// }

// if ((href.match(/localhost/) || server == "local") && !isApp) {
//   // (window as any).API_URL = "/api";
//   (window as any).PAYPAL_CLIENT_ID = (window as any).PAYPAL_CLIENT_ID_SANDBOX;
// }

// // App settings override (full api url required)
// if (isApp) {
//   (window as any).API_URL = server == "production" ? "https://my.plugz.io" : "https://api-staging.plugz.io";
//   if (server != "production") (window as any).PAYPAL_CLIENT_ID = (window as any).PAYPAL_CLIENT_ID_SANDBOX;
//   else (window as any).PAYPAL_CLIENT_ID = (window as any).DEFAULT_PAYPAL_CLIENT_ID;
// }
