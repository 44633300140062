<template>
  <div v-show="RouteHelpers.isAuthenticatedPage() && globalStore.responsive !== null && !!authStore.role">
    <v-navigation-drawer
      v-model="showSidebar"
      app
      dark
      floating
      persistent
      mobile-break-point="991"
      width="270"
      class="main-drawer-menu drawer pt-0"
      :class="[`drawer__${authStore.role}`]"
      hide-overlay
    >
      <v-row id="navigation-bar" class="fill-height pa-0 flex-column sidebar-navigation flex-nowrap" tag="v-list">
        <v-col class="pt-0">
          <template v-if="actAsOwnerOptions.length > 1">
            <v-list class="pt-0 pb-0 mt-0">
              <v-list-item class="v-list-item" :class="{ active: route.name === 'owner-account-selection' }" @click="router.push({ name: 'owner-account-selection' })">
                <v-icon icon="mdi-account-circle" class="mr-5"></v-icon>
                <span>Account Selection</span>
              </v-list-item>
            </v-list>
            <hr style="border-top: 1px solid" />
          </template>

          <v-list class="pt-0 pb-0 mt-0" v-model:opened="openedItems">
            <template v-for="(item, index) in menu">
              <v-list-item
                v-if="!item.group || (item.group && item.action)"
                :id="item.elementId"
                class="v-list-item"
                :class="{ active: route.name === item.route }"
                :key="index"
                @click.stop="handleGroupClick(item)"
              >
                <div class="d-flex align-center">
                  <v-icon :icon="item.icon" class="mr-4" />
                  <span>{{ item.label }}</span>
                  <v-spacer />
                  <template v-if="item.action">
                    <img v-if="authStore.isOwner" src="/static/assets/warning-owner.png" class="mr-0 ml-3" width="24px" />
                    <img v-else src="/static/assets/warning-user.png" class="mr-0 ml-3" width="24px" />
                  </template>
                </div>
              </v-list-item>
              <v-list-group v-else :key="`else_${item.elementId}_${item.childrens?.length}`" :value="item.elementId" no-action>
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" class="v-list-item" :key="index" @click.stop="handleGroupClick(item)" :id="item.elementId">
                    <v-icon :icon="item.icon" class="mr-4" />
                    <span>{{ item.label }}</span>
                  </v-list-item>
                </template>

                <v-list-item
                  class="v-list-item"
                  :class="{ active: route.name === subItem.route }"
                  v-for="(subItem, i_sub_item) in item.childrens"
                  :key="i_sub_item"
                  @click.stop="router.push({ name: subItem.route })"
                >
                  <v-icon :icon="subItem.icon" class="mr-4" />
                  <span>{{ subItem.label }}</span>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list>
        </v-col>
        <v-col class="align-end d-flex pb-0">
          <v-list class="pt-0 mt-0 w-full">
            <v-list-item
              :class="{ active: route.name === `${authStore.roleSetting.routePrefix}settings` }"
              id="navigation-bar-settings"
              class="v-list-item"
              @click.stop="router.push({ name: `${authStore.roleSetting.routePrefix}settings` })"
            >
              <v-icon icon="mdi-cog" class="mr-4" />
              <span>Account Settings</span>
            </v-list-item>
            <v-list-item id="navigation-bar-article" class="v-list-item" @click.stop="gotoHelpArticle">
              <v-icon icon="mdi-text-box-outline" class="mr-4" />
              <span>Help Articles</span>
            </v-list-item>
            <v-list-item id="navigation-bar-intercom" class="v-list-item" @click.stop="globalStore.toggleIntercom()">
              <v-icon icon="mdi-message-alert" class="mr-4" />
              <span>Chat With Us</span>
            </v-list-item>
            <v-list-item id="navigation-bar-logout" class="v-list-item" @click.stop="showLogoutDialog">
              <v-icon icon="mdi-exit-to-app" class="mr-4" />
              <span>Logout</span>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-app-bar id="header" height="58">
      <v-app-bar-nav-icon id="header-hamburger" @click.stop="showSidebar = !showSidebar"></v-app-bar-nav-icon>
      <img v-if="!globalStore.responsive" id="header-logo" class="plugzio-logo" src="/static/assets/PlugzioLogoHorizontal.png" />
      <img v-else id="header-logo" class="plugzio-logo" src="/static/assets/Logo.png" />
      <v-spacer></v-spacer>
      <div class="title-menu text-right pr-4">
        <span id="header-email">{{ ownerProxyStore.actAs !== null ? `Logged in as ${ownerProxyStore.actAs}` : user }}</span>
      </div>
    </v-app-bar>

    <ConfirmationModal
      text="Are you sure you want to logout?"
      title="Confirm"
      :show="showLogoutConfirmModal"
      confirm-button="Yes"
      max-width="290"
      @confirmed="logout"
      @cancel="showLogoutConfirmModal = false"
    />
  </div>
</template>

<script lang="ts" setup>
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { RouteHelpers, SortHelpers } from "@/library/helpers";
import { adminStore, authStore, deviceStore, eventStore, globalStore, ownerProxyStore, paymentPlanStore, walletStore } from "@/store-pinia";
import { cloneDeep, debounce } from "lodash-es";
import { computed, nextTick, onMounted, ref, watch } from "vue-demi";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const user = ref(null);
const openedItems = ref([]);

const onResponsiveInverted = () => {
  globalStore.responsive = window.innerWidth < 991;
};

const menu = ref([]);
const menus = computed(() => ({
  user: [
    {
      elementId: "navigation-bar-dashboard",
      label: "Dashboard",
      icon: "mdi-power-plug",
      route: "dashboard",
    },
    {
      elementId: "navigation-bar-session-history",
      label: "Session History",
      icon: "mdi-history",
      route: "sessions",
    },
    {
      elementId: "navigation-bar-wallets",
      label: "Wallets",
      icon: "mdi-wallet",
      route: "wallets",
      action: globalStore.initialized && walletStore.initialized && walletStore.all.length === 0,
    },
  ],
  owner: [
    {
      elementId: "navigation-bar-dashboard",
      label: "Dashboard",
      icon: "mdi-view-dashboard",
      route: `${authStore.roleSetting.routePrefix}dashboard`,
    },
    {
      elementId: "navigation-bar-device-management",
      label: "Device Management",
      icon: "mdi-power-plug",
      route: `${authStore.roleSetting.routePrefix}outlet-management`,
      action: globalStore.initialized && deviceStore.initialized && deviceStore.all.length === 0,
      group: true,
      expand: false,
      childrens: [
        {
          label: "Network Configuration",
          icon: "mdi-wifi",
          route: `${authStore.roleSetting.routePrefix}device-network-management`,
        },
        {
          label: "Load Balancing",
          icon: "mdi-scale-balance",
          route: `${authStore.roleSetting.routePrefix}load-balancing`,
        },
      ],
    },
    {
      elementId: "navigation-bar-payment-plans",
      label: "Payment Plans",
      icon: "mdi-file-document",
      route: `${authStore.roleSetting.routePrefix}access-plans`,
      action: globalStore.initialized && paymentPlanStore.initialized && paymentPlanStore.all.length === 0,
    },
    {
      elementId: "navigation-bar-wallets",
      label: "Wallets",
      icon: "mdi-wallet",
      route: `${authStore.roleSetting.routePrefix}wallets`,
      action: globalStore.initialized && walletStore.initialized && walletStore.all.length === 0,
    },
    {
      elementId: "navigation-bar-billing",
      label: "Service Subscription",
      icon: "mdi-text-box",
      route: `${authStore.roleSetting.routePrefix}service-subscription`,
      action: (globalStore.initialized && deviceStore.initialized && deviceStore.all.length === 0) || !!deviceStore.closestExpirationDate,
    },
  ],
  admin: [
    {
      elementId: "navigation-bar-dashboard",
      label: "APIs",
      icon: "mdi-view-dashboard",
      route: `${authStore.roleSetting.routePrefix}dashboard`,
    },
    {
      elementId: "navigation-bar-managerview",
      label: "Manager View",
      icon: "mdi-account-box",
      route: `${authStore.roleSetting.routePrefix}managerview`,
    },
    {
      elementId: "navigation-bar-userview",
      label: "User View",
      icon: "mdi-account-circle",
      route: `${authStore.roleSetting.routePrefix}userview`,
    },
    {
      elementId: "navigation-bar-deviceview",
      label: "Device View",
      icon: "mdi-power-plug",
      route: `${authStore.roleSetting.routePrefix}deviceview`,
    },
    {
      elementId: "navigation-bar-wifisetup",
      label: "Wi-Fi Setup",
      icon: "mdi-wifi-cog",
      route: `${authStore.roleSetting.routePrefix}wifisetup`,
    },
    {
      elementId: "navigation-bar-configure-device-subscriptions",
      label: "Device Subscriptions",
      icon: "mdi-tag-heart",
      name: "subscriptionview",
      group: true,
      childrens: [
        {
          label: "Subscription Time",
          icon: "mdi-clock-outline",
          route: `${authStore.roleSetting.routePrefix}configure-device-subscriptions-time`,
        },
        {
          label: "Subscription Plan",
          icon: "mdi-list-box-outline",
          route: `${authStore.roleSetting.routePrefix}configure-device-subscriptions-plan`,
        },
      ],
    },
  ],
}));

const showSidebar = computed({
  get: () => globalStore.showSidebar,
  set: (v) => (globalStore.showSidebar = !!v),
});

const actAsOwnerOptions = computed(() => [
  { value: null, text: "My Account" },
  ...ownerProxyStore.canActAsUsers.map((p) => ({ value: p.owner_username, text: p.owner_username })).sort(SortHelpers.compareValues("text")),
]);

const userview = computed(() => adminStore.userview);
watch(
  () => userview.value,
  (user) =>
    debounce(() => {
      let newMenu = cloneDeep(menu.value);
      menu.value = [];
      const menuIndex = newMenu.findIndex((m) => m.elementId == "navigation-bar-userview");
      if (!user) {
        newMenu[menuIndex] = {
          elementId: "navigation-bar-userview",
          label: "User View",
          icon: "mdi-account-circle",
          route: `${authStore.roleSetting.routePrefix}userview`,
          name: "userview",
        };
      } else {
        newMenu[menuIndex] = {
          elementId: "navigation-bar-userview",
          label: "User View",
          icon: "mdi-account-circle",
          route: `${authStore.roleSetting.routePrefix}userview`,
          name: "userview",
          group: true,
          childrens: [
            {
              elementId: "navigation-bar-userview-dashboard",
              label: "Dashboard",
              icon: "mdi-view-dashboard",
              route: "admin-userview-dashboard",
            },
            {
              elementId: "navigation-bar-userview-session-history",
              label: "Session History",
              icon: "mdi-history",
              route: "admin-userview-sessions",
            },
            {
              elementId: "navigation-bar-userview-wallets",
              label: "Wallets",
              icon: "mdi-wallet",
              route: "admin-userview-wallets",
            },
            {
              label: "Configuration",
              icon: "mdi-cog",
              route: "admin-userview-configuration",
            },
          ],
        };
      }
      nextTick(() => {
        menu.value = newMenu;
        openedItems.value = ["navigation-bar-userview"];
      });
    }, 200)(),
  { deep: true }
);

const managerview = computed(() => adminStore.managerview);
watch(
  () => managerview.value,
  (owner) => {
    let newMenu = cloneDeep(menu.value);
    menu.value = [];
    const menuIndex = newMenu.findIndex((m) => m.elementId == "navigation-bar-managerview");
    if (!owner) {
      newMenu[menuIndex] = {
        elementId: "navigation-bar-managerview",
        label: "Manager View",
        icon: "mdi-account-box",
        route: `${authStore.roleSetting.routePrefix}managerview`,
        name: "managerview",
      };
    } else {
      newMenu[menuIndex] = {
        elementId: "navigation-bar-managerview",
        label: "Manager View",
        icon: "mdi-account-box",
        route: `${authStore.roleSetting.routePrefix}managerview`,
        name: "managerview",
        group: true,
        childrens: [
          {
            label: "Dashboard",
            icon: "mdi-view-dashboard",
            route: "admin-managerview-dashboard",
          },
          {
            label: "Device Management",
            icon: "mdi-power-plug",
            route: "admin-managerview-outlet-management",
          },
          {
            label: "Network Configuration",
            icon: "mdi-wifi",
            route: "admin-managerview-device-network-management",
          },
          {
            label: "Load Balancing",
            icon: "mdi-scale-balance",
            route: "admin-managerview-load-balancing",
          },
          {
            label: "Payment Plans",
            icon: "mdi-file-document",
            route: "admin-managerview-paymentplans",
          },
          {
            label: "Wallets",
            icon: "mdi-wallet",
            route: "admin-managerview-wallets",
          },
          {
            label: "Subscription Service",
            icon: "mdi-text-box",
            route: "admin-managerview-subscription",
          },
          {
            label: "Configuration",
            icon: "mdi-cog",
            route: "admin-managerview-configuration",
          },
        ],
      };
    }
    nextTick(() => {
      menu.value = newMenu;

      openedItems.value = ["navigation-bar-managerview"];
    });
  },
  { deep: true }
);

const deviceview = computed(() => adminStore.deviceview);
watch(
  () => deviceview.value,
  () => {
    let newMenu = cloneDeep(menu.value);
    menu.value = [];
    const menuIndex = newMenu.findIndex((m) => m.elementId == "navigation-bar-deviceview");
    if (!deviceview.value) {
      newMenu[menuIndex] = {
        elementId: "navigation-bar-deviceview",
        label: "Device View",
        icon: "mdi-power-plug",
        route: `${authStore.roleSetting.routePrefix}deviceview`,
        name: "deviceview",
      };
    } else {
      newMenu[menuIndex] = {
        elementId: "navigation-bar-deviceview",
        label: "Device View",
        icon: "mdi-power-plug",
        route: `${authStore.roleSetting.routePrefix}deviceview`,
        name: "deviceview",
        group: true,
        childrens: [
          {
            label: "Dashboard",
            icon: "mdi-view-dashboard",
            route: "admin-deviceview-dashboard",
          },
          {
            label: "Info & Logs",
            icon: "mdi-information",
            route: "admin-deviceview-info-and-logs",
          },
          {
            label: "OCPP Config",
            icon: "mdi-cog",
            route: "admin-deviceview-ocpp-config",
          },
          {
            label: "Heartbeat Analytics",
            icon: "mdi-clipboard-pulse",
            route: "admin-deviceview-heartbeat-analytics",
          },
        ],
      };
    }
    nextTick(() => {
      menu.value = newMenu;

      openedItems.value = ["navigation-bar-deviceview"];
    });
  },
  { deep: true }
);

const handleGroupClick = (item: any) => {
  if (item.group) openedItems.value = [item.elementId];

  menu.value.forEach((menuItem: any) => {
    if (menuItem.group && openedItems.value.includes(menuItem.elementId) && menuItem !== item) {
      openedItems.value = openedItems.value.filter((i) => i !== menuItem.elementId);
    }
  });

  if (item.elementId === "navigation-bar-device-management") openedItems.value = [item.elementId];

  router.push({ name: item.route });
};

const showLogoutConfirmModal = ref(false);
const showLogoutDialog = () => {
  // this.$intercom.hide();
  if (globalStore.responsive) globalStore.showSidebar = false;
  nextTick(() => {
    showLogoutConfirmModal.value = true;
  });
};
const logout = () => {
  showLogoutConfirmModal.value = false;
  authStore.logout().then((redirectTo: any) => router.push(redirectTo));
};

const gotoHelpArticle = () => {
  window.open("http://help.plugz.io/");
};

watch(
  () => authStore.role,
  () => {
    menu.value = menus.value[authStore.role];
  }
);
watch(
  () => menus.value,
  () => {
    if (!adminStore.isDeviceView && !adminStore.isManagerView) menu.value = menus.value[authStore.role];
  },
  { deep: true }
);

eventStore.$onAction(({ name, args }) => {
  if (!globalStore.responsive) return;
  if (name === "onSwiped") {
    const direction = args[0];
    const dialogActiveElement = document.querySelector(".v-dialog__content--active");
    if (direction === "right" && !dialogActiveElement) showSidebar.value = true;
  }
});

onMounted(() => {
  onResponsiveInverted();
  window.addEventListener("resize", onResponsiveInverted);
  if (!globalStore.responsive) globalStore.showSidebar = true;
  user.value = localStorage.getItem("email");
  menu.value = menus.value[authStore.role];

  // this is equals to this.$store.dispatch("initialize");
  globalStore.initializeData();
});
</script>

<style lang="scss">
.main-drawer-menu {
  color: white;
  .v-icon {
    color: white !important;
  }
}
.v-list__group__items--no-action .v-list__tile {
  padding-left: 36px;
}
.v-toolbar--fixed {
  z-index: 200;
}
.v-navigation-drawer {
  z-index: 1001;
}
.navbar-change-account-selection {
  height: 5.5rem;
  .v-list__tile {
    height: 100%;
    .v-select__selections {
      padding-top: 0px !important;
    }
    .v-input__append-inner {
      margin-top: 14px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "./../styles/colors";

.plugzio-logo {
  height: 30px;
  margin-left: 5px;
}

.sidebar-navigation {
  top: 12px;
  position: relative;
}

.drawer {
  &__user {
    background: $user-color;
    .account-info {
      background: $user-dark-color;
    }
    .v-list-item:hover {
      background: $user-highlight-color;
    }
    .active {
      background: $user-active-color !important;
    }
  }

  &__owner {
    background: $owner-color;
    .account-info {
      background: $owner-dark-color;
    }
    .v-list-item:hover {
      background: $owner-highlight-color;
    }
    .active {
      background: $owner-active-color !important;
    }
  }
  &__admin {
    background: $admin-color;
    .account-info {
      background: $admin-dark-color;
    }
    .v-list-item:hover {
      background: $admin-highlight-color;
    }
    .active {
      background: $admin-active-color !important;
    }
  }
}

.v-list-item:hover {
  cursor: pointer;
}

.v-list__tile__action {
  min-width: 45px !important;
}

.account-info {
  h3 {
    font-weight: 300;
    font-size: 1em;
  }
}
</style>
