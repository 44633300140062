export const SESSION_STATUSES = {
  OFF: 0,
  ON: 1,
  STALE: 2,
};

export const SESSION_STATUS_COLOR_CLASS = {
  OFF: "plug-off-color",
  ON: "plug-on-color",
  STALE: "plug-stale-color",
};

export const SESSION_STATUS_COLOR = ["gray", "green", "black"];

export const DARK_SESSION_STATUS_COLOR = ["#f09000 ", "#008000", "#2a2a2a"];
