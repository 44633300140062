<template>
  <v-dialog v-model="visible" max-width="900" scrollable>
    <v-card style="max-height: 600px">
      <v-card-title>
        <h3>{{ globalStore.globalDialog?.title }}</h3>
      </v-card-title>
      <v-card-text>
        <div v-html="globalStore.globalDialog?.content" />
      </v-card-text>
      <v-card-actions class="flex justify-end">
        <v-btn v-if="globalStore.globalDialog?.showCancel" color="gray" variant="flat" @click="cancel">
          {{ globalStore.globalDialog?.cancelText || "Cancel" }}
        </v-btn>
        <v-spacer />
        <v-btn v-if="globalStore.globalDialog?.showConfirm" color="green" variant="flat" @click="confirm">
          {{ globalStore.globalDialog?.confirmText || "OK" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { globalStore } from "@/store-pinia";
import { computed } from "vue-demi";

const visible = computed({
  get: () => globalStore.globalDialog?.show,
  set: (value) => {
    if (!value) {
      globalStore.globalDialog?.onClosed();
      globalStore.reset(["globalDialog"]);
      return;
    }
    globalStore.globalDialog.show = value;
  },
});

const confirm = () => {
  globalStore.globalDialog.onConfirmed();
  visible.value = false;
};
const cancel = () => {
  globalStore.globalDialog.onCanceled();
  visible.value = false;
};
</script>
