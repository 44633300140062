class ConsoleHelpers {
  allowLog: boolean = false;

  constructor() {}

  log(...args: any[]) {
    if (this.allowLog) console.log(...args);
  }
  logError(...args: any[]) {
    if (this.allowLog) console.error(...args);
  }
}

export default new ConsoleHelpers();
