import { DAYS_CONSTANT } from "@/constants";
import { hourAndMinuteIntToAmPm } from "@/library/helpers";
import moment from "moment-timezone";

export function timeBasedGetText({ type = "time", values = {} }: any) {
  const { hour, minute, amPm } = hourAndMinuteIntToAmPm(values || { hour: 0, minute: 0 });
  if (type === "time") {
    values.hour = hour;
    values.minute = minute;
    values.amPm = amPm;
  }
  values.month = (values.month || 0).toString().padStart(2, "0");
  values.day = (values.day || 0).toString().padStart(2, "0");
  values.hour = (values.hour || 0).toString().padStart(2, "0");
  values.minute = (values.minute || 0).toString().padStart(2, "0");

  const currentYear = new Date().getFullYear();
  const texts = {
    time: `${values.hour || "..."}:${values.minute || "..."} ${values.amPm || "..."}`,
    day: DAYS_CONSTANT[values.weekday],
    date: `${moment(`${currentYear}-${values.month}-${values.day} 00:00:00`).format("MMMM Do")} at ${hour}:${minute} ${amPm}`,
  };

  return texts[type];
}
