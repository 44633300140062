<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title>
        <h5>Device Cloud Subscription</h5>
      </v-card-title>
      <v-card-text class="text-xs-justify">
        <v-row class="ma-0">
          <v-col cols="12" class="d-flex justify-content-center flex-column pa-0">
            <p class="text-justify">
              Some of your Plugzio device(s) cloud access are schedules to expire on <strong>{{ expirationDate }}</strong
              >. Expired devices will no longer be accessable by the Plugzio cloud for both you and your users. If you would like to continue your device's subscription, you can do
              at the "Service Subscription" page.
            </p>
            <v-btn class="plugzio-button mt-4 wifi-setup-button" block outlined rounded @click="goToBillingPage()"> GO TO SERVICE SUBSCRIPTION PAGE </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="green" variant="text" @click="close">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { deviceStore, globalStore } from "@/store-pinia";
import moment from "moment-timezone";
import { computed, ref } from "vue-demi";
import { useRouter } from "vue-router";

const router = useRouter();

const isDismissed = ref(localStorage.getItem("__plugzio_plug_expiration_notification_dismissed__") === "true");

const show = computed({
  get() {
    if (!deviceStore.closestExpirationDate || isDismissed.value) return false;
    const is_dismissed = localStorage.getItem("__plugzio_plug_expiration_notification_dismissed__");
    return !is_dismissed || is_dismissed === "false";
  },
  set(value) {
    if (value === false) localStorage.setItem("__plugzio_plug_expiration_notification_dismissed__", "true");
  },
});

const expirationDate = computed(() => {
  let date = moment.unix(deviceStore.closestExpirationDate).tz(globalStore.timezone);
  return date.format("DD-MMM-YYYY");
});

const goToBillingPage = () => {
  close();
  router.push({ path: "/owner/service-subscription" });
};

const close = () => {
  show.value = false;
  isDismissed.value = true;
};
</script>
