import { USER_ROLES } from "@/constants";
import { AdminViews } from "@/interfaces";
import Api from "@/library/apis/Api";
import { ApiHelpers, LocalStorageHelpers } from "@/library/helpers";
import moment from "moment-timezone";
import { defineStore } from "pinia";
import { mapDeviceData } from "./deviceStore";

const defaultPersistentState = () => ({
  starttime: null,
  endtime: null,
  timezone: null,
});

export default defineStore("Admin", {
  state: () => ({
    deviceview: null,
    managerview: null,
    activeView: null,
    userview: null,
    searchedUsers: null,
    persistents: defaultPersistentState(),
  }),
  getters: {
    isUserView: (state) => state.activeView === AdminViews.USERVIEW,
    isManagerView: (state) => state.activeView === AdminViews.MANAGERVIEW,
    isDeviceView: (state) => state.activeView === AdminViews.DEVICEVIEW,
    formattedDeviceView: (state) => {
      if (!state.deviceview) return null;
      return mapDeviceData(state.deviceview);
    },
    viewAsUser: (state) => {
      if (!state.userview || !state.activeView) return null;
      return {
        username: state.userview.username,
        id: state.userview.id,
      };
    },
    viewAsOwner(state) {
      if ((!state.managerview && !state.deviceview) || !state.activeView) return null;
      if (this.isManagerView) {
        if (!state.managerview) return null;
        return {
          username: state.managerview ? state.managerview.owner_username : null,
          id: state.managerview ? state.managerview.id : null,
        };
      } else if (this.isDeviceView) {
        if (!state.deviceview) return null;
        return {
          username: state.deviceview ? state.deviceview.owner_username : null,
          id: state.deviceview ? state.deviceview.owner_id : null,
        };
      }

      return null;
    },
    isDeviceRegistered: (state) => {
      if (state.activeView !== "deviceview") return true;

      if (state.activeView === "deviceview" && !state.deviceview) return false;

      return state.deviceview.id !== "not_registered";
    },
  },
  actions: {
    setActiveView(route) {
      this.activeView = null;

      if (!route) route = "";

      // This action fetches data preferences/user history search related to the manager view,
      // where the device subscription doesn't have an active view state component.
      if (route.match(/managerview/) || route.match(/configure-device-subscriptions-time/) || route.match(/configure-device-subscriptions-plan/) || route.match(/wifisetup/))
        this.activeView = "managerview";
      else if (route.match(/userview/)) this.activeView = "userview";
      else if (route.match(/deviceview/)) this.activeView = "deviceview";
    },
    async getPreferencesForView() {
      const view = this.viewAsOwner || this.viewAsUser;
      if (!view) return;

      const preferences = await Api.getPrefs(
        ApiHelpers.getApiPrefix(USER_ROLES.ADMIN as "admin", this.activeView),
        ApiHelpers.getUsernamePayloadOfViewAs(this.activeView, this.viewAsOwner, this.viewAsUser)
      );
      if (preferences) {
        this[this.activeView] = {
          ...this[this.activeView],
          preferences,
        };
      }
    },
    getSearchedUsers() {
      this.searchedUsers = LocalStorageHelpers.getNestedItem("__plugzio_admin_storage__", ["searched_items", this.activeView, localStorage.getItem("email")]);
    },
    setSearchedUsers(search: string) {
      const datetime = moment().format("YYYY-MM-DD HH:mm:ss");
      if (!Array.isArray(this.searchedUsers)) this.searchedUsers = [];

      let searchedUsers = this.searchedUsers.filter((user) => user.search !== search);
      searchedUsers.unshift({ search, datetime });
      searchedUsers = searchedUsers.slice(0, 100);

      LocalStorageHelpers.setNestedItem("__plugzio_admin_storage__", ["searched_items", this.activeView, localStorage.getItem("email")], searchedUsers);
      this.searchedUsers = searchedUsers;
    },
    removeSearchedUser(search) {
      if (!this.searchedUsers) this.searchedUsers = [];
      const searchedUsers = this.searchedUsers.filter((user) => user.search != search);

      LocalStorageHelpers.setNestedItem("__plugzio_admin_storage__", ["searched_items", this.activeView, localStorage.getItem("email")], searchedUsers);

      this.searchedUsers = searchedUsers;
    },
    reset() {
      this.deviceview = null;
      this.managerview = null;
      this.userview = null;
    },
  },
});
